@if(field(); as field)
{
<div class="input-group">
	<span class="input-group-text">
		@if(field.saving()){<i class="fa-fw fa-regular fa-spin fa-spinner-third"></i>}
		@else if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
		@else if(field.config().type ==='dollar')
		{
		<i class="fa-fw fa-regular fa-dollar-sign"></i>
		}
		@else if(field.config().type ==='percentage')
		{
		<i class="fa-fw fa-regular fa-percent"></i>
		}
		@else {
		<i class="fa-fw fa-regular fa-hashtag"></i>
		}
	</span>


	<div class="form-floating">
		<input [type]="type()" #numberField class="form-control" [id]="randomId" [placeholder]="field.config().label"
			[min]="min()" [max]="max()" step="1" [ngModel]="renderedModel()" (ngModelChange)="onChange($event)"
			[disabled]="field.saving()" (blur)='onBlur()' (focus)="onFocus()">
		<label class="color-gray d-flex" [for]="randomId">
			{{field.config().label}}{{!field.config().required ? ' ' + labels().optionalWithParentheses():''}}
		</label>
	</div>
</div>

@if(errorToShow() !== '')
{
<div class="color-darkred text-end">{{errorToShow()}}</div>
}
}