import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';


@Component({
	selector: 'common-stepper',
	standalone: true,
	imports: [NgClass],
	templateUrl: './common-stepper.widget.html',
	styleUrls: ['./common-stepper.widget.scss']
})
export class CommonStepperWidget {

	public readonly totalStep = input.required<number>();
	public readonly currentStep = input.required<number>();
	public readonly displayNumbers = input<boolean>(false);

}