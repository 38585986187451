import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Instructions: computed(() => en() ? `Instructions` : `Instrucciones`),
		Complete: computed(() => en() ? `Complete` : `Finzalizado`),
		NextStep: computed(() => en() ? `Next Step` : `{{ ES: Next Step }}`),
		IWorkBusinessFullTime: computed(() => en() ? `I work on this business full-time` : `Trabajo en el negocio tiempo completo`),
		IWorkBusinessPartTime: computed(() => en() ? `I work on this business part-time` : `Trabajo en el negocio medio tiempo`),
		InstructionMsg: computed(() => en() ? 'In order to best serve you, we would love to learn more about you. No personally identifiable information will be shared with any external, third party without your authorization. As a nonprofit, EforAll needs aggregated (non-personally identifiable) demographic data to apply for grants and other fundraising opportunities that enable us to offer programs at no cost.' : `{{ ES: In order to best serve you, we would love to learn more about you. No personally identifiable information will be shared with any external, third party without your authorization. As a nonprofit, EforAll needs aggregated (non-personally identifiable) demographic data to apply for grants and other fundraising opportunities that enable us to offer programs at no cost. }}`),
	}
}