<mobile-page-part>

	<div class="info-content">
		@if(topics().length){<h3>{{util.text.singularOrPluralWithCount(topics().length, labels.TopicAvailable(),
			labels.TopicsAvailable())}}</h3>}
		@else {<h3>{{labels.NoOngoingSession()}}</h3>}
	</div>

	@if(topics().length) {
	@if(topics(); as topics){
	<div ngbAccordion [closeOthers]="true">
		@for (topic of topics; track $index;) {
		<div ngbAccordionItem>
			<div ngbAccordionHeader>
				<button ngbAccordionButton class="btn btn-flat p-2">
					{{topic.topicName}}
				</button>
			</div>

			<div ngbAccordionCollapse>
				<div ngbAccordionBody class="p-2">
					<ng-template>
						<p>{{topic.topicGoals}}</p>


						<h4>{{labels.Materials()}}:</h4>

						@if(!topic.hasMaterials){
						<i class="color-gray">
							{{labels.TopicNoMaterialsMsg()}}
						</i>
						}


						@if(topic.topicMaterials.length){

						<table class="table table-sm table-bordered">
							@for(material of topic.topicMaterials; track material.id;){
							<tr>
								<td class="p-1">{{material.id}}:</td>
								<td class="p-1"><i class="fa-fw {{material.icon}}"></i></td>
								<td class="p-1"><a [href]="material.url" target="_blank">
										{{material.name}}
									</a>
								</td>
							</tr>
							}
						</table>
						}
					</ng-template>
				</div>
			</div>
		</div>
		}

	</div>
	}
	}
	@else {
	<div>{{labels.NoOngoingSession()}}</div>
	}

</mobile-page-part>