import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';

export function getLabels(urlService: UrlService) {
	const en = urlService.isEnglish;
	return {
		...urlService.commonLabels,

		Questionnaire: computed(() => en() ? `Questionnaire` : `{{ ES : Questionnaire }}`),
		MiddleInit: computed(() => en() ? `M.I.` : `{{ ES : M.I. }}`),
		WhatsRole: computed(() => en() ? `What is your Role?` : `{{ ES : What is your Role? }}`),
		LearnAboutEforAll: computed(() => en() ? `How did you learn about EforAll` : `{{ ES : How did you learn about EforAll }}`),
		TypeOfBusiness: computed(() => en() ? `Type of business?` : `{{ ES : Type of business? }}`),
		StageOfBusiness: computed(() => en() ? `Stage of business?` : `{{ ES : Stage of business? }}`),
		Offerings: computed(() => en() ? `Offerings` : `{{ ES : Offerings }}`),
		Industry: computed(() => en() ? `Industry` : `{{ ES : Industry }}`),
		YearOfBirth: computed(() => en() ? `Year Of Birth` : `{{ ES : Year Of Birth }}`),
		HouseholdSize: computed(() => en() ? `Household Size` : `{{ ES : Household Size }}`),
		College: computed(() => en() ? `College` : `{{ ES : College }}`),
		college: {
			GraduatedCollege: computed(() => en() ? `I have graduated from college` :`{{ ES : I have graduated from college }}`),
			DidNotGraduateCollege: computed(() => en() ? `I have not graduated from college` : `{{ ES : I have not graduated from college }}`),
		},
		role: {
			NewBusiness: computed(() => en() ? `I'd like to start a new business` : `{{ ES : I'd like to start a new business }}`),
			ExistsingBusiness: computed(() => en() ? `I'd like help with my existing business` : `{{ ES : I'd like help with my existing business }}`),
			EforAllVolunteer: computed(() => en() ? `I'm EforAll Volunteer` : `{{ ES : I'm EforAll Volunteer }}`),
			EforAllStaffMember: computed(() => en() ? `I'm EforAll Staff Member` : `{{ ES : I'm EforAll Staff Member }}`),
		},
		learn: {
			SocialMedia: computed(() => en() ? `Social Media` : `{{ ES : Social Media }}`),
			OnlineAd: computed(() => en() ? `Online Ad` : `{{ ES : Online Ad }}`),
			SearchEngine: computed(() => en() ? `Search Engine` : `{{ ES : Search Engine }}`),
			TV: computed(() => en() ? `TV / Radio Ad` : `{{ ES : TV / Radio Ad }}`),
			Article: computed(() => en() ? `Article / Blog / Podcast` : `{{ ES : Article / Blog / Podcast }}`),
			Friend: computed(() => en() ? `Friend / Family / Neighbor` : `{{ ES : Friend / Family / Neighbor }}`),
			Event: computed(() => en() ? `Event / Conference` : `{{ ES : Event / Conference }}`),
			Other: computed(() => en() ? `Other` : `{{ ES : Other }}`),
		},

		BusinessType: {
			Profit: computed(() => en() ? `For Profit` : `{{ ES : For Profit }}`),
			NonProfit: computed(() => en() ? `Non-Profit` : `{{ ES : Non-Profit }}`),
		},
		BusinessStage: {
			Stage0: computed(() => en() ? `Stage 0 - I have an idea of business` : `{{ ES : Stage 0 - I have an idea of business }}`),
			Stage1: computed(() => en() ? `Stage 1 - My business has started with little sales` : `{{ ES : Stage 1 - My business has started with little sales }}`),
			Stage2: computed(() => en() ? `Stage 2 - My business is stable and I pay myself` : `{{ ES : Stage 2 - My business is stable and i pay myself }}`),
		},
		BusinessOfferings: {
			Products: computed(() => en() ? `Products` : `{{ ES : Products }}`),
			Services: computed(() => en() ? `Services` : `{{ ES : Services }}`),
			Both: computed(() => en() ? `Products and Services` : `{{ ES : Products and Services }}`),
		}
	};
}