@if(field(); as field)
{
<div class="input-group">
	<span class="input-group-text">
		@if(field.saving()){<i class="fa-fw fa-regular fa-spin fa-spinner-third"></i>}
		@else if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
		@else{<i class="fa-fw fa-regular fa-location-dot"></i>}
	</span>
	<div class="form-floating">
		<input type="text" class="form-control" #addressField [id]="randomId" [placeholder]="field.config().label"
			[ngModel]="renderedModel()" [disabled]="field.saving()" (blur)='onBlur()' (focus)="onFocus()">
		<label class="color-gray d-flex" [for]="randomId">
			{{field.config().label}}{{!field.config().required ? ' ' + labels().optionalWithParentheses():''}}
		</label>
	</div>
</div>

@if(errorToDisplay() !== '')
{
<div class="color-darkred text-end">{{errorToDisplay()}}</div>
}
}