import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,
		UpcomingEvents: computed(() => en() ? `Upcoming Events` : `{{ ES : Upcoming Events }}`),
		OngoingEvents: computed(() => en() ? `Ongoing Events` : `{{ ES : Ongoing Events }}`),
		PastEvents: computed(() => en() ? `Past Events` : `{{ ES : Past Events }}`),
		NoOngoingEvents: computed(() => en() ? `No Ongoing Events` : `{{ ES : No Ongoing Events }}`),
		NoUpcomingEvents: computed(() => en() ? `No Upcoming Events` : `{{ ES : No Upcoming Events }}`),
		NoPastEvents: computed(() => en() ? `No Past Events` : `{{ ES : No Past Events }}`),
		AcceleratorSession: computed(() => en() ? `Accelerator Session` : `Sesión de aceleración`),
		SurveyComplete: computed(() => en() ? `Survey Complete` : `{{ ES : Survey Complete }}`),
		SurveyMissed: computed(() => en() ? `Survey Missed` : `{{ ES : Survey Missed }}`),
		SurveyUnavailable: computed(() => en() ? `Survey Unavailable` : `{{ ES : Survey Unavailable }}`),
		SurveyNotOpenYet: computed(() => en() ? `Survey NotOpenYet` : `{{ ES : Survey NotOpenYet }}`),
		SurveyOpen: computed(() => en() ? `Survey Open` : `{{ ES : Survey Open }}`),
		Unknown: computed(() => en() ? `Unknown` : `{{ ES : Unknown }}`),

		eventTypes: <{ [index: string]: Signal<string | undefined> }>{
			'Accelerator Showcase': computed(() => en()
				? 'Accelerator Showcase'
				: 'Exhibición del Acelerador'
			),
			'Accelerator Session': computed(() => en()
				? 'Accelerator Session'
				: 'Sesión del acelerador'
			),
			'Accelerator Mentor Matching': computed(() => en()
				? 'Accelerator Mentor Matching'
				: 'Asignación de mentores del acelerador'
			),
			'Accelerator Final Pitch': computed(() => en()
				? 'Accelerator Final Pitch'
				: 'Presentación final del acelerador'
			),
			'Accelerator Quarterlies Q2': computed(() => en()
				? 'Accelerator Quarterlies Q2'
				: 'Acelerador de Negocios Reuniones Trimestrales Q2'
			),
			'Accelerator Quarterlies Q3': computed(() => en()
				? 'Accelerator Quarterlies Q3'
				: 'Acelerador de Negocios Reuniones Trimestrales Q3'
			),
			'Accelerator Quarterlies Q4': computed(() => en()
				? 'Accelerator Quarterlies Q4'
				: 'Acelerador de Negocios Reuniones Trimestrales Q4'
			),
		},

	};
}
