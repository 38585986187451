import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonOptionsFieldConfig, CommonSelectField, CommonTextField, CommonTextFieldConfig, Option, UrlService } from "@eforall/common";
import { MyTeamService } from "../my-team.service";
import { getLabels } from "./business.page.labels";

@Injectable({ providedIn: 'root' })
export class CohortBusinessFormService {

	private readonly myTeamService = inject(MyTeamService);
	private readonly urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	public readonly companyName = computed(() => {
		return this.myTeamService.accTeam()?.companyName;
	});

	readonly businessName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.BusinessName(), min: 5, max: 40, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.companyName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setLongName({ companyId: accTeam.companyId, companyName: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, companyName: value });
			}
		},
		error: signal(''),
	}));

	readonly shortName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.ShortName(), min: 0, max: 40, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.shortName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setShortName({ companyId: accTeam.companyId, shortName: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, shortName: value });
			}
		},
		error: signal(''),
	}));


	private readonly businessTypeOptions = computed(() => {
		const domainData = this.myTeamService.domain.data();
		return domainData.companyTypes.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.companyTypeId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly businessType = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.labels.BusinessType(), options: this.businessTypeOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.companyTypeId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setCompanyTypeId({ companyId: accTeam.companyId, companyTypeId: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, companyTypeId: value });
			}
		},
		error: signal(''),
	}));


	private readonly industryOptions = computed(() => {
		return this.myTeamService.domain.data().industries.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.industryId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly industry = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.labels.Industry(), options: this.industryOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.industryId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setIndustryId({ companyId: accTeam.companyId, industryId: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, industryId: value });
			}
		},
		error: signal(''),
	}));


	private readonly offeringOptions = [
		{ value: 'Products', text: this.labels.Products() },
		{ value: 'Services', text: this.labels.Services() },
		{ value: 'Both', text: this.labels.ProductsAndServices() },
	];

	public readonly offering = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.Offering(), options: this.offeringOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.offerings || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setOfferings({ companyId: accTeam.companyId, offerings: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, offerings: value as 'Products' | 'Services' | 'Both' });
			}
		},
		error: signal(''),
	}));

	private readonly statusOptions = [
		{ value: 'Open', text: this.labels.status.Open() },
		{ value: 'Closed', text: this.labels.status.Closed() },
	];

	public readonly status = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.OperatingStatus(), options: this.statusOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.myTeamService.accTeam()?.status || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const accTeam = this.myTeamService.accTeam();
			if (accTeam) {
				await this.myTeamService.func.company.form.setStatus({ companyId: accTeam.companyId, status: value });
				this.myTeamService.setTeamBusinessData({ ...accTeam, status: value as "Open" | "Closed" });
			}
		},
		error: signal(''),
	}));




	public async loadData() {
		await this.myTeamService.loadData();
	}

}
