import { NgClass } from '@angular/common';
import { Component, computed, inject, Signal } from '@angular/core';
import {
	CommonButtonRowWidget,
	CommonSelectFieldWidget,
	CommonUrlFieldWidget,
	UrlService,
	UtilityService,
} from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { WebLinks } from '@interfaces';
import { AppService, FlyoutService } from 'services';
import { AddSocialLinksFormService } from './add-social-links-form.service';
import { getLabels } from './social-links.page.labels';
import { SocialLinkService } from './social-links.service';

export interface WebLinksWithIcons extends WebLinks {
	icon?: string
	iconStyle?: string
}
@Component({
	selector: 'profile-social-links-page',
	imports: [
		MobilePagePart,
		CommonSelectFieldWidget,
		CommonUrlFieldWidget,
		CommonButtonRowWidget,
		NgClass
	],
	templateUrl: './social-links.page.html',
	styleUrls: ['./social-links.page.scss']
})

export class ProfileSocialLinksPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	public form = inject(AddSocialLinksFormService);
	public socialLinkService = inject(SocialLinkService);
	private util = inject(UtilityService);
	public flyoutService = inject(FlyoutService);
	public LinkOrLinks = computed(() => this.util.text.singularOrPluralWithCount(this.links().length, this.labels.Link(), this.labels.Links()));
	private app = inject(AppService);
	public links: Signal<WebLinksWithIcons[]> = computed(() => (this.app.data().user.webLinks || []).map(t => ({ ...t, icon: this.getSocialLinkIcon(t.webLinkTypeId), iconStyle: t.webLinkTypeId === 1 ? 'fa-regular' : 'fa-brands' })));

	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/social-links',
			backUrl: '/profile',
			pageName: computed(() => this.labels.SocialLinks()),
			headerText: computed(() => this.labels.SocialLinks()),
		});
	}

	askForConfirmDelete(webLinkId: number) {
		this.flyoutService.showConfirmationDialouge(this.labels.DeleteLinkConfirmationMessage(), this.labels.DeleteLinkConfirmationMessageTitle());
		this.flyoutService.flyoutActionObservable.subscribe((action) => {
			if (action) this.form.removeWebLink(webLinkId)
		});
	}

	private getSocialLinkIcon(webLinkTypeId: number): string {
		switch (webLinkTypeId) {
			case 1://Website
				return 'fa-link';
			case 2://Facebook
				return 'fa-facebook';
			case 3://LinkedIn
				return 'fa-linkedin';
			case 4://Twitter
				return 'fa-twitter';
			default:
				return 'fa-link';
		}
	}

}