<mobile-page-part>

	<div class="info-content">
		<h3>{{labels.WelcomeTitle()}}</h3>
	</div>

	<div class="d-flex justify-content-center align-items-center flex-column text-center h-75">
		<h2 class="mb-4">{{labels.StartJourneyMessage()}}</h2>
		<p>{{labels.WelcomeMessage()}}</p>
		<div class="mt-3">
			<common-button-row [center]="true">
				<button class="btn btn-primary btn-large" routerLink="../questionnaire/page-1">{{labels.GetStarted()}}</button>
			</common-button-row>
		</div>

	</div>
</mobile-page-part>