import { Component, computed, inject, OnInit } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AllCoursesService } from '../all-courses.service';
import { CourseDetailsListViewPart } from '../course-details-list-view-part/course-details-list-view.part';
import { getLabels } from './all-courses.page.labels';

@Component({
	selector: 'all-courses-page',
	imports: [
		MobilePagePart,
		CourseDetailsListViewPart,
	],
	templateUrl: './all-courses.page.html'
})

export class AllCoursesPage implements OnInit {
	private frame = inject(MobileFrameService);
	public labels = getLabels(this.frame.urlService);
	public allCoursesService = inject(AllCoursesService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/all-courses',
			backUrl: '/',
			pageName: computed(() => this.labels.AllCourses()),
			headerText: computed(() => this.labels.AllCourses()),
		});
	}

	async ngOnInit() {
		await this.allCoursesService.loadAllData();
	}

}