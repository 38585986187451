import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonDateTimeField, CommonDateTimeFieldConfig, CommonMultiSelectField, CommonOptionsFieldConfig, CommonSelectField, CommonTextField, UrlService, UtilityService } from "@eforall/common";
import { AppService, FuncService } from '../../../services';
import { getLabels } from "./demographics.page.labels";

@Injectable({ providedIn: 'root' })
export class ProfileDemographicsFormService {

	private readonly app = inject(AppService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	private readonly util = inject(UtilityService);
	public labels = getLabels(this.urlService);



	readonly birthday = computed<CommonDateTimeField>(() => ({
		config: signal<CommonDateTimeFieldConfig>({ label: this.labels.form.Birthday(), required: false, isSpanish: this.urlService.isSpanish(), type: 'birthday', includeTime: false }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			const birthdayUTC = user && user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
			return birthdayUTC;
		}),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			const user = this.app.data().user;
			console.log("service birthdayUTC", value)
			await this.func.profile.form.setBirthdayAndBirthyear({ birthdayUTC: value });

			const birthDate = this.util.date.getBirthMonthDayAndYearFromUTC(value);

			this.app.setUserData({ ...user, birthDay: birthDate.birthDay, birthYear: birthDate.birthYear });

		},
		error: signal(''),
	}));



	private readonly gendersOptions = computed(() => [
		{ value: 'F', text: this.labels.genderOption.Female() },
		{ value: 'M', text: this.labels.genderOption.Male() },
		{ value: 'N', text: this.labels.genderOption.NonBinary() },
		{ value: 'O', text: this.labels.genderOption.Other() },
	]);

	readonly gender = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Gender(), options: this.gendersOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setGender({ gender: value });
			this.app.setUserData({ ...user, gender: value });
		},
		error: signal(''),
	}));


	readonly genderOther = computed<CommonTextField>(() => ({
		config: signal({ label: this.labels.form.OtherGender(), min: 0, max: 45, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.genderOther || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			if (user.gender === 'O') {
				await this.func.profile.form.setGender({ gender: value });
				this.app.setUserData({ ...user, gender: value });
			}
		},
		error: signal(''),
	}));

	private readonly racesOptions = computed(() => [
		{ value: 'N', text: this.labels.raceOptions.AmericanIndian() },
		{ value: 'A', text: this.labels.raceOptions.Asian() },
		{ value: 'B', text: this.labels.raceOptions.AfricanAmerican() },
		{ value: 'P', text: this.labels.raceOptions.PacificIslander() },
		{ value: 'W', text: this.labels.raceOptions.White() },
	]);

	readonly races = computed<CommonMultiSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Race(), options: this.racesOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			const user = this.app.data().user;
			await this.func.profile.form.setRaces({ races: value });
			this.app.setUserData({ ...user, races: value });
		},
		error: signal(''),
	}));


	private readonly hispanicOptions = computed(() => [
		{ value: 'Y', text: this.labels.hispanicOptions.IAmHispanic() },
		{ value: 'N', text: this.labels.hispanicOptions.IAmNotHispanic() }
	]);
	readonly hispanicOrLatino = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.HispanicOrLatino(), options: this.hispanicOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setHispanic({ hispanic: pendingValue });
			this.app.setUserData({ ...user, hispanic: pendingValue });
		},
		error: signal(''),
	}));


	private readonly veteranOptions = computed(() => [
		{ value: 'Y', text: this.labels.veteranOptions.IAmVeteran() },
		{ value: 'N', text: this.labels.veteranOptions.IAmNotVeteran() }
	]);
	readonly veteran = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Veteran(), options: this.veteranOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setVeteran({ veteran: pendingValue });
			this.app.setUserData({ ...user, veteran: pendingValue });
		},
		error: signal(''),
	}));



	private readonly nativeEnglishOptions = computed(() => [
		{ value: 'Y', text: this.labels.nativeEnglishOptions.NativeSpeaker() },
		{ value: 'N', text: this.labels.nativeEnglishOptions.NotNativeSpeaker() }
	]);
	readonly nativeEnglish = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.NativeEnglishSpeaker(), options: this.nativeEnglishOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeEnglish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeEnglish({ nativeEnglish: pendingValue });
			this.app.setUserData({ ...user, nativeEnglish: pendingValue });
		},
		error: signal(''),
	}));


	private readonly nativeSpanishOptions = computed(() => [
		{ value: 'Y', text: this.labels.nativeSpanishOptions.NativeSpeaker() },
		{ value: 'N', text: this.labels.nativeSpanishOptions.NotNativeSpeaker() }
	]);
	readonly nativeSpanish = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.NativeSpanishSpeaker(), options: this.nativeSpanishOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeSpanish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeSpanish({ nativeSpanish: pendingValue });
			this.app.setUserData({ ...user, nativeSpanish: pendingValue });
		},
		error: signal(''),
	}));

}