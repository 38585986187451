import { computed } from '@angular/core';


export function getLabels(isSpanish: boolean | undefined) {

	const en = computed(() => !isSpanish);

	return {

		missingRequired: computed(() => en() ? `Required field` : `Campo requerido`),
		optionalWithParentheses: computed(() => en() ? `(optional)` : `(opcional)`),
		select: computed(() => en() ? `Select` : `Seleccionar`),
		invalidSelection: computed(() => en() ? `Invalid Selection` : `Selección inválida`),
		notValidUrlPattern: computed(() => en() ? `Invalid URL (please include http:// or https://)` : `URL inválido (por favor incluya http:// o https://)`),
		notValidPhonePattern: computed(() => en() ? `Invalid phone number` : `Número de teléfono inválido.`),
		minPhoneLength: computed(() => en() ? `Phone must be at least 10 digits long.` : `El teléfono debe tener al menos 10 dígitos.`),
		maxPhoneLength: computed(() => en() ? `Phone must be shorter than 10 digits.` : `El teléfono debe ser más corto que 10 dígitos.`),
		notValidEmail: computed(() => en() ? `Invalid email address` : `Dirección de correo electrónico inválida`),
		notValidDate: computed(() => en() ? `Invalid date` : `Fecha inválida`),
		notValidAddress: computed(() => en() ? `Invalid address` : `Dirección inválida`),
		missingZip: computed(() => en() ? `Please enter a valid US zip code` : `Por favor, ingrese un código postal válido de EEUU`),
	};
}
