import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		// Materials: computed(() => en() ? `Materials` : `Materiales`),
		MdNoMaterialsbefore48hoursMsg: computed(() => en() ? `The course materials for this topic will be made available on ` : `Los materiales del curso para este tema estarán disponibles el `),
		MdSurveyAvailableMsg: computed(() => en() ? `Take survey! Available for {{available-for}}.` : `Realizar encuesta! Disponible para {{available-for}}.`),
		MdSurveyMarkedAbsentMsg: computed(() => en() ? `You cannot take this survey because you were marked absent. If you need assistance, reach out to the Program Manager at {{pm-email}}.` : `No puede realizar esta encuesta porque se le marcó como ausente. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.`),
		MdSurveyMissedMsg: computed(() => en() ? `You have missed the deadline to complete this survey. The deadline was {{deadline}} and it is now {{now}}.` : `No cumplió con la fecha límite para completar esta encuesta. La fecha límite era el {{deadline}} y ahora es el {{now}}.`),
		MdSurveyNotAvailableMsg: computed(() => en() ? `The Program Manager has not made this survey available to you. If you need assistance, reach out to the Program Manager at {{pm-email}}.` : `El administrador del programa no ha puesto esta encuesta a su disposición. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.`),
		SessionNoTopicsMsg: computed(() => en() ? `This session does not have any topics.` : `Esta sesión no tiene ningún tema.`),
		SessionTopicNoMaterialsMsg: computed(() => en() ? `This topic does not have any course material.` : `Este tema no tiene ningún material de curso.`),
		SurveyCompleteMsg: computed(() => en() ? `Thank you. You have succesfully completed this survey.` : `Gracias. Ha completado con éxito esta encuesta.`),
		EventDetails: computed(() => en() ? `Event Details` : `Detalles del Evento`),
		Venue: computed(() => en() ? `Venue` : `Lugar`),
		Online: computed(() => en() ? `Online` : `En línea.`),
		Starts: computed(() => en() ? `Starts` : `Comienza`),
		Ends: computed(() => en() ? `Ends` : `Termina`),
		Materials: computed(() => en() ? `Materials` : `Materiales`),
		ClassSurvey: computed(() => en() ? `Class Survey` : `Encuesta de Clase`),
		Map: computed(() => en() ? `Map` : `Mapa`),
		Website: computed(() => en() ? `Website` : `Página web`),
		EventLink: computed(() => en() ? `Event Link` : `Enlace del Evento`),
		Notes: computed(() => en() ? `Notes` : `Notas`),

		eventTypes: <{ [index: string]: Signal<string | undefined> }>{
			'Accelerator Showcase': computed(() => en()
				? 'Accelerator Showcase'
				: 'Exhibición del Acelerador'
			),
			'Accelerator Session': computed(() => en()
				? 'Accelerator Session'
				: 'Sesión del acelerador'
			),
			'Accelerator Mentor Matching': computed(() => en()
				? 'Accelerator Mentor Matching'
				: 'Asignación de mentores del acelerador'
			),
			'Accelerator Final Pitch': computed(() => en()
				? 'Accelerator Final Pitch'
				: 'Presentación final del acelerador'
			),
			'Accelerator Quarterlies Q2': computed(() => en()
				? 'Accelerator Quarterlies Q2'
				: 'Acelerador de Negocios Reuniones Trimestrales Q2'
			),
			'Accelerator Quarterlies Q3': computed(() => en()
				? 'Accelerator Quarterlies Q3'
				: 'Acelerador de Negocios Reuniones Trimestrales Q3'
			),
			'Accelerator Quarterlies Q4': computed(() => en()
				? 'Accelerator Quarterlies Q4'
				: 'Acelerador de Negocios Reuniones Trimestrales Q4'
			),
		},
	};
}
