<mobile-page-part>
	<div class="info-content">
		Page 2 of 3
	</div>

	<p class="p-0 m-0">We noticed you have selected <b>"{{form.questionnaireService.selectedRoleText()}}"</b>...</p>
	<h3>Please tell us about your business</h3>
	<div class="d-flex flex-column gap-2">
		<common-select-field [field]="form.businessType()"></common-select-field>
		<common-select-field [field]="form.businessStage()"></common-select-field>
		<common-select-field [field]="form.businessOfferings()"></common-select-field>
		<common-select-field [field]="form.businessIndustry()"></common-select-field>
	</div>
	<common-button-column>
		<button class="btn btn-primary btn-large" routerLink="../page-3"
			[disabled]="!form.isValidInputs()">{{form.questionnaireService.labels.button.Next()}}</button>
	</common-button-column>
</mobile-page-part>