
const TIME_OPTIONS: {
	hour: "numeric",
	minute: "2-digit",
	hour12: true,
	timeZoneName: "short",
} = {
	hour: "numeric",
	minute: "2-digit",
	hour12: true,
	timeZoneName: "short",
};


const DATE_OPTIONS_MMDDYYYY: {
	year: "numeric",
	month: "numeric" | "2-digit" | "long" | "short" | "narrow",
	day: "numeric",
} = {
	year: "numeric",
	month: "2-digit",
	day: "numeric",
};

const DATE_OPTIONS_MMDD: {
	month: "numeric" | "2-digit" | "long" | "short" | "narrow",
	day: "numeric",
} = {
	month: "long",
	day: "numeric",
};


const DATE_OPTIONS_MMMDYYYY: {
	year: "numeric",
	month: "numeric" | "2-digit" | "long" | "short" | "narrow",
	day: "numeric",
} = {
	year: "numeric",
	month: 'short',
	day: "numeric",
};



export const DateUtilities = {

	/**
	 * Calculate the time period between 2 dates. Ex. Days, Hours, Minutes.
	 * @param event 
	 */
	calculateTimePeriod: (startUTC: number, endUTC: number, isSpanish: boolean = false) => {
		const MINUTE = 60;
		const HOUR = 60 * MINUTE;
		const DAY = 24 * HOUR;


		const elapsed = endUTC - startUTC;

		const handlePlural = (div: number, unit: 'Day' | 'Día' | 'Hour' | 'Hora' | 'Minute' | 'Minutos'): string => {
			const value: number = Math.round(elapsed / div);
			return value + ' ' + (value == 1 ? unit : unit + 's');
		}

		if (elapsed >= DAY) return handlePlural(DAY, isSpanish ? 'Día' : 'Day');
		else if (elapsed >= HOUR) return handlePlural(HOUR, isSpanish ? 'Hora' : 'Hour');
		else return handlePlural(MINUTE, isSpanish ? 'Minutos' : 'Minute');

	},



	/**
	 * Return JS Date from UTC
	 */
	fromUTC: (utc: number | undefined) => {
		if (utc == undefined) return undefined;
		return new Date(utc * 1000);
	},


	/**
	 * Return UTC from JS Date
	 */
	toUTC: (date: Date | undefined) => {
		if (date == undefined) return undefined;
		return date.getTime() / 1000;
	},


	/**
	 * Calculate the days from the UTC till now. Ex. a year ago, 9 months ago etc.
	 */
	getDaysSince: (utc: number): string => {

		//
		// This logic is made to emulate how moment.fromNow() works.
		// See: https://momentjs.com/docs/#/displaying/fromnow/
		//

		const secs = (Date.now() - utc * 1000) / 1000;

		if (secs < 0) return `in the future`;
		if (secs <= 44) return `a few seconds ago`;
		if (secs <= 89) return `a minute ago`;

		const mins = Math.ceil(secs / 60);

		if (mins <= 44) return `${mins} minutes ago`;
		if (mins <= 89) return `an hour ago`;

		const hours = Math.ceil(mins / 60);

		if (hours <= 21) return `${hours} hours ago`;
		if (hours <= 35) return `a day ago`;

		const days = Math.ceil(hours / 24);

		if (days <= 25) return `${days} days ago`;
		if (days <= 45) return `a month ago`;

		const months = Math.ceil(days / 30);

		if (days <= 319) return `${months} months ago`;
		if (days <= 547) return `a year ago`;

		const years = Math.ceil(days / 356.25);

		return `${years} years ago`;
	},



	/**
	 * Convert MM-DD in this format to a Month, Day format
	 * @param date 
	 */
	formatMonthDay: (date: string) => {
		if (date.length !== 5 || date[2] != '-') throw new Error(`Date format is not supported.`);

		const currentYear = new Date().getFullYear;
		date = `${currentYear}-${date}`;

		return DateUtilities.formatUTC(Date.parse(date) / 1000, "MMM D", "No Time", 'en-US');
	},


	/**
	 * Convert YYYY-MM-DD in this format to a Day Month, Year format
	 * @param date 
	 */
	formatYearMonthDay: (date: string) => {
		if (date.length !== 10 || date[4] != '-' || date[7] != '-') throw new Error(`Date format is not supported.`);

		return DateUtilities.formatUTC(Date.parse(date) / 1000, "MMM D, YYYY", "No Time", 'en-US');
	},

	/**
	 *  Given the day and time string check the correct format and return concatnated string
	 * @param day -  YYYY-MM-DD
	 * @param time - HH:MM
	 * @returns `YYYY-MM-DD HH:MM:00 GMT`
	 */
	getDateString: (day: string, time: string) => {
		//
		// Check if the day string is not the standard 'YYYY-MM-DD'
		//
		if (day.length !== 10 || day[4] != '-' || day[7] != '-') throw new Error(`Date format is not supported.`);
		if (time.length !== 5 || day[2] != ':') throw new Error(`Time format is not supported.`);

		const utc = Date.parse(day);

		// Convert to date and time string i.e. YYYY-MM-DD HH:MM:00 GMT
		return `${day} ${time}:00 GMT`;
	},


	/**
	 * Given the UTC return a string formatted 'YYYY-MM-DD'
	 * @param utc 
	 * @returns 
	 */
	getYYYYMMDDFromUTC: (utc: number): string => {
		const date = new Date(utc * 1000);
		let month = '' + (date.getMonth() + 1);
		let day = '' + date.getDate();
		const year = date.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	},



	/**
	 * Based on date and time strings return out the milliseconds for the timeZone.
	 * @param day YYYY-MM-DD string
	 * @param time HH:MM string
	 */
	getSpecificTimeAtTimeZone: (day: string, time: string, timeZone: string): number => {

		const options = <Intl.DateTimeFormatOptions><unknown>{ timeZoneName: 'shortOffset', timeZone };
		const intlDateTimeFormat = new Intl.DateTimeFormat('en-US', options);

		// Convert to date and time string i.e. YYYY-MM-DD HH:MM:00 GMT
		const dateStr = DateUtilities.getDateString(day, time);
		const parsedDate = Date.parse(dateStr);


		//
		// Use the Intl.DateTimeFormat to get the correct GMT offset for the site timeZone
		//
		const gmt = intlDateTimeFormat.format(parsedDate).split('GMT');


		//
		// If for some reason the offset is not GMT throw an error.
		//
		if (gmt.length !== 2) throw new Error(`The current time format does not include GMT offset.`)

		const dateStrWithGMTOffset = dateStr + gmt[1];


		//
		// Return the date in MS to store in the database
		//
		return Date.parse(dateStrWithGMTOffset) / 1000;

	},

	/**
	 * Given the current utc from our database in ms, return the date and time string based on the format options.
	 */
	formatUTC: (
		/** A database UTC that does not include ms*/
		utc: number,
		/** May render differently for different locales */
		dateFormat: 'No Date' | 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)' | 'DOW MMM D, YYYY' | 'MMM D',
		/** May render differently for different locales */
		timeFormat: 'No Time' | 'H:MM AM EST',

		locale: 'en-US' | 'es-US',

		/** Leave blank to use the users timezone */
		timeZone?: string,
	): string => {

		if (!utc) return 'No UTC value';

		const date = new Date(utc * 1000);

		// Show both date and time
		if (dateFormat != 'No Date' && timeFormat != 'No Time') {
			return DateUtilities.formatDateAndTime(date, dateFormat, locale, timeZone);
		}
		//Show only time
		else if (dateFormat == 'No Date' && timeFormat == 'H:MM AM EST') {
			return DateUtilities.formatTime(date, locale, timeZone);
		}
		// Show only date
		else if (dateFormat !== 'No Date' && timeFormat == 'No Time') {
			return DateUtilities.formatDate(date, dateFormat, locale, timeZone);
		}


		return 'Invalid parameters';

	},

	/**
	 * Given the current Date, return the date string based on the format.
	 */
	formatDate: (
		date: Date,
		/** May render differently for different locales */
		dateFormat: 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)' | 'DOW MMM D, YYYY' | 'MMM D',
		locale: 'en-US' | 'es-US',
		timeZone?: string,
	) => {

		if (!date) return 'No Date value';

		const dateOptions = dateFormat == 'MM/DD/YYYY' ? DATE_OPTIONS_MMDDYYYY : dateFormat == 'MMM D' ? DATE_OPTIONS_MMDD : DATE_OPTIONS_MMMDYYYY;

		const options: Intl.DateTimeFormatOptions = { ...dateOptions, timeZone };

		const formattedDateAndTime = new Intl.DateTimeFormat(locale, options).format(date);

		if (dateFormat == 'MMM D, YYYY (DOW)') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'short', timeZone }).format(date);
			return `${formattedDateAndTime} (${weekday})`;
		}
		else if (dateFormat == 'DOW MMM D, YYYY') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'long', timeZone }).format(date);
			return `${weekday} ${formattedDateAndTime}`;
		}

		return formattedDateAndTime;
	},


	/**
	 * Given the current Date, return the time string.
	 */
	formatTime: (date: Date, locale: 'en-US' | 'es-US', timeZone?: string) => {

		if (!date) return 'No Date value';

		const options: Intl.DateTimeFormatOptions = { ...TIME_OPTIONS, timeZone };

		return new Intl.DateTimeFormat(locale, options).format(date);
	},

	/**
	 * Given the current Date, return the date and time based on the format.
	 */
	formatDateAndTime: (
		date: Date,
		/** May render differently for different locales */
		dateFormat: 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)' | 'DOW MMM D, YYYY' | 'MMM D',
		locale: 'en-US' | 'es-US',
		timeZone?: string,
	) => {

		if (!date) return 'No Date value';

		const dateOptions = dateFormat == 'MM/DD/YYYY' ? DATE_OPTIONS_MMDDYYYY : dateFormat == 'MMM D' ? DATE_OPTIONS_MMDD : DATE_OPTIONS_MMMDYYYY;

		const options: Intl.DateTimeFormatOptions = { ...dateOptions, ...TIME_OPTIONS, timeZone };

		const formattedDateAndTime = new Intl.DateTimeFormat(locale, options).format(date);

		if (dateFormat == 'MMM D, YYYY (DOW)') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'short', timeZone }).format(date);
			return `${formattedDateAndTime} (${weekday})`;
		}
		if (dateFormat == 'DOW MMM D, YYYY') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'long', timeZone }).format(date);
			return `${weekday} ${formattedDateAndTime}`;
		}

		return formattedDateAndTime;
	},


	getTimezone(): string {
		let tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Unknown';

		const country = 'America/';
		if (tz.startsWith(country)) tz = tz.substr(country.length);

		tz = tz.trim().split('_').join(' ');

		return tz || 'Unknown';
	},

	adjustDateByYears(num: number, type: 'plus' | 'minus', date?: Date, includeTime: boolean = false) {
		if (!date) date = new Date();

		// Adjust the year based on the type parameter
		if (type === 'plus') {
			date.setFullYear(date.getFullYear() + num);
		} else if (type === 'minus') {
			date.setFullYear(date.getFullYear() - num);
		}


		// Format the date as 'yyyy-mm-dd'
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const day = String(date.getDate()).padStart(2, '0');

		let formattedDate = `${year}-${month}-${day}`;

		// Optionally include time in the format 'yyyy-mm-ddThh:mm'
		if (includeTime) {
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			formattedDate += `T${hours}:${minutes}`;
		}


		return formattedDate;
	},


	getBirthMonthDayAndYearFromUTC(timestampUTC: number | undefined): { birthDay: number | undefined, birthYear: number | undefined } {

		if (!timestampUTC) return { birthDay: undefined, birthYear: undefined };


		const date = new Date(timestampUTC * 1000); // Create a Date object from the timestamp

		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();

		const birthDay = month * 100 + day;

		return { birthDay, birthYear: year };
	},

	secondsToMinutes(seconds: number): string {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;

		// Pad single-digit seconds with a leading zero
		const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

		return `${minutes}:${paddedSeconds}`;
	}
};
