
import { Component, OnInit, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonSelectField } from '../field';
import { getLabels } from '../field.labels';


@Component({
	selector: 'common-select-field',
	imports: [FormsModule],
	templateUrl: './common-select-field.widget.html'
})
export class CommonSelectFieldWidget<T> extends CommonFieldWidgetBase<T, CommonSelectField<T>> implements OnInit {

	public readonly field = input.required<CommonSelectField<T>>();

	public readonly labels = computed(() => getLabels(this.field().config().isSpanish));

	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});


	public readonly defaultValue = computed(() => typeof this.field().actualValue() == 'number' ? 0 : '');



	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T) {
		this.field().pendingValue.set(value);

		this.onBlur();
	}

	public async onBlur() {
		await this.blur(this.field());
	}


	protected validate(): string {

		const value = this.getValue(this.field());
		const config = this.field().config();

		if (!value && config.required) return this.labels().missingRequired();

		return '';
	}
}