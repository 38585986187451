<mobile-page-part>

	<div class="info-content">
		<h3>{{ labels.Feedback() }}</h3>
	</div>

	@if(feedback().length) {
	<div class="white-box">
		{{ labels.feedbackOverview() }}
	</div>
	}




	@if(!feedback().length){
	<h4 class="text-center">{{ labels.NoFeedback() }}</h4>
	}
	@else {
	<div>
		<h4>{{ labels.FeedbackFor() }}</h4>
		<select class="form-select form-select-sm" [ngModel]="selectedFeedback()?.key" (ngModelChange)="onChange($event)">
			<option disabled value="">{{labels.form.SelectEllipses()}}</option>
			@for(option of feedback(); track option.key;)
			{
			<option [value]="option.key">{{option.siteName}} {{option.programInstanceName}} {{option.programName}}
			</option>
			}
		</select>
	</div>

	@if(selectedFeedback(); as selectedFeedback){
	@for(data of selectedFeedback.feedback; track $index;){
	<!-- <h3>{{data.reviewerLabel}} <small>({{data.polishedFeedback.length}})</small></h3> -->
	@for(feedback of data.polishedFeedback; track $index; let idx = $index){
	<div class="white-box">
		<h4>{{labels.messages[data.reviewerLabel]!()}} | {{idx+1}} {{labels.Of()}} {{data.polishedFeedback.length}}</h4>
		{{feedback}}
	</div>
	}
	}
	}
	}

</mobile-page-part>
