import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { AppService } from '../../../services';
import { AgreementService } from '../agreements.service';
import { getLabels } from './agreement.page.labels';


interface AgreementData {
	agreementVersionId: number,
	signedDate: string | undefined,
	name: string,
	introduction: string,
	agreement: string,
}


@Component({
	selector: 'agreement-page',
	imports: [
		CommonButtonRowWidget,
		CommonMessageWidget,
		FormsModule,
		MarkdownModule,
		MobilePagePart,
		NgbAccordionModule,
	],
	providers: [provideMarkdown()],
	templateUrl: './agreement.page.html'
})

export class AgreementPage implements OnInit {

	private frame = inject(MobileFrameService);
	private router = inject(Router);
	private util = inject(UtilityService);
	private agreementService = inject(AgreementService);
	private appService = inject(AppService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public agreement = computed<AgreementData | undefined>(() => {
		const url = this.urlService.url();
		const agreements = this.agreementService.agreements();
		if (!url || !agreements.length) return;

		const isSpanish = this.urlService.isSpanish();


		const urlParts = url.split('/');

		const path = urlParts[urlParts.length - 1];

		//
		// Check if the path is the correct slug in agreement types
		//
		const agreement = agreements.find(type => type.slug == path);

		//
		// If the path doesn't match a slug from our DB, return to dashboard
		//
		if (!agreement) {
			this.router.navigate([this.urlService.withCurrentLanguage('/')]);
			return;
		}


		return {
			agreementVersionId: agreement.agreementVersionId,
			agreement: isSpanish ? agreement.agreementES : agreement.agreementEN,
			introduction: isSpanish ? agreement.introductionES : agreement.introductionEN,
			name: isSpanish ? agreement.nameES : agreement.nameEN,
			signedDate: agreement.signedUTC ? this.util.date.formatUTC(agreement.signedUTC, 'MMM D, YYYY', 'No Time', this.urlService.languageCode()) : '',
		}


	});


	public readonly isAcknowledgeChecked = signal(false);
	public readonly userNameFieldValue = signal('');

	public userName = computed(() => {
		const user = this.appService.data().user;
		return user ? user.firstName + ' ' + user.lastName : '';
	});
	public readonly hasFocus = signal(false);
	public readonly hadFocus = signal(false);

	public onFocus() {
		this.hadFocus.set(true);
		this.hasFocus.set(true);
	}

	public errorToShow = computed(() => {
		if (this.hasFocus() || !this.hadFocus()) return '';
		const user = this.userName();
		if (!this.valuesAreSame()) return `${this.labels.SignatureMustMatch()} "${user}"`;
		return '';
	});

	public valuesAreSame = computed(() => {
		const userNameField = this.userNameFieldValue().trim();
		const user = this.userName();
		return userNameField && userNameField.toLowerCase() == user.toLowerCase();
	});


	async ngOnInit() {

		await this.agreementService.getAndSetAgreements();
		this.frame.setUrlMetadata(
			{
				url: `/agreements`,
				backUrl: `/`,
				pageName: computed(() => this.labels.Agreement()),
				headerText: computed(() => this.labels.Agreement()),
			});

		for (const data of this.agreementService.agreements() || []) {
			const pageName = data.nameEN;
			const url = `/agreements/${data.slug}`;
			this.frame.setUrlMetadata({
				url,
				backUrl: `/agreements`,
				pageName: computed(() => pageName),
				headerText: computed(() => this.labels.Agreement()),
			});
		}

	}


	public onChange(value: string) {
		this.userNameFieldValue.set(value);
	}

	public onBlur() {
		this.hasFocus.set(false);
	}


	async signAgreement() {

		const agreement = this.agreement();
		if (agreement) {
			await this.agreementService.signAgreement(agreement.agreementVersionId)
		}
	}
}