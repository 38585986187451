@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">
	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-triangle-exclamation" text="{{header() |titlecase}}" spacing="large" iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex justify-content-center flex-column mt-2">
			<h1>
				<common-icon-text iconStyle="fa-solid" icon="fa-triangle-exclamation" [text]="''" spacing="large" iconSize="xx-large" class="d-flex justify-content-center flex-column" messageType="alert">
				</common-icon-text>
			</h1>
		</div>
		<div class="mb-3 flex-fill">
			<div class="container text-center px-0">
				<markdown [data]="message()"></markdown>
				<div class="btn-container w-100">
				</div>
			</div>
		</div>
		<common-button-column [stretch]='true'>
			<button class="btn btn-primary btn-large" (click)="confirm()">
				{{ labels.Confirm() }}
			</button>
			<button class="btn btn-standard btn-large" (click)="close()">
				{{ labels.button.Cancel() }}
			</button>
		</common-button-column>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}