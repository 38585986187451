import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		NoSurveyOpen: computed(() => en() ? `No Survey open at this time` : `{{ES : No Survey open at this time}}`),
		AccSessionSurvey: computed(() => en() ? `Accelerator Session Survey` : `Sesión de aceleración Survey`),
		Closes: computed(() => en() ? `Closes` : `{{ ES : Closes}}`),
		Complete: computed(() => en() ? `Complete` : `{{ ES : Complete}}`),
		NoOpenSurveys: computed(() => en() ? `No Open Surveys` : `{{ ES : No Open Surveys}}`),
		Surveys: computed(() => en() ? `Surveys` : `{{ ES : Surveys}}`),
		OpenSurvey: computed(() => en() ? `Open Survey` : `{{ ES : Open Survey}}`),
		OpenSurveys: computed(() => en() ? `Open Surveys` : `{{ ES : Open Surveys}}`),
	}
}
