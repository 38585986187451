import { inject, Injectable, signal } from "@angular/core";
import { UrlService } from "@eforall/common";
import {
	CourseBasicDetails,
	CourseDetailsQueryResult,
	CourseEnrollment,
	CourseVersion,
	EntrepreneurPhaseEnum,
} from '@interfaces';
import { AppService, FuncService } from "../../services";


@Injectable({ providedIn: 'root' })
export class AllCoursesService {

	private func = inject(FuncService);
	private app = inject(AppService);
	public urlService = inject(UrlService);
	private readonly loaded = signal<boolean>(false);
	private readonly _allcourses = signal<CourseBasicDetails[]>([]);
	public readonly allcourses = this._allcourses.asReadonly();

	public async loadAllData() {
		console.log("AllCoursesService loadAllData")
		if (!this.loaded()) {
			await this.getAllCoursesAndEnrollment();
			this.loaded.set(true);
		}
	}

	async getAllCoursesAndEnrollment() {
		// setTimeout(async () => { // TODO: TEMP FIX as we are not getting app data.
		if (!this._allcourses().length) {
			const courses = await this.func.courses.getAllCourses();
			const userdata = this.app.data().user;
			if (courses && userdata) {
				courses.forEach((course: CourseDetailsQueryResult) => {
						switch (userdata.phase) {
							case EntrepreneurPhaseEnum.Prospect:
								course.canEnroll = (course.prospectSelfEnrollment === 'Y');
								break;
							case EntrepreneurPhaseEnum.Cohort:
								course.canEnroll = (course.prospectSelfEnrollment === 'Y' || course.cohortSelfEnrollment === 'Y');
								break;
							case EntrepreneurPhaseEnum.Alum:
								course.canEnroll = (course.prospectSelfEnrollment === 'Y' || course.cohortSelfEnrollment === 'Y' || course.alumSelfEnrollment === 'Y');
								break;
						}
						const userCourseEnrollments = this.app.data().courseEnrollments;
						course.courseEnrollment = userCourseEnrollments.find((ce) => ce.courseVersionId === course.latestVersionId);
						course.enrolledToPreviousVersion = !!userCourseEnrollments.find((ce) => ce.courseTypeId === course.courseTypeId && ce.courseVersionId != course.latestVersionId);
					});
			};
			this._allcourses.set(courses || []);
		}
		// }, 1000);


	}

	public replaceCourseEnrollment(course: CourseVersion) {
		const enrollmentData: CourseEnrollment = {
			courseEnrollmentId: course.courseEnrollmentId, courseVersionId: course.courseVersionId, courseTypeId: course.courseTypeId,
			completedUTC: course.courseCompletedUTC, accId: course.accId, completionPercentage: course.courseCompletionPercentage ?? 0,
		};
		this.app.setCourseEnrollmentData(enrollmentData);

		const courses = this.allcourses();
		if (courses) {
			const courseIndex = courses.findIndex(c => course.courseVersionId == c.latestVersionId);
			if (courseIndex != -1) {
				courses[courseIndex]!.courseEnrollment = enrollmentData;
				this._allcourses.set([...courses]);
			}
		}

	}
}