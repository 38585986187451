import { computed, Signal } from '@angular/core';
import { UrlService } from '../../../services/url/url.service';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;
	return {
		messages: <{ [index: string]: Signal<string | undefined> }>{
			information: computed(() => en() ? 'Information' : '{{ ES: Information }}'),
			alert: computed(() => en() ? 'Alert' : '{{ ES: Alert }}'),
			error: computed(() => en() ? 'Error' : '{{ ES: Error }}'),
			success: computed(() => en() ? 'Success' : '{{ ES: Success }}'),
		}
	}
}
