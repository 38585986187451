import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonOptionsFieldConfig, CommonSelectField, UrlService } from "@eforall/common";
import { PhoneTypeId } from "@interfaces";
import { AppService, FuncService } from "services";
import { getLabels } from "./notification-settings.page.labels";

@Injectable({ providedIn: 'root' })
export class NotificationSettingsFormService {

	private readonly urlService = inject(UrlService);
	public readonly labels = getLabels(this.urlService);
	private func = inject(FuncService);
	private app = inject(AppService);

	private readonly phoneTypeIdOptions = computed(() => [
		{ value: PhoneTypeId.Mobile, text: this.labels.SendMeTextNotifications() },
		{ value: PhoneTypeId.Mobile_DoNotText, text: this.labels.DoNotSendMeTextNotifications() },
	]);

	readonly phoneTypeId = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.labels.form.SelectEllipses(), options: this.phoneTypeIdOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.phoneTypeId || PhoneTypeId.Mobile),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			await this.func.profile.form.setPhoneTypeId({ phoneTypeId: value });
			this.app.setUserData({ ...this.app.data().user, phoneTypeId: value });
		},
		error: signal(''),
	}));


	async setPrimaryEmail(emailId: number) {
		await this.app.update(this.func.email.setPrimary, { emailId });
	}



}