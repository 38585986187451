<mobile-page-part>

	<div class="info-content">
		<h3>{{form.goalsService.goalsList().length}} {{labels.GoalsEntered()}}</h3>
	</div>


	@if(form.goalsService.goalsAndAccess(); as goalsAndAccess){

	<mobile-list [items]="form.goalsService.goalsList()"></mobile-list>



	@if(goalsAndAccess.canAddGoal){

	@if(!form.showAddForm()){
	<common-button-row [center]="true">
		<button class="btn btn-primary btn-large" (click)="addAGoal()">{{labels.AddANewGoal()}}</button>
	</common-button-row>
	}
	@else {
	<div class="white-box d-flex flex-column gap-3">
		<h4 class="text-center">{{labels.GoalDetails()}}</h4>

		<common-text-field [field]="form.summary()"></common-text-field>
		<common-text-field [field]="form.details()"></common-text-field>


		<common-button-row [center]="true">
			<button class="btn btn-primary" (click)="form.createGoal()">{{labels.CreateGoal()}}</button>
			<button class="btn btn-standard" (click)="form.closeAddForm()">{{labels.button.Cancel()}}</button>
		</common-button-row>

	</div>
	}
	}
	}

</mobile-page-part>