import { Injectable, computed, signal } from '@angular/core';
import { CommonField } from './field';

@Injectable()
export abstract class CommonFieldWidgetBase<T, FIELD extends CommonField<unknown, T>> {

	public readonly hasFocus = signal(false);
	public readonly hadFocus = signal(false);
	public readonly randomId = `field-${Math.floor(Math.random() * (2 ** 32))}`;



	public readonly errorToShow = computed(() => {
		const error = this.validate();
		if (error == '') return '';
		if (this.hasFocus() || !this.hadFocus()) return '';
		return error;
	});


	protected valuesAreSame(value1: T, value2: T) {
		return value1 == value2;
	}


	public onFocus() {
		this.hadFocus.set(true);
		this.hasFocus.set(true);
	}



	public abstract onBlur(): Promise<void>;

	public async blur(field: FIELD) {

		this.hasFocus.set(false);
		let pendingValue = field.pendingValue();
		const actualValue = field.actualValue();



		if (pendingValue === null) {
			return;
		}



		const error = this.validate();
		field.error.set(error);
		if (error !== '') return;

		//
		// Clean up the value that the user entered in
		//
		pendingValue = this.cleanValue(pendingValue!);
		field.pendingValue.set(pendingValue);

		//
		// Save
		//
		field.saving.set(true);

		try {
			if (!this.valuesAreSame(pendingValue, actualValue) || (!pendingValue && !actualValue)) {
				await field.save(pendingValue);
			}
			// field.pendingValue.set(null);
		}
		catch (e) {
			console.error(e);
			field.error.set('Could not save.');
		}

		field.saving.set(false);

	}



	/**
	 * Check the pendingValue and determine if therre is an error. 
	 * Return the error or '' if none
	 */
	protected abstract validate(): string;

	protected getValue(field: FIELD) {
		const pendingValue = field.pendingValue();
		return pendingValue === null ? field.actualValue() : pendingValue;
	}

	protected cleanValue(value: T): T {
		return value;
	}


	protected render(value: T): T | string {
		return value;
	}
}