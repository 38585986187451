import { computed, inject, Injectable, Signal, signal } from "@angular/core";
import { UrlService, UtilityService } from "@eforall/common";
import { MobileListWithSubItem } from '@eforall/mobile';
import { CalendarEvent, EventTypeId } from "@interfaces";
import { FlyoutService, FuncService } from "../../services";
import { getLabels } from "./calendar.page.labels";

@Injectable({ providedIn: 'root' })
export class CalendarService {

	private func = inject(FuncService);
	public util = inject(UtilityService);
	private urlService = inject(UrlService);
	private flyoutService = inject(FlyoutService);

	private allCalenderEvents = signal<CalendarEvent[]>([]);
	public onGoingList: Signal<MobileListWithSubItem[]> = computed(() => this.setOnGoingList());
	public upcomingList: Signal<MobileListWithSubItem[]> = computed(() => this.setUpcomingList());
	public pastList: Signal<MobileListWithSubItem[]> = computed(() => this.setPastList());
	public labels = getLabels(this.urlService);

	public date = computed(() => this.util.date.formatDate(new Date(), 'MMM D, YYYY', this.urlService.languageCode()));


	async loadData() {
		this.allCalenderEvents.set(await this.func.calendar.get());
	}

	private setOnGoingList() {
		const currentUTC = Date.now() / 1000;
		const { endOfWeekUTC, startOfWeekUTC } = this.getWeekStartAndEndUTC(currentUTC);
		const onGoingList: MobileListWithSubItem[] = this.allCalenderEvents()
			.filter(event =>
				(event.toolStartUTC <= currentUTC && currentUTC <= event.toolEndUTC)
				||
				(event.startUTC <= endOfWeekUTC && event.endUTC >= startOfWeekUTC)
			).sort((a, b) => a.startUTC - b.startUTC)
			.reduce((a: MobileListWithSubItem[], event) => {

				const item = this.getItem(event);
				a.push(item);
				return a;
			}, [])
		return onGoingList;
	}


	private setPastList() {
		const currentUTC = Date.now() / 1000;
		const { startOfWeekUTC } = this.getWeekStartAndEndUTC(currentUTC)
		const pastList: MobileListWithSubItem[] = this.allCalenderEvents()
			.filter(event => event.endUTC < startOfWeekUTC)
			.sort((a, b) => b.startUTC - a.startUTC)
			.reduce((a: MobileListWithSubItem[], event) => {

				const item = this.getItem(event);
				a.push(item);
				return a;
			}, []);

		return pastList;
	}

	private setUpcomingList() {
		const currentUTC = Date.now() / 1000;
		const { endOfWeekUTC } = this.getWeekStartAndEndUTC(currentUTC)
		const upcomingList: MobileListWithSubItem[] = this.allCalenderEvents()
			.filter(event => event.startUTC > endOfWeekUTC)
			.sort((a, b) => a.startUTC - b.startUTC)
			.reduce((a: MobileListWithSubItem[], event) => {

				const item = this.getItem(event);
				a.push(item);
				return a;
			}, []);

		return upcomingList;
	}


	private getWeekStartAndEndUTC(currentUTC: number): { startOfWeekUTC: number, endOfWeekUTC: number } {
		const now = new Date(currentUTC * 1000);

		const currentDayOfWeek = now.getDay();

		// Calculate the start of the week (Sunday)
		const startOfWeek = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() - currentDayOfWeek));
		const startOfWeekUTC = startOfWeek.getTime() / 1000;

		// Calculate the end of the week (Saturday 23:59:59)
		const endOfWeek = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + (6 - currentDayOfWeek) + 1, 0, 0, 0));
		const endOfWeekUTC = endOfWeek.getTime() / 1000;

		return {
			startOfWeekUTC,
			endOfWeekUTC
		};
	}


	private getItem(event: CalendarEvent): MobileListWithSubItem {

		let item: MobileListWithSubItem = {
			icon: 'fa-help',
			iconStyle: 'fa-regular',
			text: this.labels.Unknown(),
		};

		const text = this.formatEventDates(event.startUTC, event.endUTC, this.urlService.languageCode());
		const callback = () => { this.flyoutService.showCalendarEvent(event); }

		let iconStyle = 'fa-solid';
		if (event.surveyStatus) {
			const status = event.surveyStatus;
			if (status == 'Complete') {
				item = {
					icon: `fa-circle color-green`,
					iconStyle,
					text,
					subText: this.labels.AcceleratorSession() + ': ' + this.labels.SurveyComplete(),
					callback,
				}
			}
			if (status == 'Missed' || status == 'Unavailable') item = {
				icon: `fa-circle color-darkred`,
				iconStyle,
				text,
				subText: this.labels.AcceleratorSession() + ': ' + (status == 'Missed' ? this.labels.SurveyMissed() : this.labels.SurveyUnavailable()),
				callback,
			};

			if (status == 'NotOpenYet') {
				const topicText = event.countOfTopics ? ': ' + this.util.text.singularOrPluralWithCount(event.countOfTopics, this.labels.common.Topic(), this.labels.common.Topics()) : '';
				item = {
					icon: `fa-circle color-lightgray`,
					iconStyle,
					text,
					subText: this.labels.AcceleratorSession() + topicText,
					callback,
				};
			}
			if (status == 'Open') item = {
				icon: `fa-circle color-eforall-blue`,
				iconStyle,
				text,
				subText: this.labels.AcceleratorSession() + ': ' + this.labels.SurveyOpen(),
				callback,
			};

		}
		else {
			iconStyle = 'fa-regular';
			if (event.eventTypeId == EventTypeId.AccFinalPitch) item = { icon: 'fa-user-chart', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccMentorMatching) item = { icon: 'fa-users', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ2) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ3) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccQuarterliesQ4) item = { icon: 'fa-chart-pie-alt', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
			if (event.eventTypeId == EventTypeId.AccShowcase) item = { icon: 'fa-award', iconStyle, text, subText: this.labels.eventTypes[event.eventType]?.() ?? event.eventType, callback, };
		}

		return item;
	}



	private formatEventDates(startUTC: number, endUTC: number, locale: 'en-US' | 'es-US'): string {
		const startDate = this.util.date.formatUTC(startUTC, 'DOW MMM D, YYYY', 'No Time', locale);
		const endDate = this.util.date.formatUTC(endUTC, 'DOW MMM D, YYYY', 'No Time', locale);
		const startTime = this.util.date.formatUTC(startUTC, 'No Date', 'H:MM AM EST', locale);
		if (startDate == endDate) {
			const endTime = this.util.date.formatUTC(endUTC, 'No Date', 'H:MM AM EST', locale);
			return `${startDate} ${startTime.split(' ')[0]}-${endTime}`;
		} else {
			return `${startDate} ${startTime}`;
		}
	}

}
