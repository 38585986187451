import { inject, Injectable } from "@angular/core";
import { UrlService, UtilityService } from "@eforall/common";
import { FlyoutService } from "../../../services";

@Injectable({ providedIn: 'root' })
export class SocialLinkService {

	public util = inject(UtilityService);
	public urlService = inject(UrlService);
	public flyoutService = inject(FlyoutService);
}