import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		MyTeam: computed(() => en() ? `My Team` : `Mi equipo`),
		NoTeam: computed(() => en() ? `No Team` : `{{ ES: No Team }}`),
		TeamMember: computed(() => en() ? `Team Member` : `Miembro del Equipo`),
		TeamMembers: computed(() => en() ? `Team Members` : `Miembros del Equipo`),
		ViewApplication: computed(() => en() ? `View Application` : `Ver Aplicación`),
		Entrepreneurs: computed(() => en() ? `Entrepreneurs` : `Emprendedores`),
		NoEntrepreneurs: computed(() => en() ? `No Entrepreneurs` : `No hay Emprendedores`),
		Mentors: computed(() => en() ? `Mentors` : `Mentores`),
		NoMentors: computed(() => en() ? `No Mentors` : `No hay Mentores`),
		OrgType: computed(() => en() ? `Org Type` : `Tipo de organización`),
		Industry: computed(() => en() ? `Industry` : `Industria`),
		Offerings: computed(() => en() ? `Offerings` : `Ofertas`),
		OperatingStatus: computed(() => en() ? `Operating Status` : `Estado Operativo`),

	};
}