import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		BusinessDetails: computed(() => en() ? `Business Details` : `Detalles del Negocio`),
		BusinessName: computed(() => en() ? `Business Name` : `{{ ES : Business Name }}`),
		ShortName: computed(() => en() ? `Short Name` : `{{ ES : Short Name}}`),
		BusinessType: computed(() => en() ? `Business Type` : `{{ ES : Business Type}}`),
		Industry: computed(() => en() ? `Industry` : `Industria`),
		Offering: computed(() => en() ? `Offering` : `Oferta`),
		OperatingStatus: computed(() => en() ? `Operating Status` : `{{ ES : Operating Status }}`),
		Products: computed(() => en() ? `Products` : `{{ ES : Products }}`),
		Services: computed(() => en() ? `Services` : `{{ ES : Services }}`),
		ProductsAndServices: computed(() => en() ? `Products and Services` : `{{ ES : Products and Services }}`),
	};
}
