import { Injectable, signal } from '@angular/core';
declare const Wistia: any;

interface wq {
	id: string,
	onReady: (video: unknown) => void,
}

export interface WistiaPlayer {
	/**
	 * Replaces the content of the current video with the video identified by hashedId.
	 */
	replaceWith: (hashedId: string, options: { autoPlay: boolean, fitStrategy: 'contain' | 'cover' | 'fill' | 'none', transition: "slide" | "fade" | "crossfade" | "none" }) => void,

	/**
	 * Returns true if the video is ready to be played, false if it is not
	 */
	ready: () => boolean,

	/**
	 * Sets the height of the video container to val in pixels
	 * If constrain: true is passed as an option, then the height of the video will also be updated to maintain the correct aspect ratio.
	 */
	height: (px: number, options?: { constrain: boolean }) => void,
	/**
	 * Returns the percent of the video that has been watched as a decimal between 0 and 1.
	 */
	percentWatched: () => number,
	/**
	 * Returns the number of unique seconds that have been watched for the video
	 */
	secondsWatched: () => number,

	/**
	 * Returns an array where each index represents the number of times the viewer has watched each second of the video.
	 */
	secondsWatchedVector: () => number[],
	/**
	 * Seeks the video to the time defined by val. It is expected that val is a decimal integer specified in seconds.
	 */
	time: (seconds: number) => void,
	/**
	 * Sets the height of the video to val in pixels
	 * If constrain: true is passed as an option, then the height of the video will also be updated to maintain the correct aspect ratio.
	 */
	videoHeight: (px: number, options: { constrain: boolean }) => void,
	/**
	 * Sets the width of the video to val in pixels
	 * If constrain: true is passed as an option, then the width of the video will also be updated to maintain the correct aspect ratio.
	 */
	videoWidth: (px: number, options: { constrain: boolean }) => void,

	/**
	 * Sets the width of the video container to val in pixels
	 * If constrain: true is passed as an option, then the width of the video will also be updated to maintain the correct aspect ratio.
	 */
	width: (px: number, options?: { constrain: boolean }) => void,

	/**
	 * Plays the video.
	 */
	play: () => void;

	/**
	 * Pauses the video.
	 */
	pause: () => void;

	/**
	 * Removes the video from the page cleanly. This will do garbage collection, cancel asynchronous operations, and stop the video from streaming, none of which are reliable if the video is simply removed from the DOM,
	 */
	remove: () => void;
	/**
	 * Returns the hashed ID associated with this video. The hashed ID is an alphanumeric string that uniquely identifies your video in Wistia.
	 */
	hashedId: () => string;

	/**
	 * Binds a function to a Wistia player event.
	 * @param eventName - The name of the event to bind to.
	 * @param callback - The function to be called when the event occurs.
	 */
	bind: (eventName: string, callback: () => void) => void;
	unbind: (eventName: string, callback: () => void) => void;
}


@Injectable({ providedIn: 'root' })
export class WistiaService {

	private _viewInited = signal<boolean>(false);

	private _isPlaying = signal<boolean>(false); // Signal to track play state
	public isPlaying = this._isPlaying.asReadonly(); // Read-only signal for play state
	private _secondsWatchedVector = signal<number[] | undefined>(undefined); // Signal to track play state
	public secondsWatchedVector = this._secondsWatchedVector.asReadonly(); // Read-only signal for play state


	/**
	 * Called in a page or view ngAfterViewInit so the service
	 * knows that the Wistia API can find and attach itself to
	 * the 'wistia_player' div element.
	 */
	public async onNgAfterViewInit() {
		this._viewInited.set(true);

		// // Ensure the player is ready before setting up event listeners
		// const player = await this.getPlayer();
		// if (player) {
		// 	await this.bindEventListeners(); // Setup event listeners for play/pause
		// } else {
		// 	console.error('Failed to initialize event listeners: player not found.');
		// }
	}


	/**
	 * After a page or view is destroyed
	 */
	public async onNgOnDestroy() {
		this._viewInited.set(false);
		this._isPlaying.set(false);
		this._secondsWatchedVector.set(undefined);

		await this.remove();
	}


	private async bindEventListeners(player: WistiaPlayer) {

		player.bind('play', () => {
			this._isPlaying.set(true); // Update play state to true
		});

		player.bind('pause', () => {
			this._isPlaying.set(false); // Update play state to false
		});

		player.bind('end', () => {
			this._isPlaying.set(false); // Update play state to false
		})

		player.bind('secondchange', () => {
			if (!this._isPlaying()) {
				this._secondsWatchedVector.set(undefined);
				return;
			}
			const secondsWatchedVector = player.secondsWatchedVector();

			if (secondsWatchedVector) this._secondsWatchedVector.set([...secondsWatchedVector]);
			else this._secondsWatchedVector.set(undefined)
		});


	}


	public async unbindEventListeners(player: WistiaPlayer) {

		player.unbind('play', () => {
			this._isPlaying.set(false); // Update play state to true
		});

		player.unbind('pause', () => {
			this._isPlaying.set(false); // Update play state to false
		});

		player.unbind('end', () => {
			this._isPlaying.set(false); // Update play state to false
		});

		player.unbind('secondchange', () => {
			this._secondsWatchedVector.set(undefined)
		});


	}

	/**
	 * Waits until onNgAfterViewInit() has been called because the
	 * page or view DOM has been rendered. Then, the synchronous
	 * Wistia api matcher is used to get a reference to the player.
	 */
	private async getPlayer(): Promise<WistiaPlayer | undefined> {
		let player: WistiaPlayer | undefined = undefined;

		if (this._viewInited()) {
			// Retry until the player is ready
			while (!player || !player.ready()) {
				player = Wistia.api(`wistia_player`);

				if (player && player.ready()) {
					break; // Exit the loop if player is ready
				}

				await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100 ms before retrying
			}

			if (!player) {
				console.error(`Couldn't find wistia_player`);
				return undefined;
			}
		}

		return player;
	}


	/**
	 * Gets a reference to the wistia player for the div element with
	 * an id of 'wistia_player' and then instructs the Wistia API to
	 * load and show the video associated with the provided hashedId
	 */
	public async replaceVideo(hashedId: string, autoPlay = false) {

		const player = await this.getPlayer();
		if (!player) return;


		this.unbindEventListeners(player);


		player.replaceWith(hashedId, {
			autoPlay, fitStrategy: 'contain', transition: "slide",
		});

		this.bindEventListeners(player);


	}


	/**
	 * Seeks the video to the time defined by val. 
	 * It is expected that val is a decimal integer specified in seconds.
	 */
	public async seekVideo(lastSecondWatched: number) {

		const player = await this.getPlayer();
		if (!player) return;

		player.time(lastSecondWatched);

	}

	public async pauseVideo() {

		const player = await this.getPlayer();
		if (!player) return;

		player.pause();

	}

	public async playVideo() {

		const player = await this.getPlayer();
		if (!player) return;

		player.play();

	}

	public async getHashedId() {

		const player = await this.getPlayer();
		if (!player) return;

		return player.hashedId();
	}

	public async remove() {

		const player = await this.getPlayer();
		if (!player) return;

		return player.remove();
	}


	/**
	 * Gets a reference to the wistia player for the div element with
	 * an id of 'wistia_player' and instructs it to resize the player
	 * to the provided width and height.
	 */
	public async setWidthAndHeight(width: number, height: number, aspectRatio = true) {

		const player = await this.getPlayer();
		if (!player) return;

		player.width(width);
		player.height(height);
	}

	public async setWidth(width: number, aspectRatio = true) {

		const player = await this.getPlayer();
		if (!player) return;

		player.videoWidth(width, { constrain: aspectRatio });
	}


}