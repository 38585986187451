import { Component, computed, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { UpdateGoalFormService } from './update-goal-form.service';
import { getLabels } from './acc-team-goal.page.labels';


@Component({
	selector: 'acc-team-goal-page',
	imports: [
		MobilePagePart,
		CommonMessageWidget,
		CommonButtonRowWidget,
		CommonTextFieldWidget,
	],
	templateUrl: './acc-team-goal.page.html'
})

export class AccTeamGoalPage implements OnInit {
	private frame = inject(MobileFrameService);
	public form = inject(UpdateGoalFormService);
	private route = inject(ActivatedRoute);
	public labels = getLabels(this.form.goalsService.urlService);

	async ngOnInit() {
		this.form.accTeamGoalId.set(undefined);
		this.form.resetValues();
		const accTeamGoalId = +this.route.snapshot.params['accTeamGoalId'];
		this.frame.setUrlMetadata({
			url: `/goals/${accTeamGoalId}`,
			backUrl: '/goals',
			pageName: computed(() => this.labels.Goal()),
			headerText: computed(() => this.labels.Goal()),
		});
		await this.form.goalsService.loadData();
		this.form.accTeamGoalId.set(accTeamGoalId);
	}

}
