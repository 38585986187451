import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,


		Applicant: computed(() => en() ? `Applicant` : `Solicitante`),
		CompleteAllFieldsMsg: computed(() => en() ? `TBD. Please complete all the required fields inorder to join the application. TBD` : `{{ ES : TBD. Please complete all the required fields inorder to join the application. TBD }}`),
		Business: computed(() => en() ? `Business` : `Empresa`),
		CodeAlreadyUsed: computed(() => en() ? `The code "{code}" has already been used.` : `{{ ES : The code "{{code}}" has already been used. }}`),
		Instructions: computed(() => en() ? `Instructions` : `Instrucciones`),
		InstructionsMsg: computed(() => en() ? `Please tell us about you. We __promise__ never to share your individual information without your authorization. We compile bulk statistics (no individually identifiable data) to demonstrate the demographic information of the people with whom we support. This information is frequently used in our grant applications and donation solicitations which allows us to offer our programs to aspiring entrepreneurs like you.` : `{{ ES: Please tell us about you. We __promise__ never to share your individual information without your authorization. We compile bulk statistics (no individually identifiable data) to demonstrate the demographic information of the people with whom we support. This information is frequently used in our grant applications and donation solicitations which allows us to offer our programs to aspiring entrepreneurs like you. }}`),
		InvitedToJoin: computed(() => en() ? `You've been invited to join an application for` : `{{ ES : You've been invited to join an application for }}`),
		IWorkFullTime: computed(() => en() ? `I work on this business full-time` : `Trabajo en el negocio tiempo completo`),
		IWorkPartTime: computed(() => en() ? `I work on this business part-time` : `Trabajo en el negocio medio tiempo`),
		Join: computed(() => en() ? `Join` : `{{ ES : Join }}`),
		Involvement: computed(() => en() ? `Involvement` : `¿Qué tanto estoy involucrado?`),
		JoinAnApplication: computed(() => en() ? `Join an Application` : `Únase a una aplicación`),
		JoinApplication: computed(() => en() ? `Join Application` : `{{ ES : Join Application }}`),
		NotMe: computed(() => en() ? `Not me` : `{{ ES : Not me }}`),
		Program: computed(() => en() ? `Program` : `Programa`),
		TellUsAboutYou: computed(() => en() ? `Tell us about You` : `Cuéntenos acerca de usted`),
		YourTitle: computed(() => en() ? `Your Title` : `¿Cúal es su cargo en la empresa? (dueña, presidente, etc)`),
		FirstName: computed(() => en() ? `First Name` : `Primer Nombre`),
		UnableToJoin: computed(() => en() ? `Unable to join. This application is in a closed state.` : `{{ ES : Unable to join. This application is in a closed state. }}`),
	};
}