import { computed } from '@angular/core';
import { UrlService } from '@eforall/common'; // Replace with the correct path to UrlService



export function getLabels(urlService: UrlService) {
	const en = urlService.isEnglish;
	return {
		...urlService.commonLabels,
		Confirm: computed(() => en() ? `Confirm` : `{{ ES: Confirm }}`),
	}
}