import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonEmailField, CommonGeneralFieldConfig, CommonTextField, CommonTextFieldConfig, UrlService } from "@eforall/common";
import { FuncService, MessageFlyoutContentMessageType } from '../../../../services';
import { ApplicationService } from "../application.service";
import { getLabels } from "./your-team.page.labels";

@Injectable({ providedIn: 'root' })
export class ApplicationYourTeamFormService {

	public readonly applicationService = inject(ApplicationService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	public readonly labels = getLabels(this.urlService);

	private values = signal<{ firstName: string, lastName: string, email: string, title: string }>({
		firstName: '',
		lastName: '',
		title: '',
		email: '',
	});


	readonly restOfTeam = computed<CommonTextField>(() => ({

		config: signal<CommonTextFieldConfig>({
			label: this.labels.form.Answer(),
			min: 1,
			max: 5000,
			fixCase: true,
			multiLine: true,
			minHeight: 15,
			isSpanish: this.urlService.isSpanish()
		}),
		actualValue: computed(() => this.applicationService.application()?.application.restOfTeam || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const application = this.applicationService.application();

			if (application) {
				await this.func.application.form.setRestOfTeam({ applicationId: application.application.applicationId, restOfTeam: value });
				this.applicationService.app.setApplicationData({ ...application.application, restOfTeam: value });
			}
		},
		error: signal(''),
	}));



	readonly title = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.Title(), min: 3, max: 50, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().title),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, title: value });
		},
		error: signal(''),
	}));

	readonly firstName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.FirstName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().firstName),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, firstName: value });
		},
		error: signal(''),
	}));

	readonly lastName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.LastName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().lastName),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, lastName: value });
		},
		error: signal(''),
	}));


	readonly email = computed<CommonEmailField>(() => ({
		config: signal<CommonGeneralFieldConfig>({ label: this.labels.form.Email(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().email),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, email: value });
		},
		error: signal(''),
	}));


	public showInviteForm = signal(false);


	async invite() {
		const application = this.applicationService.application();
		if (application && application.canSubmit) {

			const valid = this.checkIfValid();


			if (valid) {
				const values = this.values();
				//Send invite email
				await this.applicationService.app.update(
					this.func.pendingParticipant.add, {
					applicationId: application.application.applicationId,
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					title: values.title,
				});

				const message = this.labels.InviteSuccesfullySentMessage().split('{firstName}').join(values.firstName).split('{lastName}').join(values.lastName).split('{email}').join(values.email);
				this.applicationService.flyoutService.showMarkdownMessage(message, this.labels.Invite(), MessageFlyoutContentMessageType.success);
				this.closeInvite();
			}
			else {
				this.applicationService.flyoutService.showMarkdownMessage(this.labels.FieldsIncompleteMessage(), this.labels.Invite(), MessageFlyoutContentMessageType.alert);
			}
		}
	}

	checkIfValid() {
		const values = this.values();
		return !this.firstName().error() && !this.lastName().error() && !this.title().error() && !this.email().error()
			&& !!values.firstName && !!values.lastName && !!values.title && !!values.email;
	}

	resetForm() {
		this.values.set({
			firstName: '',
			lastName: '',
			title: '',
			email: '',
		});
	}


	closeInvite() {
		this.resetForm();
		this.showInviteForm.set(false);
	}

}