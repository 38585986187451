<mobile-page-part>

	<div class="info-content">
		{{headerText()}}
	</div>

	<div class="d-flex flex-column gap-2">
		<common-select-field [field]="form.gender()"></common-select-field>
		<common-select-field [field]="form.hispanicOrLatino()"></common-select-field>
		<common-multiselect-field [field]="form.races()"></common-multiselect-field>
		<common-select-field [field]="form.veteran()"></common-select-field>
		@if(form.questionnaireService.values().questionnaireRoleId !== staffRoleId)
		{
		<common-select-field [field]="form.yearOfBirth()"></common-select-field>
		}
		@if(form.questionnaireService.entrepreneurRole())
		{
		<common-number-field [field]="form.householdIncome()"></common-number-field>
		<common-select-field [field]="form.householdSize()"></common-select-field>
		<common-select-field [field]="form.college()"></common-select-field>
		}
	</div>
	<common-button-column>
		<button class="btn btn-primary btn-large" (click)="saveAndNavigate()"
			[disabled]="!form.isValidInputs()">Next</button>
	</common-button-column>

</mobile-page-part>