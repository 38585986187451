import { computed, Signal } from '@angular/core';


export function getCommonLabels(isEnglish: Signal<boolean>) {

	const en = isEnglish;

	return {

		/** Common EforAll terms */
		common: {
			Applications: computed(() => en() ? `Applications` : `Aplicaciones`),
			Calendar: computed(() => en() ? `Calendar` : `Calendario`),
			Cohort: computed(() => en() ? `Cohort` : `Grupo`),
			Cohorts: computed(() => en() ? `Cohorts` : `Cohortes`),
			Contact: computed(() => en() ? `Contact` : `Contacto`),
			Contacts: computed(() => en() ? `Contacts` : `Contactos`),
			Demographics: computed(() => en() ? `Demographics` : `Demographics`),
			EforAll: computed(() => en() ? `EforAll` : `EparaTodos`),
			EforAllWebsite: computed(() => en() ? `eforall.org` : `eparatodos.org`),
			Feedback: computed(() => en() ? `Feedback` : `Comentario`),
			Goals: computed(() => en() ? `Goals` : `Metas`),
			Home: computed(() => en() ? `Home` : `Inicio`),
			Learning: computed(() => en() ? `Learning` : `Aprendizaje`),
			Materials: computed(() => en() ? `Materials` : `Materiales`),
			MyTeam: computed(() => en() ? `My Team` : `Mi equipo`),
			MyCourses: computed(() => en() ? `My Courses` : `{{ ES : My Courses }}`),
			NotAuthorized: computed(() => en() ? `Not Authorized` : `No autorizado`),
			Profile: computed(() => en() ? `Profile` : `Perfil`),
			Programs: computed(() => en() ? `Programs` : `Programas`),
			ProgramManager: computed(() => en() ? `Program Manager` : `Gerente de Programa`),
			SignedAgreements: computed(() => en() ? `Signed Agreements` : `Documentos Firmados`),
			Specialist: computed(() => en() ? `Specialist` : `Especialista`),
			Surveys: computed(() => en() ? `Surveys` : `Encuestas`),
			Topic: computed(() => en() ? `Topic` : `Tema`),
			Topics: computed(() => en() ? `Topics` : `Temas`),
			Venture: computed(() => en() ? `Venture` : `Empresa`),
			Ventures: computed(() => en() ? `Ventures` : `Empresas`),
			Volunteer: computed(() => en() ? `Volunteer` : `Voluntario`),
		},


		employmentStatusOptions: {
			Employed: computed(() => en() ? `Employed` : `Empleado`),
			Retired: computed(() => en() ? `Retired` : `Jubilado`),
			Underemployed: computed(() => en() ? `Underemployed` : `Subempleados`),
			Unemployed: computed(() => en() ? `Unemployed` : `Desempleado`),
		},

		/** Standard labels used in forms */
		form: {
			Address: computed(() => en() ? `Address` : `Dirección`),
			Answer: computed(() => en() ? `Answer` : `Respuesta`),
			Birthday: computed(() => en() ? `Birthday` : `Cumpleaños`),
			Details: computed(() => en() ? `Details` : `Detalles`),
			Email: computed(() => en() ? `Email` : `Correo Electrónico`),
			EmploymentStatus: computed(() => en() ? `Employment Status` : `Estado de Empleo`),
			FirstName: computed(() => en() ? `First Name` : `Primer Nombre`),
			Gender: computed(() => en() ? `Gender` : `Género`),
			HeadOfHousehold: computed(() => en() ? `Head of Household (according to IRS)` : `Jefe de hogar (de acuerdo con el IRS)`),
			HispanicOrLatino: computed(() => en() ? `Hispanic Or Latino` : `Hispano o latino`),
			HomeAddress: computed(() => en() ? `Home Address` : `Direccion de Domicilio`),
			HouseholdIncomeLastYear: computed(() => en() ? `Household Income (last year)` : `Ingresos del hogar (año pasado)`),
			Involvement: computed(() => en() ? `Involvement` : `Intervención`),
			LastName: computed(() => en() ? `Last Name` : `Apellido`),
			MiddleInitial: computed(() => en() ? `Middle Initial` : `Inicial del segundo nombre`),
			NativeEnglishSpeaker: computed(() => en() ? `Native English Speaker` : `Hablante nativo de inglés`),
			NativeSpanishSpeaker: computed(() => en() ? `Native Spanish Speaker` : `Hablante nativo de español`),
			OtherGender: computed(() => en() ? `Other Gender` : `Otro género`),
			Phone: computed(() => en() ? `Phone` : `Teléfono`),
			PhoneNumber: computed(() => en() ? `Phone Number` : `Número de teléfono`),
			Progress: computed(() => en() ? `Progress` : `Progreso`),
			Question: computed(() => en() ? `Question` : `Pregunta`),
			Race: computed(() => en() ? `Race` : `Raza`),
			SelectEllipses: computed(() => en() ? `Select...` : `Seleccionar...`),
			Summary: computed(() => en() ? `Summary` : `Resumen`),
			Title: computed(() => en() ? `Title` : `Título`),
			Veteran: computed(() => en() ? `Veteran` : `Veterano`),
			YourTitle: computed(() => en() ? `Your Title` : `Tu título`),
		},

		genderOption: {
			Female: computed(() => en() ? `Female` : `Mujer`),
			Male: computed(() => en() ? `Male` : `Masculino`),
			NonBinary: computed(() => en() ? `Non-Binary` : `No binario`),
			Other: computed(() => en() ? `Other` : `Otro`),
		},


		headOfHouseholdOptions: {
			IAmHead: computed(() => en() ? `I am Head of Household` : `Soy jefe de hogar`),
			IAmNotHead: computed(() => en() ? `I am NOT Head of Household` : `NO soy jefe de hogar`),
		},

		hispanicOptions: {
			IAmHispanic: computed(() => en() ? `I am Hispanic or Latino` : `Soy hispano o latino`),
			IAmNotHispanic: computed(() => en() ? `I am NOT Hispanic or Latino` : `NO soy hispano o latino`),
		},


		nativeEnglishOptions: {
			NativeSpeaker: computed(() => en() ? `I am a Native English Speaker` : `Soy un hablante nativo de inglés`),
			NotNativeSpeaker: computed(() => en() ? `I am NOT a Native English Speaker` : `NO soy un hablante nativo de inglés`),
		},


		nativeSpanishOptions: {
			NativeSpeaker: computed(() => en() ? `I am a Native Spanish Speaker` : `Soy un hablante nativo de español`),
			NotNativeSpeaker: computed(() => en() ? `I am NOT a Native Spanish Speaker` : `NO soy un hablante nativo de español`),
		},


		raceOptions: {
			AfricanAmerican: computed(() => en() ? 'Black or African American' : 'Negro o afroamericano'),
			AmericanIndian: computed(() => en() ? 'American Indian or Alaska Native' : 'Indio americano o nativo de Alaska'),
			Asian: computed(() => en() ? 'Asian' : 'Asiático'),
			PacificIslander: computed(() => en() ? 'Native Hawaiian or Other Pacific Islander' : 'Nativo hawaiano u otro isleño del Pacífico'),
			White: computed(() => en() ? 'White' : 'Blanco'),
		},


		veteranOptions: {
			IAmVeteran: computed(() => en() ? `I am a U.S. Veteran` : `Soy EEUU Veterano`),
			IAmNotVeteran: computed(() => en() ? `I am NOT a U.S. Veteran` : `No soy EEUU Veterano`),
		},



		/** Standard labels used on buttons */
		button: {
			Apply: computed(() => en() ? 'Apply' : 'Aplica'),
			ApplyInLanguage: computed(() => en() ? 'Solicite en español' : 'Apply in English'),
			Cancel: computed(() => en() ? 'Cancel' : `Cancelar`),
			Continue: computed(() => en() ? 'Continue' : `Seguir`),
			Close: computed(() => en() ? 'Close' : `Cerrar`),
			LearnMore: computed(() => en() ? 'Learn More' : 'Conoce más'),
			Next: computed(() => en() ? `Next` : `Próximo`),
			No: computed(() => en() ? `No` : `No`),
			Okay: computed(() => en() ? `Okay` : `Bueno`),
			Open: computed(() => en() ? `Open` : `Abrir`),
			Previous: computed(() => en() ? `Previous` : `Anterior`),
			Remove: computed(() => en() ? `Remove` : `Eliminar`),
			Save: computed(() => en() ? `Save` : `Guardar`),
			Submit: computed(() => en() ? `Submit` : `Enviar`),
			View: computed(() => en() ? `View` : `Ver`),
			Yes: computed(() => en() ? `Yes` : `Sí`),
			Edit: computed(() => en() ? `Edit` : `Editar`),
		},


		status: {
			Open: computed(() => en() ? 'Open' : `Abierto`),
			Complete: computed(() => en() ? 'Complete' : `Completa`),
			Missed: computed(() => en() ? 'Missed' : `Sin Completar`),
			NotOpenYet: computed(() => en() ? 'Not Open Yet' : `Aun no Abierta`),
			Unavailable: computed(() => en() ? 'Unavailable' : `No disponible`),
			Closed: computed(() => en() ? 'Closed' : `Cerrado`),
		},

		topicLongName: <{ [index: string]: Signal<string> }>{
			'Accelerator Kick Off': computed(() => en() ? 'Accelerator Kick Off' : 'Bienvenida'),
			'Business Models and Customer Segments': computed(() => en() ? 'Business Models and Customer Segments' : 'Modelos de negocio y Segmentos de clientes'),
			'Connecting Customers to Value': computed(() => en() ? 'Connecting Customers to Value' : 'Conectando las necesidades del cliente con mi producto'),
			'Prioritizing Customers Testing Assumptions': computed(() => en() ? 'Prioritizing Customers and Testing Assumptions' : 'Priorización de clientes y validacion de supuestos'),
			'Testing Assumptions Through Surveys': computed(() => en() ? 'Testing Assumptions Through Surveys' : 'Prueba de supuestos a través de encuestas '),
			'Setting Goals and Working through Surveys': computed(() => en() ? 'Setting Goals and Working through Surveys' : 'Establecimiento de metas y elaboración de encuestas'),
			'Testing Profitability Quick Calculations': computed(() => en() ? 'Testing Profitability through Quick Calculations' : 'Cálculos rápidos para determinar ganancias'),
			'Checking In and Planning Ahead': computed(() => en() ? 'Checking In and Planning Ahead' : 'Revision del programa hasta ahora y planificación de los proximos meses'),
			'Exploring Pricing Strategies': computed(() => en() ? 'Exploring Pricing Strategies' : 'Exploración de estrategias de precios'),
			'Entrepreneur Tell All/Open Class': computed(() => en() ? 'Entrepreneur Tell All/Open Class' : 'Clase abierta- Sugerencias: Emprendedor Invitado o Herramientas en Línea/Google Suite, etc.'),
			'Progress Pitch': computed(() => en() ? 'Progress Pitch' : 'Presentación del progreso'),
			'Branding and Messaging': computed(() => en() ? 'Branding and Messaging' : 'Creación de la marca (Branding) y el mensaje'),
			'Forecasting Revenues and Costs': computed(() => en() ? 'Forecasting Revenues and Costs' : 'Prevision de costos e ingresos'),
			'Legal Issues of Business': computed(() => en() ? 'Legal Issues of Business' : 'Aspectos legales'),
			'Accounting': computed(() => en() ? 'Accounting' : 'Contabilidad'),
			'Insurance': computed(() => en() ? 'Insurance' : 'Seguros'),
			'Reaching Customers through Websites': computed(() => en() ? 'Reaching Customers through Websites' : 'Llegar a los clientes a través de paginas web'),
			'Marketing and Social Media': computed(() => en() ? 'Marketing and Social Media' : 'Comercialización (Marketing) y redes sociales'),
			'Asking for Money': computed(() => en() ? 'Asking for Money' : 'Acceso a capital'),
			'Presentation Skills': computed(() => en() ? 'Presentation Skills' : 'Aptitudes de presentación (hablar en público)'),
			'Marketing through Media Outlets': computed(() => en() ? 'Marketing through Media Outlets' : 'Marketing a través de los medios'),
			'Purpose and Impact of Your Business': computed(() => en() ? 'Exploring the Purpose and Impact of Your Business' : 'Explorando el propósito y el impacto de su negocio'),
			'Selling to your Customer': computed(() => en() ? 'Selling to your Customer' : 'Vender a su cliente'),
			'Practice Presentations': computed(() => en() ? 'Practice Presentations' : 'Práctica para la Presentación Final'),
			'Final Presentation Practice': computed(() => en() ? 'Final Presentation Practice' : 'Práctica para la Presentación Final'),
		},

		eventTypeFullName: <{ [index: string]: Signal<string> }>{
			'Accelerator Session': computed(() => en() ? 'Accelerator Session' : 'Sesión del acelerador'),
		},

	};
}
