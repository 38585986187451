import { Component, computed, inject } from '@angular/core';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './my-courses.page.labels';
import { RouterLink } from '@angular/router';


@Component({
	selector: 'my-courses-page',
	imports: [
		MobilePagePart, CommonButtonRowWidget, RouterLink
	],
	templateUrl: './my-courses.page.html'
})

export class MyCoursesPage {
	private frame = inject(MobileFrameService);
	public labels = getLabels(this.frame.urlService);
	public readonly myCourse = computed(() => {
		if (this.frame.urlService.isSpanish()) {
			return {
				header: this.labels.AceleradordeNegociosdeEparaTodosTitle(),
				blob: this.labels.AceleradordeNegociosdeEparaTodosBlob(),
				url: this.frame.urlService.withCurrentLanguage("my-courses/2"),
			}
		} else {
			return {
				header: this.labels.BusinessAcceleratorCourseTitle(),
				blob: this.labels.BusinessAcceleratorCourseBlob(),
				url: this.frame.urlService.withCurrentLanguage("my-courses/1"),
			}
		}
	});

	constructor() {
		this.frame.setUrlMetadata({
			url: '/my-courses',
			backUrl: '/all-courses',
			pageName: computed(() => this.labels.common.MyCourses()),
			headerText: computed(() => this.labels.common.MyCourses()),
		});
	}

}