import { Component, CUSTOM_ELEMENTS_SCHEMA, effect, input, signal } from '@angular/core';

@Component({
	selector: 'common-wistia-transcript',
	standalone: true,
	template: `
		<div class="wistia_transcript_container">
			<wistia-transcript id="wistia_transcript" player-dom-id="wistia_player" media-id="{{video().wistiaId}}"></wistia-transcript>
		</div>
	`,
	styles: `
		.wistia_transcript_container{
			max-height: 30em;
    		overflow-y: auto;
		}
	    
	`,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonWistiaTranscriptWidget {

	public readonly video = input.required<{ wistiaId: string, furthestWatchedSecond?: number }>();
	private currentWistiaId = signal<string | undefined>(undefined);

	constructor() {
		effect(async () => {
			const video = this.video();

			if (video && video.wistiaId !== this.currentWistiaId()) {
				await this.loadTranscript(video);
			}

		});
	}

	async loadTranscript(video: { wistiaId: string, furthestWatchedSecond?: number }) {

		try {

			this.currentWistiaId.set(video.wistiaId);

			const wistia_transcript = document.getElementById('wistia_transcript');

			if (wistia_transcript) {
				wistia_transcript.setAttribute('media-id', video.wistiaId);
			}

		} catch (error) {
			console.error("Error loading video:", error); // Handle errors!
		}
	}
}