import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonProgressBarWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { CourseActivity, CourseLevel, CourseVersion } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { AllCoursesService } from '../all-courses.service';
import { IndividualCourseService } from '../individual-course.service';
import { getLabels } from './course-dashboard.page.labels';

interface Card {
	header: string,
	infoMarkdown: string,
	percentComplete?: number,
	activityCompleteCountMsg?: string,
	dueDate?: string,
	buttonText: string,
	navigateUrl: string,
	overviewUrl?: string,
}


@Component({
	selector: 'course-dashboard-page',
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		CommonProgressBarWidget,
		NgbAccordionModule,
		FormsModule,
		MobileListWidget,
		MarkdownModule
	],
	providers: [provideMarkdown()],
	templateUrl: './course-dashboard.page.html'
})

export class CourseDashboardPage implements OnInit {
	public individualCourseService = inject(IndividualCourseService);
	public allCoursesService = inject(AllCoursesService);

	private router = inject(Router);
	public util = inject(UtilityService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	public courseCardAndList = computed(() => this.generateCourseCardsAndDueList());
	private frame = inject(MobileFrameService);
	private route = inject(ActivatedRoute);


	constructor() {
	}

	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];

		this.frame.setUrlMetadata({
			url: `/my-courses/${courseVersionId}`,
			backUrl: '/my-courses',
			pageName: computed(() => this.labels.CourseDashboard()),
			headerText: computed(() => this.labels.CourseDashboard()),
		});
		await this.individualCourseService.loadCourse(courseVersionId);
	}

	private generateCourseCardsAndDueList(): { cards: Card[], dueList: MobileListWithSubItem[] } {
		const course = this.individualCourseService.course();
		const cards: Card[] = [];
		const dueList: MobileListWithSubItem[] = [];

		if (!course) return { cards, dueList };

		const { dueLevels, pendingActivity, anyActivityStarted } = this.findFirstPendingAndDueLevels(course);

		if (!anyActivityStarted) {
			cards.push({
				buttonText: this.labels.StartCourse(),
				header: `${course.title}: ${this.labels.GetStarted()}!`,
				infoMarkdown: this.labels.WelcomeMessageFromProgram(),
				navigateUrl: `./overview`,
			});
		} else if (pendingActivity) {
			cards.push(this.createPendingActivityCard(course, pendingActivity));
		}
		else {
			cards.push({
				buttonText: this.labels.CourseOverview(),
				header: `${course.title}: ${this.labels.Complete()}!`,
				infoMarkdown: this.labels.ThankYouMessageFromProgram().split('{course-title}').join(course.title),
				navigateUrl: `./overview`,
			});
		}

		dueList.push(...this.createDueList(dueLevels, course));


		return { cards, dueList };
	}

	private createPendingActivityCard(course: CourseVersion, pendingActivity: {
		level: CourseLevel | undefined,
		activity: CourseActivity | undefined,
		parents: string[],
		levelIndex: number,
		activityIndex: number,
	}): Card {
		const { percentComplete, activityCompleteCountMsg } = this.calculateCompletion(pendingActivity.level);

		return {
			buttonText: this.labels.Resume(),
			header: `${course.title}: ${this.labels.PickWhereLeftOff()}!`,
			infoMarkdown: pendingActivity.parents.join('\n\n'),
			percentComplete,
			activityCompleteCountMsg,
			dueDate: pendingActivity.level?.deadline
				? this.util.date.formatUTC(pendingActivity.level.deadline, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode())
				: undefined,
			navigateUrl: `activity/${pendingActivity.activity?.courseActivityStructureId}`,
			overviewUrl: `overview`,
		};
	}

	private findFirstPendingAndDueLevels(courseVersion: CourseVersion): {
		pendingActivity: {
			level: CourseLevel | undefined,
			activity: CourseActivity | undefined,
			parents: string[],
			levelIndex: number,
			activityIndex: number,
		} | undefined,
		dueLevels: {
			level: CourseLevel,
			parents: string[],
			levelIndex: number,
			dueStatus: 'DueSoon' | 'PastDue',
		}[],
		anyActivityStarted: boolean
	} {
		const now = Date.now() / 1000;
		const oneWeekLater = now + 7 * 24 * 60 * 60; // 1 week in milliseconds

		const checkDueStatus = (deadline?: number): 'DueSoon' | 'PastDue' | undefined => {
			if (!deadline) return undefined;
			if (deadline < now) return 'PastDue';
			if (deadline <= oneWeekLater) return 'DueSoon';
			return undefined;
		};

		let pendingActivity: {
			level: CourseLevel | undefined,
			activity: CourseActivity | undefined,
			parents: string[],
			levelIndex: number,
			activityIndex: number,
		} | undefined = undefined;

		const dueLevels: {
			level: CourseLevel,
			parents: string[],
			levelIndex: number,
			dueStatus: 'DueSoon' | 'PastDue'
		}[] = [];

		let anyActivityStarted = false;

		// Check pending activities directly in the course (not within levels)
		for (let i = 0; i < (courseVersion.activities || []).length; i++) {
			const activity = courseVersion.activities[i]!;
			if (activity.response?.startUTC) {
				anyActivityStarted = true;
			}
			if (!activity.response?.completedUTC && !activity.activityOptional) {
				if (!pendingActivity) {
					pendingActivity = {
						level: undefined,
						activity,
						parents: [`${this.labels.Course()}: ${courseVersion.title}`],
						levelIndex: -1,
						activityIndex: i,
					};
				}
			}
		}

		// Helper function to recursively find due levels and pending activities in levels
		const findInLevel = (level: CourseLevel, currentParents: string[], levelIndex: number): void => {
			const dueStatus = checkDueStatus(level.deadline);

			for (let i = 0; i < level.activities.length; i++) {
				const activity = level.activities[i]!;
				if (activity.response?.startUTC) {
					anyActivityStarted = true;
				}
				if (!activity.response?.completedUTC && !activity.activityOptional) {
					if (!pendingActivity) {
						// Build the `parents` path with activity details if level type is not 'section'
						const parentsPath = [...currentParents];
						if (level.levelType !== 'section') {
							parentsPath.push(`${this.labels.Activity()} ${i + 1}: ${activity.activityTitle}`);
						}
						pendingActivity = {
							level,
							activity,
							parents: parentsPath,
							levelIndex,
							activityIndex: i,
						};
					}
				}
			}

			// Check if this level has overdue or due soon status
			if (dueStatus && !level.activities.every(act => act.response?.completedUTC || act.activityOptional)) {
				dueLevels.push({
					level,
					parents: [...currentParents],
					levelIndex,
					dueStatus,
				});
			}

			// Recursively check child levels
			for (let i = 0; i < level.children.length; i++) {
				const childLevel = level.children[i]!;
				const levelType = this.labels[childLevel.levelType as 'Module' | 'Section' | 'Topic']();
				findInLevel(childLevel, [...currentParents, `${levelType} ${i + 1}: ${childLevel.levelTitle}`], i + 1);
			}
		};

		// Loop through each top-level course version and its levels
		for (let i = 0; i < (courseVersion.levels || []).length; i++) {
			const level = courseVersion.levels[i]!;
			const levelType = this.labels[level.levelType as 'Module' | 'Section' | 'Topic']();
			findInLevel(level, [`${levelType} ${i + 1}: ${level.levelTitle}`], i + 1);
		}

		return {
			pendingActivity,
			dueLevels,
			anyActivityStarted
		};
	}

	private calculateCompletion(level?: CourseLevel): { percentComplete: number | undefined, activityCompleteCountMsg: string | undefined } {
		if (!level) return { activityCompleteCountMsg: undefined, percentComplete: undefined };

		const requiredActivities = level.activities.filter(activity => !activity.activityOptional);
		const completedActivities = requiredActivities.filter(activity => activity.response?.completedUTC).length;

		const percentComplete = requiredActivities.length > 0 ? Math.round((completedActivities / requiredActivities.length) * 100) : 0;
		return {
			percentComplete,
			activityCompleteCountMsg: requiredActivities.length > 0 ? `${completedActivities} ${this.labels.Of()} ${requiredActivities.length} ${this.labels.RequiredActivitiesCompleted()} (${percentComplete}%)` : undefined,
		}
	}

	private createDueList(dueLevels: {
		level: CourseLevel,
		parents: string[],
		levelIndex: number,
		dueStatus: "DueSoon" | "PastDue",
	}[], course: CourseVersion): MobileListWithSubItem[] {
		return dueLevels.map(level => ({
			icon: level.dueStatus === 'PastDue' ? 'fa-circle color-darkred' : 'fa-circle color-lightgray',
			iconStyle: 'fa-solid',
			text: level.parents.map(p => p.split(':')[0]).join(' > '),
			subText: level.level.deadline
				? this.labels.Due() + ': ' + this.util.date.formatUTC(level.level.deadline, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode())
				: '',
			callback: () => {
				this.router.navigate([this.individualCourseService.urlService.withCurrentLanguage(`my-courses/${course.courseVersionId}/level/${level.level.courseLevelStructureId}`)]);
			}
		}));
	}
}