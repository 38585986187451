<mobile-page-part>

	<div class="info-content">
		<h3>{{calendarService.date()}}</h3>
	</div>

	<mobile-list [items]="calendarService.onGoingList()" [header]="labels.OngoingEvents()" [noItemsMessage]="labels.NoOngoingEvents()"></mobile-list>

	<mobile-list [items]="calendarService.upcomingList()" [header]="labels.UpcomingEvents()" [noItemsMessage]="labels.NoUpcomingEvents()"></mobile-list>

	<mobile-list [items]="calendarService.pastList()" [header]="labels.PastEvents()" [noItemsMessage]="labels.NoPastEvents()"></mobile-list>

</mobile-page-part>
