import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Comment: computed(() => en() ? `Comment` : `Comentario`),
		Comments: computed(() => en() ? `Comments` : `Comentarios`),
		NoComments: computed(() => en() ? `No Comments` : `No hay comentarios`),
		NoProgressAdded: computed(() => en() ? `No Progress Added` : `Progreso no agregado`),
		AddANewGoal: computed(() => en() ? `Add a new Goal` : `Agregar nueva meta`),
		GoalsEntered: computed(() => en() ? `Goals Entered` : `Metas Ingresadas`),
		GoalDetails: computed(() => en() ? `Goal Details` : `Detalles de la meta`),
		CreateGoal: computed(() => en() ? `Create Goal` : `Crear Meta`),

	};
}