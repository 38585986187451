import { Component, computed, inject } from '@angular/core';
import { CommonButtonColumnWidget, UrlService } from '@eforall/common';
import { LanguageId, User } from '@interfaces';
import { DomainDataService } from '../../../../services';
import { ApplicationService } from '../application.service';
import { getLabels } from './application-readonly.page.labels';


@Component({
	selector: 'application-readonly-part',
	imports: [
		CommonButtonColumnWidget,
	],
	templateUrl: './application-readonly.part.html',
	styleUrl: './application-readonly.part.scss'
})

export class ApplicationReadonlyPart {
	public applicationService = inject(ApplicationService);
	private domainDataService = inject(DomainDataService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public programDetails = computed(() => {
		const application = this.applicationService.application();

		return {

			program: application?.name || '',
			deadline: application?.deadline || '',
			programLanguage: application?.application.languageId === LanguageId.Spanish ? 'Espanol' : 'English',
			contact: application?.application.siteEmail,
			hasFeedback: application?.hasFeedback,
		}
	});

	private readonly gendersOptionsMap: { [index: string]: string } = {
		'': '', 'F': this.labels.genderOption.Female(), 'M': this.labels.genderOption.Male(), 'N': this.labels.genderOption.NonBinary(), 'O': this.labels.genderOption.Other(),
	};
	private readonly racesOptionsMap: { [index: string]: string } = { 'N': this.labels.raceOptions.AmericanIndian(), 'A': this.labels.raceOptions.Asian(), 'B': this.labels.raceOptions.AfricanAmerican(), 'P': this.labels.raceOptions.PacificIslander(), 'W': this.labels.raceOptions.White(), };
	private readonly employmentStatusOptionsMap: { [index: number]: string } = { 0: '', 1: this.labels.employmentStatusOptions.Unemployed(), 2: this.labels.employmentStatusOptions.Underemployed(), 3: this.labels.employmentStatusOptions.Employed(), 4: this.labels.employmentStatusOptions.Retired(), };

	public readonly aboutYouQuestions = computed<{ question: string, answer: string }[]>(() => {
		const data: { question: string, answer: string }[] = [];
		const application = this.applicationService.application();
		const user = this.applicationService.app.data().user;
		const participant = application!.participants.find(participant => participant.personId == user.personId);

		if (application && participant) {
			const birthdayUTC = user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
			data.push(
				{ question: this.labels.form.Involvement(), answer: !participant.doingItFullTime ? '' : participant.doingItFullTime === 'Y' ? this.labels.IWorkBusinessFullTime() : this.labels.IWorkBusinessPartTime() },
				{ question: this.labels.form.YourTitle(), answer: participant.title || '' },
				{ question: this.labels.form.FirstName(), answer: user.firstName || '' },
				{ question: this.labels.form.MiddleInitial(), answer: user.middleInit || '' },
				{ question: this.labels.form.LastName(), answer: user.lastName || '' },
				{ question: this.labels.form.Birthday(), answer: birthdayUTC ? this.applicationService.util.date.formatUTC(birthdayUTC, 'MMM D, YYYY', 'No Time', this.urlService.languageCode()) : '' },
				...this.getGenderQuestions(user),
				this.getRaces(user),
				{ question: this.labels.form.HispanicOrLatino(), answer: !user.hispanic ? '' : user.hispanic === 'Y' ? this.labels.hispanicOptions.IAmHispanic() : this.labels.hispanicOptions.IAmNotHispanic() },
				{ question: this.labels.form.Veteran(), answer: !user.veteran ? '' : user.veteran === 'Y' ? this.labels.veteranOptions.IAmVeteran() : this.labels.veteranOptions.IAmNotVeteran() },
				{ question: this.labels.form.NativeEnglishSpeaker(), answer: !user.nativeEnglish ? '' : user.nativeEnglish === 'Y' ? this.labels.nativeEnglishOptions.NativeSpeaker() : this.labels.nativeEnglishOptions.NotNativeSpeaker() },
				{ question: this.labels.form.NativeSpanishSpeaker(), answer: !user.nativeSpanish ? '' : user.nativeSpanish === 'Y' ? this.labels.nativeSpanishOptions.NativeSpeaker() : this.labels.nativeSpanishOptions.NotNativeSpeaker() },
				{ question: this.labels.form.EmploymentStatus(), answer: this.employmentStatusOptionsMap[participant.employmentStatusId ?? 0] || '' },
				{ question: this.labels.form.HeadOfHousehold(), answer: !participant.headOfHousehold ? '' : participant.headOfHousehold === 'Y' ? this.labels.headOfHouseholdOptions.IAmHead() : this.labels.headOfHouseholdOptions.IAmNotHead() },
				{ question: this.labels.form.HouseholdIncomeLastYear(), answer: (participant.householdIncome ?? '').toString() },
				{ question: this.labels.form.Phone(), answer: user.phone ? this.applicationService.util.phone.format(user.phone) : '' },
				{ question: this.labels.form.Address(), answer: user.zipId ? this.applicationService.util.zip.format(user.zipId) : '' },
			);
		}

		return data;
	});


	private getGenderQuestions(user: User) {
		const genderQuestions: { question: string, answer: string }[] = [{ question: this.labels.form.Gender(), answer: this.gendersOptionsMap[user.gender ?? ''] || '' }];
		if (user.gender && user.gender === 'O') genderQuestions.push({ question: this.labels.form.OtherGender(), answer: user.genderOther || '' });

		return genderQuestions;
	}

	private getRaces(user: User): { question: string, answer: string } {
		const answer = user.races?.map(race => this.racesOptionsMap[race]).join(', ');
		return { question: this.labels.form.Race(), answer: answer || '' };

	}


	public readonly businessQuestions = computed<{ question: string, answer: string }[]>(() => {
		const data: { question: string, answer: string }[] = [];
		const application = this.applicationService.application();
		const user = this.applicationService.app.data().user;
		if (application) {
			data.push(
				{ question: this.labels.BusinessName(), answer: application.application.companyName },
				{ question: this.labels.BusinessType(), answer: application.application.companyTypeId ? this.domainDataService.data().companyTypeMap[application.application.companyTypeId]?.label || '' : '' },
				{ question: this.labels.Industry(), answer: application.business.industryId ? this.domainDataService.data().industryMap[application.business.industryId]?.label || '' : '' },
				{ question: this.labels.Offering(), answer: application.business.offerings },
				{ question: this.labels.AnnualRevenue(), answer: (application.application.annualRevenue ?? '').toString() },
				{ question: this.labels.NumFullTimeEmployees(), answer: (application.application.numFullTimeEmployees ?? '').toString() },
				{ question: this.labels.NumPartTimeEmployees(), answer: (application.application.numPartTimeEmployees ?? '').toString() },
				{ question: this.labels.NumContractors(), answer: (application.application.numContractors ?? '').toString() },
				{ question: this.labels.BusinessPhone(), answer: application.business.phone ? this.applicationService.util.phone.format(application.business.phone) : '' },
				{ question: this.labels.BusinessAddress(), answer: application.business.zipId ? this.applicationService.util.zip.format(application.business.zipId) : '' },
			);
		}

		return data;
	});

	public readonly answersQuestions = computed<{ question: string, answer: string }[]>(() => {
		const answers = this.applicationService.answers();
		return answers.reduce((a: { question: string, answer: string }[], answer) => {
			a.push({ question: answer.question.text, answer: answer.answer || '' });
			return a;
		}, []);

	});


	navigateToFeedback() {
		const application = this.applicationService.application();

		if (application) {
			const key = application.application.accId ? `acc-${application.application.accId}` : `pic-${application.application.picId}`;
			this.applicationService.router.navigate([this.urlService.withCurrentLanguage('feedback')], { queryParams: { key } });
		}
	}


}