<mobile-page-part>

	<div class="info-content">
		<h3> {{ LinkOrLinks()}}</h3>
	</div>
	@if(links().length)
	{
	<div class="social-links-section-container">
		<div class="white-box d-flex flex-column w-100">
			@for(link of links(); track link.webLinkId;){
			<div class="d-flex individual-weblink justify-content-between mt-1 mb-1">
				<div class="d-flex justify-content-start align-items-center">
					<i class='fa-fw fs-5 fa-regular me-2' [ngClass]="[link.iconStyle,link.icon]"></i>
					<div class="web-link text-break m-1">{{link.url}}</div>
				</div>
				<div class="d-flex button-column justify-content-end align-items-center">
					<a class="btn btn-standard me-2" [href]="link.url" target="_blank">
						<i class='fa-regular fa-external-link '></i>
					</a>
					<button class="btn btn-standard" (click)="askForConfirmDelete(link.webLinkId)">
						<i class='fa-regular fa-xmark'></i>
					</button>
				</div>
			</div>
			}
		</div>
	</div>
	}


	@if(!form.showAddForm()){
	<common-button-row [center]="true">
		<button class="btn btn-large" [ngClass]="{'btn-primary':links().length==0,'btn-standard':links().length>0}" (click)="form.openAddForm()">{{labels.AddALink()}}</button>
	</common-button-row>
	}
	@else {
	<div class="white-box d-flex flex-column gap-3">
		<h4 class="text-center">{{labels.SocialLinks()}}</h4>
		<common-select-field [field]="form.linkType()"></common-select-field>
		<common-url-field [field]="form.url()"></common-url-field>
		<common-button-row [center]="true">
			<button class="btn btn-primary" (click)="form.saveNewWebLink()" [disabled]="form.isInvalidForm()">{{labels.button.Save()}}</button>
			<button class="btn btn-standard" (click)="form.closeAddForm()">{{labels.button.Cancel()}}</button>
		</common-button-row>
	</div>
	}
</mobile-page-part>