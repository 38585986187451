import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ApplicationQuestions: computed(() => en() ? `Application Questions` : `{{ ES: Application Questions }}`),
		BusinessAddress: computed(() => en() ? `Business Address` : `Dirección de Negocios`),
		BusinessName: computed(() => en() ? `Business Name` : `Nombre del Negocio`),
		BusinessType: computed(() => en() ? `Business Type` : `Tipo de negocio`),
		Deadline: computed(() => en() ? `Deadline` : `{{ ES: Deadline }}`),
		Language: computed(() => en() ? `Language` : `Idioma`),
		Contact: computed(() => en() ? `Contact` : `Contacto`),
		Complete: computed(() => en() ? `Complete` : `Finzalizado`),
		Industry: computed(() => en() ? `Industry` : `Industria`),
		Offering: computed(() => en() ? `Offering` : `¿Qué ofrece?`),
		AnnualRevenue: computed(() => en() ? `Annual Revenue (last year)` : `Ventas anuales (año pasado)`),
		NumFullTimeEmployees: computed(() => en() ? `Number of Full-Time Employees (include owners)` : `Número de empleados a tiempo completo (incluir propietarios)}`),
		NumPartTimeEmployees: computed(() => en() ? `Number of Part-Time Employees (include owners)` : `Número de empleados a tiempo parcial (incluir propietarios)`),
		NumContractors: computed(() => en() ? `Number of Contractors (IRS form 1099)` : `Cantidad de contratistas a los que les paga través del formulario 1099 del IRS`),
		BusinessPhone: computed(() => en() ? `Business Phone` : `Teléfono de negocios`),
		Incomplete: computed(() => en() ? `Incomplete` : `Incompleto`),
		IWorkBusinessFullTime: computed(() => en() ? `I work on this business full-time` : `Trabajo en el negocio tiempo completo`),
		IWorkBusinessPartTime: computed(() => en() ? `I work on this business part-time` : `Trabajo en el negocio medio tiempo`),
		Participants: computed(() => en() ? `Participants` : `Participantes`),
		FeedbackAvailable: computed(() => en() ? `Feedback Available` : `{{ ES: Feedback Available }}`),
		MakeChanges: computed(() => en() ? `Make Changes` : `{{ ES: Make Changes }}`),
		TellUsAboutYourBusiness: computed(() => en() ? `Tell us about Your Business` : `Háblenos de su negocio`),
		TellUsAboutYou: computed(() => en() ? `Tell us about You` : `Cuéntenos acerca de usted`),
		TellUsAboutYourTeam: computed(() => en() ? `Tell us about Your Team` : `Cuéntenos sobre su equipo`),
		TeamNameAndBio: computed(() => en() ? `List name and a short bio of each member of your core team, whether they will participate or not.` : `Indique el nombre y una breve biografía de cada participante.  También puede incluir a otros miembros del equipo que formen parte de su organización, pero que no asistan a las clases/reuniones. (Los miembros del equipo que no asistan a las clases/reuniones no necesitan completar la información de los coparticipantes).`),
	}
}