import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonIconTextWidget, CommonMessageWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService, MobileListWidget, MobileListWithSubItem } from '@eforall/mobile';
import { AccSessionEvent, CalendarEvent, EventTypeId } from '@interfaces';
import { NgbAccordionModule, NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments';
import { FuncService } from '../../../../services';
import { getIconForTopicMaterialType } from '../../../get-icon-for-topic-material-type';
import { getLabels } from './event-flyout.part.labels';


const FORTYEIGHT_HOURS = 48 * 60 * 60;


interface Material {
	id: number,
	name: string,
	icon: string,
	url: string,
}

interface CalendarTopic {
	index: number,
	topicName: string,
	topicGoals: string,
	/**
	 * Returns a true if there are materials added to the topic
	 */
	hasMaterials: boolean,
	materialAvailableDate?: string,
	/**
	 * List is populated only if it is 48 hours before the session start
	 */
	topicMaterials: Material[],
}


@Component({
	selector: 'calendar-event-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		NgbAccordionModule,
		RouterModule,
		MobileListWidget,
		CommonMessageWidget,
	],
	templateUrl: './event-flyout.part.html',
	styleUrl: './event-flyout.part.scss'
})
export class CalendarEventFlyoutPart implements OnInit {

	private activeOffcanvas = inject(NgbActiveOffcanvas);
	private router = inject(Router);
	public frame = inject(MobileFrameService);
	public func = inject(FuncService);
	public util = inject(UtilityService);
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public readonly event = input.required<CalendarEvent>();
	public venueLink: string | undefined = undefined;
	public accSessionTypeId = EventTypeId.AccSession;
	public accSessionEvent = signal<AccSessionEvent | undefined>(undefined);


	public sessionWithTopic = computed(() => {
		const accSession = this.accSessionEvent();

		if (!accSession) return undefined;

		const event = this.event();
		const isSpanish = this.urlService.isSpanish();
		const surveyLabelAndIcon = this.getSurveyLabelAndIcon();

		const surveyItem: MobileListWithSubItem[] = [{
			icon: surveyLabelAndIcon.surveyIcon,
			iconStyle: 'fa-solid',
			text: this.labels.ClassSurvey() + ": " + this.getLocalizedSurveyStatus(surveyLabelAndIcon),
			subText: surveyLabelAndIcon.surveyMessage,
			callback: surveyLabelAndIcon.surveyStatus === 'Open' ? () => {
				this.close();
				this.router.navigate([this.urlService.withCurrentLanguage(`surveys/acc-session/${accSession.accSessionId}`)]);
			} : undefined,
		}];

		const topics: CalendarTopic[] = (accSession?.topics ?? []).map((topic, idx) => {
			const topicMaterials: Material[] = [];
			const hasMaterials = !!topic.topicMaterials.length;
			let materialAvailableDate: string = '';

			const now = Date.now() / 1000;
			if (now >= (event.startUTC - FORTYEIGHT_HOURS)) {
				topicMaterials.push(
					...topic.topicMaterials
						.sort((a, b) => a.displayOrder - b.displayOrder)
						.map((topicMaterial, i) => ({
							id: i + 1,
							icon: getIconForTopicMaterialType(topicMaterial.topicMaterialTypeId),
							name: topicMaterial.name,
							url: topicMaterial.url,
						})),
				);
			} else {
				materialAvailableDate = this.util.date.formatUTC(event.startUTC - FORTYEIGHT_HOURS, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode());
			}

			return {
				index: idx + 1,
				topicName: topic.longNameLabel,
				materialAvailableDate,
				topicGoals: isSpanish ? topic.goalsES : topic.goalsEN,
				hasMaterials,
				topicMaterials,
			};
		});


		return {
			accSessionId: accSession.accSessionId,
			topics,
			surveyItem,
		};

	});


	async ngOnInit() {
		const event = this.event();
		this.venueLink = event.venue && event.venue.placeId ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.venue.officialName)}&query_place_id=${event.venue.placeId}` : undefined;
		if (event.eventTypeId == this.accSessionTypeId) {
			const accSessionEvent = await this.func.calendar.getAccSessionData({ eventId: event.eventId });
			this.accSessionEvent.set(accSessionEvent);
		}
	}

	close() {
		this.activeOffcanvas.close();
	}

	getDate(utc: number) {
		const isSpanish = this.urlService.isSpanish();
		return this.util.date.formatUTC(utc, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.urlService.languageCode());
	}

	private getSurveyLabelAndIcon(): { surveyMessage: string, surveyIcon: string, surveyStatus: 'Complete' | 'Open' | 'Missed' | 'Unavailable' | 'Not Open Yet' } {
		const accSession = this.accSessionEvent();
		const event = this.event();
		let surveyLabelAndIcon: { surveyMessage: string, surveyIcon: string, surveyStatus: 'Complete' | 'Open' | 'Missed' | 'Unavailable' | 'Not Open Yet' } = {
			surveyMessage: '',
			surveyIcon: '',
			surveyStatus: 'Unavailable'
		};

		if (!accSession) return surveyLabelAndIcon;

		const now = Date.now() / 1000;

		if (accSession.accSessionSurveyResponseId) {
			surveyLabelAndIcon = {
				surveyIcon: 'fa-circle color-green',
				surveyMessage: this.labels.SurveyCompleteMsg() ?? '',
				surveyStatus: 'Complete',
			};
		} else {
			if (event.toolEndUTC < now) {
				const deadline = this.util.date.formatUTC(event.toolEndUTC, 'MM/DD/YYYY', 'H:MM AM EST', this.urlService.languageCode());
				const nowDate = this.util.date.formatUTC(now, 'MM/DD/YYYY', 'H:MM AM EST', this.urlService.languageCode());

				let message = this.labels.MdSurveyMissedMsg() ?? '';
				message = message?.split('{{deadline}}').join(deadline);
				message = message?.split('{{now}}').join(nowDate);

				surveyLabelAndIcon = {
					surveyIcon: 'fa-circle color-darkred',
					surveyMessage: message,
					surveyStatus: 'Missed',
				};
			} else if (now < event.toolStartUTC || !accSession.sessionAttendanceTaken || !accSession.attendance) {
				let message = this.labels.MdSurveyNotAvailableMsg() ?? '';
				message = message.split('{{pm-email}}').join(accSession.pmEmail);
				surveyLabelAndIcon = {
					surveyIcon: 'fa-circle color-lightgray',
					surveyMessage: message,
					surveyStatus: 'Not Open Yet',
				};
			} else if (accSession.attendance == 'Absent') {
				let message = this.labels.MdSurveyMarkedAbsentMsg() ?? '';
				message = message.split('{{pm-email}}').join(accSession.pmEmail);
				surveyLabelAndIcon = {
					surveyIcon: 'fa-circle color-darkred',
					surveyMessage: message,
					surveyStatus: 'Unavailable',
				};
			} else if (event.toolStartUTC <= now && now <= event.toolEndUTC && accSession.attendance == 'Present') {

				const url = `${this.urlService.getBaseUrl(environment, accSession.languageId)}/surveys/acc-session/${accSession.accSessionId}`;
				const availablefor = this.util.date.calculateTimePeriod(now, event.toolEndUTC);
				let message = this.labels.MdSurveyAvailableMsg() ?? '';
				message = message.split('{{url}}').join(url);
				message = message.split('{{available-for}}').join(availablefor);

				surveyLabelAndIcon = {
					surveyIcon: 'fa-circle color-eforall-blue',
					surveyMessage: message,
					surveyStatus: 'Open',
				};
			}
		}

		return surveyLabelAndIcon;
	}


	onClick(url: string | undefined) {
		if (url) window.open(url, "_blank");
	}

	private getLocalizedSurveyStatus(surveyLabelAndIcon: any): string {
		switch (surveyLabelAndIcon.surveyStatus) {
			case 'Complete':
				return this.labels.status.Complete();
			case 'Open':
				return this.labels.status.Open();
			case 'Missed':
				return this.labels.status.Missed();
			case 'Unavailable':
				return this.labels.status.Unavailable();
			case 'Not Open Yet':
				return this.labels.status.NotOpenYet();
			default:
				return this.labels.status.Unavailable();
		}
	}

}
