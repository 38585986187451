import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './welcome.page.labels';


@Component({
	selector: 'welcome-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink
	],
	templateUrl: './welcome.page.html',
	styleUrl: './welcome.page.scss'
})

export class WelcomePage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	constructor() {
		this.frame.setUrlMetadata({
			url: `/welcome`,
			backUrl: this.frame.NO_BACK_URL,
			pageName: computed(() => this.labels.Welcome()),
			headerText: computed(() => ''),
			restrictNavigation: true,
		});
	}
}