import { TitleCasePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonButtonColumnWidget, CommonIconTextWidget, UrlService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getLabels } from '../flyout.labels';


@Component({
	selector: 'public-user-confirmation-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
		MarkdownModule,
		TitleCasePipe,
		CommonButtonColumnWidget
	],
	providers: [provideMarkdown()],
	templateUrl: './user-confirmation-flyout.part.html',
	styleUrl: './user-confirmation-flyout.part.scss'
})
export class PublicUserConfirmationlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public readonly header = input<string>();
	public readonly message = input.required<string>();
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	confirm() {
		this.activeOffcanvas.close(true);
	}

	close() {
		this.activeOffcanvas.close(false);
	}
}