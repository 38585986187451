import { NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { Component, HostBinding, computed, inject, input, } from '@angular/core';
import { UrlService } from '../../../services/url/url.service';
import { getLabels } from './common-icon-text.widget.labels';

@Component({
	selector: 'common-icon-text',
	standalone: true,
	templateUrl: './common-icon-text.widget.html',
	styleUrls: ['./common-icon-text.widget.scss'],
	imports: [NgClass, TitleCasePipe, NgIf]
})
export class CommonIconTextWidget {

	public readonly iconStyle = input<'fa-regular' | 'fa-solid'>('fa-regular');
	public readonly icon = input('fa-caret-right');
	public readonly spacing = input<'small' | 'large' | 'x-large' | 'xx-large'>('small');
	public readonly iconSize = input<'small' | 'large' | 'x-large' | 'xx-large'>('small');
	public readonly text = input.required<string>();
	public readonly messageType = input<'information' | 'alert' | 'error' | 'success' | undefined>(undefined);
	public readonly iconClass = computed(() => `fa-fw ${this.iconStyle()} ${this.icon()} ${this.messageType() + '-icon'}`);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	
	@HostBinding('class') get class() {
		return ('icon-' + this.iconSize()) + ' ' + ('spacing-' + this.spacing());
	}

}