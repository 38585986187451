import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getIconForTopicMaterialType } from '../get-icon-for-topic-material-type';
import { getLabels } from './materials.page.labels';
import { MaterialService } from './materials.service';

interface Material {
	id: number,
	name: string,
	icon: string,
	url: string,
}

interface SessionTopic {
	index: number,
	topicName: string,
	topicGoals: string,
	hasMaterials: boolean,
	topicMaterials: Material[],
}


@Component({
	selector: 'materials-page',
	imports: [
		MobilePagePart,
		NgbAccordionModule,
		FormsModule,
		MarkdownModule
	],
	providers: [provideMarkdown()],
	templateUrl: './materials.page.html'
})
export class MaterialsPage implements OnInit {
	public materialService = inject(MaterialService);
	private frame = inject(MobileFrameService);
	public util = inject(UtilityService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	public topics = computed(() => this.createSessionTopics());

	constructor() {
		this.frame.setUrlMetadata({
			url: `/materials`,
			backUrl: `/`,
			pageName: computed(() => this.labels.Materials()),
			headerText: computed(() => this.labels.Materials())
		});
	}

	async ngOnInit() {
		await this.materialService.loadTopics();
	}

	private createSessionTopics(): SessionTopic[] {
		const topics = this.materialService.topics();
		if (!topics) return [];

		return topics.map((topic, idx) => ({
			index: idx + 1,
			topicName: this.labels.topicLongName[topic.longNameLabel]!(),
			topicGoals: this.urlService.isSpanish() ? topic.goalsES : topic.goalsEN,
			hasMaterials: topic.topicMaterials.length > 0,
			topicMaterials: topic.topicMaterials
				.sort((a, b) => a.displayOrder - b.displayOrder)
				.map((material, i) => ({
					id: i + 1,
					icon: getIconForTopicMaterialType(material.topicMaterialTypeId),
					name: material.name,
					url: material.url
				}))
		}));
	}


}