import { Component, computed, inject, input } from '@angular/core';
import { CommonButtonColumnWidget, CommonIconTextWidget, UrlService, UtilityService, WistiaService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { CourseActivityVideo } from '@interfaces';
import { NgbAccordionModule, NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { getLabels } from './resume-video-flyout.part.labels';


@Component({
	selector: 'resume-video-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		NgbAccordionModule,
		CommonButtonColumnWidget
	],
	templateUrl: './resume-video-flyout.part.html',
	styleUrl: './resume-video-flyout.part.scss'
})
export class ResumeVideoFlyoutPart {

	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public util = inject(UtilityService);
	public readonly header = input<string>();
	public readonly activity = input.required<CourseActivityVideo>();
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	private readonly wistiaService = inject(WistiaService);

	public getResumeBtnLabel = computed(() => this.labels.ResumeButton().replace("{minutes}", (() => this.util.date.secondsToMinutes(this.activity().furthestWatchedSecond!))()));

	close() {
		this.activeOffcanvas.close();
		this.wistiaService.playVideo();
	}

	playFromBegin() {
		this.wistiaService.seekVideo(0);
		this.activeOffcanvas.close();
		this.wistiaService.playVideo();
	}

}
