import { computed, inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { UrlService, UtilityService } from "@eforall/common";
import { MobileListWithSubItem } from '@eforall/mobile';
import { AccTeamGoalsAndAccess, Goal } from "@interfaces";
import { FlyoutService, FuncService } from "../../services";
import { getLabels } from "./goals.page.labels";

@Injectable({ providedIn: 'root' })
export class GoalsService {

	private func = inject(FuncService);
	private router = inject(Router);
	public util = inject(UtilityService);
	public urlService = inject(UrlService);
	public flyoutService = inject(FlyoutService);
	private labels = getLabels(this.urlService);

	private readonly loaded = signal<boolean>(false);
	public goalsAndAccess = signal<AccTeamGoalsAndAccess | undefined>(undefined);

	public goalsList = computed<MobileListWithSubItem[]>(() => {
		const goalsAndAccess = this.goalsAndAccess();
		return (goalsAndAccess?.goals || [])
			.reduce((a: MobileListWithSubItem[], goal) => {

				const item = this.getItem(goal);
				a.push(item);
				return a;
			}, []);

	});

	public access = computed(() => {
		const access = this.goalsAndAccess();

		return {
			canAddGoal: access?.canAddGoal ?? false,
			canEditGoal: access?.canEditGoal ?? false,
		}
	});


	async loadData() {
		if (!this.loaded()) {
			this.reset();
			const goalsAndAccess = await this.func.goals.get();
			this.goalsAndAccess.set(goalsAndAccess);
			this.loaded.set(true);
		}
	}


	private getItem(goal: Goal): MobileListWithSubItem {

		const callback = () => { this.router.navigate([this.urlService.withCurrentLanguage(`goals/${goal.accTeamGoalId}`)]); }
		const comment = goal.comments.length > 0 ? this.util.text.singularOrPluralWithCount(goal.comments.length, this.labels.Comment(), this.labels.Comments()) : this.labels.NoComments();


		const item: MobileListWithSubItem = {
			icon: `fa-circle color-lightgray`,
			iconStyle: 'fa-solid',
			text: goal.summary,
			subText: ``,
			callback,
		};

		if (goal.progress) {
			item.subText = comment;
		}
		else {
			item.icon = `fa-circle color-lightgray`;
			item.subText = `${this.labels.NoProgressAdded()} - ${comment}`;
		}

		return item;
	}


	reset() {
		this.goalsAndAccess.set(undefined);
	}


	async addGoal(summary: string, details: string) {

		const goalsAndAccess = this.goalsAndAccess();
		if (goalsAndAccess && goalsAndAccess.accTeamId) {
			const accTeamGoal = await this.func.goals.addGoal({ accTeamId: goalsAndAccess.accTeamId, details, summary });

			if (accTeamGoal) {
				this.setGoalsData(accTeamGoal);
			}
		}

	}


	public async updateGoal(goal: Goal) {
		if (goal) {
			await this.func.goals.setGoal({ accTeamGoalId: goal.accTeamGoalId, details: goal.details, summary: goal.summary, progress: goal.progress });
			this.setGoalsData(goal);
		}
	}

	public getDate(utc: number) {
		return this.util.date.formatUTC(utc, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode());
	}


	public setGoalsData(goal: Goal) {
		const goalsAndAccess = this.goalsAndAccess();

		if (goalsAndAccess) {
			const goals = [...goalsAndAccess.goals];

			const applIndex = goals.findIndex(g => g.accTeamGoalId == goal.accTeamGoalId);
			if (applIndex !== -1 && goals[applIndex]) goals[applIndex]! = { ...goal };
			else goals.push(goal);

			this.goalsAndAccess.set({ ...goalsAndAccess, goals: [...goals] });
		}

	}
}