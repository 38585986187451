import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonSelectFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { QuestionnaireBusinessFormService } from './business-form.service';


@Component({
	selector: 'questionnaire-business',
	imports: [
		MobilePagePart,
		FormsModule,
		CommonSelectFieldWidget,
		RouterLink,
		CommonButtonColumnWidget,
	],
	templateUrl: './business.page.html'
})

export class QuestionnaireBusinessPage implements OnInit {
	private frame = inject(MobileFrameService);
	private router = inject(Router);
	public readonly form = inject(QuestionnaireBusinessFormService);

	constructor() {
		this.frame.setUrlMetadata({
			url: `/questionnaire/page-2`,
			backUrl: `/questionnaire/page-1`,
			pageName: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			headerText: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			restrictNavigation: true,
		});
	}

	ngOnInit(): void {
		if (!this.form.questionnaireService.values().questionnaireRoleId) {
			this.router.navigate(['questionnaire/page-1']);
			return;
		}

		this.form.resetValues();
	}



}
