import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Goal: computed(() => en() ? `Goal` : `Meta`),
		Comment: computed(() => en() ? `Comment` : `Comentario`),
		Comments: computed(() => en() ? `Comments` : `Comentarios`),
		GoalCreated: computed(() => en() ? `Goal created` : `Meta Creada`),
		CreatedBy: computed(() => en() ? `Created by` : `Creado por`),
		LastUpdated: computed(() => en() ? `Last updated` : `Última actualización`),
		UpdatedBy: computed(() => en() ? `Updated by` : `Actualizado por`),
		Progress: computed(() => en() ? `Progress` : `Progreso`),
	};
}