import { Component, ElementRef, OnInit, computed, inject, input, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleApiService, UtilityService } from '../../../../services';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { AddressData, CommonAddressField, ModifiedAddressComponent } from '../field';
import { getLabels } from '../field.labels';


@Component({
	selector: 'common-address-field',
	imports: [FormsModule],
	templateUrl: './common-address-field.widget.html'
})
export class CommonAddressFieldWidget extends CommonFieldWidgetBase<AddressData | undefined, CommonAddressField> implements OnInit {

	private util = inject(UtilityService);
	private googleApi = inject(GoogleApiService);

	public readonly field = input.required<CommonAddressField>();
	public readonly addressField = viewChild<ElementRef<HTMLInputElement>>('addressField');

	// public foundPlace = signal<AddressData | undefined>(undefined);

	public readonly labels = computed(() => getLabels(this.field().config().isSpanish));


	public readonly renderedModel = computed(() => {
		const value = this.field().pendingValue() ?? this.field().actualValue();
		return this.render(value);
	});

	public errorToDisplay = computed(() => {
		const error = this.field().error();
		if (error == '') return '';
		if (this.hasFocus() || !this.hadFocus()) return '';
		return error;
	});



	async ngOnInit() {
		this.field().error.set(this.validate());

		const places = await this.googleApi.getPlaces();
		const autocomplete = new places.Autocomplete(this.addressField()!.nativeElement, { types: ["geocode"] });
		autocomplete.setFields(["address_components", "formatted_address", "geometry", "name", "place_id"]);

		autocomplete.addListener("place_changed", () => {
			this.field().pendingValue.set(undefined);

			const place = autocomplete.getPlace();
			//
			// User entered the name of a Place that was not suggested and
			// pressed the Enter key, or the Place Details request failed.
			//
			if (place.geometry) {

				const address = (place.formatted_address ?? '').trim();

				const { street, zipId, inUS, stateCode, county } = this.getAddressComponents(place.address_components);

				this.field().pendingValue.set({
					streetAddress: street,
					placeAddress: address,
					zipId,
					stateCode,
					county,
					inUS: inUS ?? false,
					latitude: place.geometry.location ? Math.round(place.geometry.location.lat() * 100) : undefined,
					longitude: place.geometry.location ? Math.round(place.geometry.location.lng() * 100) : undefined,
					placeId: place.place_id,
				});
			}

			this.onBlur();

		});

	}


	private getAddressComponents(parts: google.maps.GeocoderAddressComponent[] | undefined) {
		const data: ModifiedAddressComponent = {
			zipId: undefined,
			inUS: undefined,
			street: undefined,
			stateCode: undefined,
			county: undefined
		};


		if (!parts) return data;

		const countryPart = parts.find(part => (part.types ?? []).includes("country"));
		if (countryPart) data.inUS = countryPart.short_name == "US";

		if (data.inUS) {
			const zipPart = parts.find(part => (part.types ?? []).includes('postal_code'));
			if (zipPart) data.zipId = parseInt(zipPart.short_name, 10);

			const countyPart = parts.find(part => (part.types ?? []).includes('administrative_area_level_2'));
			if (countyPart) data.county = countyPart.short_name;

			const statePart = parts.find(part => (part.types ?? []).includes('administrative_area_level_1'));
			if (statePart) data.stateCode = statePart.short_name;

			const streetNumPart = parts.find(part => (part.types ?? []).includes("street_number"));
			const streetNamePart = parts.find(part => (part.types ?? []).includes("route"));
			const aptPart = parts.find(part => (part.types ?? []).includes("subpremise"));
			const street: string[] = [];
			if (streetNumPart && streetNumPart.long_name) street.push(streetNumPart.long_name);
			if (streetNamePart && streetNamePart.long_name) street.push(streetNamePart.long_name);
			if (aptPart && aptPart.long_name) street.push(aptPart.long_name);

			data.street = street.join(' ') || undefined;
		}
		return data;
	}


	public async onBlur() {

		if (!this.addressField()?.nativeElement.value) {
			this.field().pendingValue.set(undefined);
		}

		await this.blur(this.field());

	}


	protected override render(value: AddressData | undefined) {
		return value?.placeAddress;
	}


	protected validate(): string {

		const placeFound = this.getValue(this.field());
		const config = this.field().config();
		const labels = this.labels();
		const elementValue = this.addressField()?.nativeElement.value ?? placeFound?.placeAddress;

		if (config.required && !elementValue) return labels.missingRequired();
		if (elementValue && !placeFound?.zipId) return labels.missingZip();
		// if (placeFound && !this.util.values.areSame(placeFound.placeAddress, elementValue)) return labels.notValid;

		return '';
	}

	protected override valuesAreSame(value1: AddressData | undefined, value2: AddressData | undefined): boolean {
		return this.util.values.areSame(value1, value2);
	}
}