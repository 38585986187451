import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		Comment: computed(() => en() ? `Comment` : `Comentario`),
		Comments: computed(() => en() ? `Comments` : `Comentarios`),

	};
}