import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {
		...urlService.commonLabels,
		CourseNotAvailableYet: computed(() => en() ? `Course Not Available Yet` : `{{ ES : Course Not Available Yet }}`),
		NewVersionAvailable: computed(() => en() ? `New version available.Start now!` : `{{ ES : New version available.Start now! }}`),
		Start: computed(() => en() ? `Start` : `{{ ES : Start }}`),
		Resume: computed(() => en() ? `Resume` : `{{ ES : Resume }}`),
		Review: computed(() => en() ? `Review` : `{{ ES : Review }}`),
		Complete: computed(() => en() ? `Complete` : `{{ ES : Complete }}`),
		CourseComplete: computed(() => en() ? `Course Complete` : `{{ ES : Course Complete }}`),
		Sections: computed(() => en() ? `Sections` : `{{ ES : Sections }}`),
		NoCourses: computed(() => en() ? `No courses available` : `{{ ES : No courses available }}`),
		NextSectionDue: computed(() => en() ? `Next Section due:` : `{{ ES : Next Section due: }}`),
		NextSectionPastDue: computed(() => en() ? `Next Section past due:` : `{{ ES : Next Section past due: }}`),
	};
}