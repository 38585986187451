import { Injectable } from '@angular/core';
import * as I from '@interfaces';
import { 
	AuthService,
	EnvironmentService,
	ErrorService,
	FuncServiceBase,
	SpinnerService,
	UrlService,
	UtilityService,
} from '@eforall/common';

//
//  This class is generated by generate-client-shims.mjs which is called
//  during the build. DON'T CHANGE IT MANUALLY.
//

@Injectable({ providedIn: 'root' })
export class FuncService extends FuncServiceBase {

	constructor(
		env: EnvironmentService,
		auth: AuthService,
		errorService: ErrorService,
		spinner: SpinnerService,
		url: UrlService,
		util: UtilityService,
) {
		super(env, auth, errorService, spinner, url, util);
	}

	public agreements = {
		addAgreementSignature: this._call<{ agreementVersionId: number }, I.Agreement[]>('agreements.addAgreementSignature'),
		get: this._callNoPayload<I.Agreement[]>('agreements.get'),
	};

	public application = {
		form: {
			setAnnualRevenue: this._call<{ applicationId: number, annualRevenue: number }, void>('application.form.setAnnualRevenue', { noPageSpinner: true, label: 'UPDATING' }),
			setAnswer: this._call<{ applicationId: number, questionId: number, value: string }, void>('application.form.setAnswer', { noPageSpinner: true, label: 'UPDATING' }),
			setBusinessAddress: this._call<{ applicationId: number, address: I.Address }, void>('application.form.setBusinessAddress', { noPageSpinner: true, label: 'UPDATING' }),
			setCompanyIndustryId: this._call<{ applicationId: number, industryId: number }, void>('application.form.setCompanyIndustryId', { noPageSpinner: true, label: 'UPDATING' }),
			setCompanyName: this._call<{ applicationId: number, companyName: string }, void>('application.form.setCompanyName', { noPageSpinner: true, label: 'UPDATING' }),
			setCompanyOfferings: this._call<{ applicationId: number, offerings: string }, void>('application.form.setCompanyOfferings', { noPageSpinner: true, label: 'UPDATING' }),
			setCompanyPhone: this._call<{ applicationId: number, phone: string }, void>('application.form.setCompanyPhone', { noPageSpinner: true, label: 'UPDATING' }),
			setCompanyTypeId: this._call<{ applicationId: number, companyTypeId: number }, void>('application.form.setCompanyTypeId', { noPageSpinner: true, label: 'UPDATING' }),
			setContractors: this._call<{ applicationId: number, numContractors: number }, void>('application.form.setContractors', { noPageSpinner: true, label: 'UPDATING' }),
			setFullTimeEmployees: this._call<{ applicationId: number, numFullTimeEmployees: number }, void>('application.form.setFullTimeEmployees', { noPageSpinner: true, label: 'UPDATING' }),
			setPartTimeEmployees: this._call<{ applicationId: number, numPartTimeEmployees: number }, void>('application.form.setPartTimeEmployees', { noPageSpinner: true, label: 'UPDATING' }),
			setRestOfTeam: this._call<{ applicationId: number, restOfTeam: string }, void>('application.form.setRestOfTeam', { noPageSpinner: true, label: 'UPDATING' }),
		},
		addApplication: this._call<{ accId: number, picId: number, companyId: number, title: string }, number>('application.addApplication'),
		getAnswers: this._call<{ applicationId: number }, I.Answer[]>('application.getAnswers'),
		submit: this._call<{ applicationId: number }, I.FuncData>('application.submit'),
		withdraw: this._call<{ applicationId: number, applicationStatusId: I.ApplicationStatusId }, I.FuncData>('application.withdraw'),
	};

	public applications = {
		determineIfUserCanApply: this._call<{ accId: number, picId: number }, { companies: { companyId: number, title: string, priorApplicant?: string, }[] }>('applications.determineIfUserCanApply'),
		getEligibleApplications: this._call<{ type: 'Acc' | 'Pic', homeZipId: number, businessZipId: number | undefined, key?: string }, { messageLabels: string[], eligiblePrograms: I.EligibleProgram[] }>('applications.getEligibleApplications'),
		getFeedback: this._callNoPayload<I.Feedback[]>('applications.getFeedback'),
	};

	public calendar = {
		getAccSessionData: this._call<{ eventId: number }, I.AccSessionEvent | undefined>('calendar.getAccSessionData'),
		get: this._callNoPayload<I.CalendarEvent[]>('calendar.get'),
	};

	public cohort = {
		getTeam: this._callNoPayload<I.AccTeam | undefined>('cohort.getTeam'),
		get: this._callNoPayload<I.AccTeamCohort | undefined>('cohort.get'),
	};

	public company = {
		form: {
			setCompanyTypeId: this._call<{ companyId: number, companyTypeId: number }, void>('company.form.setCompanyTypeId', { noPageSpinner: true, label: 'UPDATING' }),
			setIndustryId: this._call<{ companyId: number, industryId: number }, void>('company.form.setIndustryId', { noPageSpinner: true, label: 'UPDATING' }),
			setLongName: this._call<{ companyId: number, companyName: string }, void>('company.form.setLongName', { noPageSpinner: true, label: 'UPDATING' }),
			setOfferings: this._call<{ companyId: number, offerings: string }, void>('company.form.setOfferings', { noPageSpinner: true, label: 'UPDATING' }),
			setShortName: this._call<{ companyId: number, shortName: string }, void>('company.form.setShortName', { noPageSpinner: true, label: 'UPDATING' }),
			setStatus: this._call<{ companyId: number, status: string }, void>('company.form.setStatus', { noPageSpinner: true, label: 'UPDATING' }),
		},
		addCompanyAndPosition: this._call<{ companyName: string, companyTypeId: number, industryId: number, title: string }, number>('company.addCompanyAndPosition', { noPageSpinner: true, label: 'UPDATING' }),
		setCompanyAndPosition: this._call<{ companyId: number, longName: string, companyTypeId: number, industryId: number, title: string }, void>('company.setCompanyAndPosition', { noPageSpinner: true, label: 'UPDATING' }),
	};

	public courses = {
		getAllCourses: this._callNoPayload<I.CourseDetailsQueryResult[]>('courses.getAllCourses'),
	};

	public email = {
		remove: this._call<{ emailId: number }, I.FuncData>('email.remove', { noPageSpinner: false, label: 'UPDATING' }),
		setPrimary: this._call<{ emailId: number }, I.FuncData>('email.setPrimary', { noPageSpinner: false, label: 'UPDATING' }),
	};

	public goals = {
		addGoal: this._call<{ accTeamId: number, summary: string, details: string }, I.Goal | undefined>('goals.addGoal', { noPageSpinner: true, label: 'UPDATING' }),
		get: this._callNoPayload<I.AccTeamGoalsAndAccess | undefined>('goals.get'),
		setGoal: this._call<{ accTeamGoalId: number, summary: string, details: string, progress: string }, void>('goals.setGoal', { noPageSpinner: true, label: 'UPDATING' }),
	};

	public learning = {
		getFeedbackActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number }, I.CourseActivityFeedback>('learning.getFeedbackActivityResponse'),
		getPromptActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number }, I.CourseActivityPrompt>('learning.getPromptActivityResponse'),
		getQuizActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number }, I.CourseActivityQuiz>('learning.getQuizActivityResponse'),
		getVideoActivityResponse: this._call<{ sessionId: number, courseActivityId: number, courseActivityStructureId: number }, I.CourseActivityVideo>('learning.getVideoActivityResponse'),
		getWorkbookActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number }, I.CourseActivityWorkbook>('learning.getWorkbookActivityResponse'),
		get: this._call<{ courseVersionId: number }, I.CourseVersion | undefined>('learning.get'),
		setCourseCompletionPercentage: this._call<{ courseVersionId: number, completionPercentage: number }, { courseEnrollmentId: number, completedUTC: number, completionPercentage: number }>('learning.setCourseCompletionPercentage', { noPageSpinner: true, label: 'UPDATING' }),
		setFeedbackActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number, feedbackRating: number, comments?: string }, { courseActivityResponseId: number, completedUTC: number, feedbackRating: number, comments?: string, }>('learning.setFeedbackActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
		setPromptActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number, promptAnswer: string }, { courseActivityResponseId: number, completedUTC: number, promptAnswer: string, }>('learning.setPromptActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
		setQuizActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number, selectedChoices: { quizQuestionsId: number, selectedChoiceId: number, }[] }, { courseQuizActivityId: number, courseActivityResponseId: number, completedUTC: number, courseActivityQuizResponseId: number, attemptSubmittedUTC: number, countOfAttempts: number, percentCorrect: number, } | undefined>('learning.setQuizActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
		setRatingActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number, rating: -1 | 1 | undefined }, { courseActivityResponseId: number, rating: 1 | -1 | undefined }>('learning.setRatingActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
		setVideoActivityResponse: this._call<{ sessionId: number, courseActivityId: number, courseActivityStructureId: number, latestSecondsWatchedVector: number[] }, { courseActivityResponseId: number, percentWatched: number | undefined, completedUTC: number | undefined }>('learning.setVideoActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
		setWorkbookActivityResponse: this._call<{ courseActivityId: number, courseActivityStructureId: number }, { courseActivityResponseId: number, completedUTC: number }>('learning.setWorkbookActivityResponse', { noPageSpinner: true, label: 'UPDATING' }),
	};

	public materials = {
		get: this._callNoPayload<I.Topic[]>('materials.get'),
	};

	public participant = {
		form: {
			setDoingItFulltime: this._call<{ applicationId: number, applicationParticipantId: number, doingItFullTime: 'N' | 'Y' }, void>('participant.form.setDoingItFulltime', { noPageSpinner: true, label: 'UPDATING' }),
			setEmploymentStatusId: this._call<{ applicationId: number, applicationParticipantId: number, employmentStatusId: number }, void>('participant.form.setEmploymentStatusId', { noPageSpinner: true, label: 'UPDATING' }),
			setHeadOfHousehold: this._call<{ applicationId: number, applicationParticipantId: number, headOfHousehold: 'N' | 'Y' }, void>('participant.form.setHeadOfHousehold', { noPageSpinner: true, label: 'UPDATING' }),
			setHomeAddress: this._call<{ applicationId: number, applicationParticipantId: number, address: I.Address }, void>('participant.form.setHomeAddress', { noPageSpinner: true, label: 'UPDATING' }),
			setHouseholdIncome: this._call<{ applicationId: number, applicationParticipantId: number, householdIncome: number }, void>('participant.form.setHouseholdIncome', { noPageSpinner: true, label: 'UPDATING' }),
			setTitle: this._call<{ applicationId: number, applicationParticipantId: number, title: string }, void>('participant.form.setTitle', { noPageSpinner: true, label: 'UPDATING' }),
		},
		addByCode: this._call<{ code: string, participantDetails: { title: string, address: I.Address, employmentStatusId: number, headOfHousehold: string, householdIncome: number, doingItFullTime: string, } }, number | undefined>('participant.addByCode'),
		remove: this._call<{ applicationId: number, applicationParticipantId: number }, I.FuncData>('participant.remove'),
	};

	public pendingParticipant = {
		add: this._call<{ applicationId: number, title: string, firstName: string, lastName: string, email: string }, I.FuncData>('pendingParticipant.add'),
		remove: this._call<{ applicationId: number, applicationParticipantPendingId: number }, I.FuncData>('pendingParticipant.remove'),
		resendInvite: this._call<{ applicationId: number, firstName: string, code: string, email: string }, I.FuncData>('pendingParticipant.resendInvite'),
		validateInviteCode: this._call<{ code: string }, { applicationId: number, programName: string, applicantName: string, companyName: string, applicationOpen: boolean } | undefined>('pendingParticipant.validateInviteCode'),
	};

	public profile = {
		form: {
			setAddress: this._call<{ address: I.Address }, { countyFipsId: number | undefined }>('profile.form.setAddress', { noPageSpinner: true, label: 'UPDATING' }),
			setBirthdayAndBirthyear: this._call<{ birthdayUTC: number | undefined }, void>('profile.form.setBirthdayAndBirthyear', { noPageSpinner: true, label: 'UPDATING' }),
			setFirstName: this._call<{ firstName: string }, void>('profile.form.setFirstName', { noPageSpinner: true, label: 'UPDATING' }),
			setGenderOther: this._call<{ genderOther: string }, void>('profile.form.setGenderOther', { noPageSpinner: true, label: 'UPDATING' }),
			setGender: this._call<{ gender: string }, void>('profile.form.setGender', { noPageSpinner: true, label: 'UPDATING' }),
			setHispanic: this._call<{ hispanic: string }, void>('profile.form.setHispanic', { noPageSpinner: true, label: 'UPDATING' }),
			setLastName: this._call<{ lastName: string }, void>('profile.form.setLastName', { noPageSpinner: true, label: 'UPDATING' }),
			setMiddleInit: this._call<{ middleInit: string }, void>('profile.form.setMiddleInit', { noPageSpinner: true, label: 'UPDATING' }),
			setNativeEnglish: this._call<{ nativeEnglish: string }, void>('profile.form.setNativeEnglish', { noPageSpinner: true, label: 'UPDATING' }),
			setNativeSpanish: this._call<{ nativeSpanish: string }, void>('profile.form.setNativeSpanish', { noPageSpinner: true, label: 'UPDATING' }),
			setPhoneTypeId: this._call<{ phoneTypeId: number }, void>('profile.form.setPhoneTypeId', { noPageSpinner: true, label: 'UPDATING' }),
			setPhone: this._call<{ phone: string }, void>('profile.form.setPhone', { noPageSpinner: true, label: 'UPDATING' }),
			setRaces: this._call<{ races: string[] }, void>('profile.form.setRaces', { noPageSpinner: true, label: 'UPDATING' }),
			setVeteran: this._call<{ veteran: string }, void>('profile.form.setVeteran', { noPageSpinner: true, label: 'UPDATING' }),
		},
		webLink: {
			add: this._call<{ url: string, weblinkTypeId: number }, I.FuncData>('profile.webLink.add', { noPageSpinner: false, label: 'UPDATING' }),
			remove: this._call<{ webLinkId: number }, I.FuncData>('profile.webLink.remove', { noPageSpinner: false, label: 'UPDATING' }),
		},
	};

	public questionnaire = {
		add: this._call<{ questionnaireRoleId: number, questionnaireLearnId: number, questionnaireBusinessType: string | undefined, businessStage: string | undefined, offerings: string | undefined, industryId: number | undefined, householdIncome: number | undefined, householdSize: number | undefined, yearOfBirth: number | undefined, college: string | undefined }, void>('questionnaire.add', { noPageSpinner: false, label: 'UPDATING' }),
	};

	public surveys = {
		accSession: {
			getSessionTopicsWithSpecialists: this._call<{ accSessionId: number }, { accSessionTopicWithSpecialists: I.AccSessionTopicWithSpecialists[], access: I.AccSessionSurveyAccess } | undefined>('surveys.accSession.getSessionTopicsWithSpecialists'),
			setSessionSurveyResponse: this._call<I.AccSessionSurveyAnswers, { accSessionTopicWithSpecialists: I.AccSessionTopicWithSpecialists[], access: I.AccSessionSurveyAccess }>('surveys.accSession.setSessionSurveyResponse', { noPageSpinner: true, label: 'UPDATING' }),
		},
		get: this._callNoPayload<{ accSessionId: number, accLanguageId: I.LanguageId, toolEndUTC: number, accSessionSurveyResponseId: number }[]>('surveys.get'),
	};

	public getAllData = this._callNoPayload<I.FuncData>('getAllData');
	public getDomainData = this._callNoPayload<I.DbDomainData>('getDomainData');
}