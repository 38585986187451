import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {
		...urlService.commonLabels,
		Welcome: computed(() => en() ? 'Welcome' : '{{ ES : Welcome }}'),
		WelcomeTitle: computed(() => en() ? 'Welcome!' : '{{ ES : Welcome! }}'),
		WelcomeMessage: computed(() => en() ? 'Messaging TBD: Please fill out our quick questionnaire so we can understand how you want to get involved!' : '{{ ES : WelcomeMessage }}'),
		GetStarted: computed(() => en() ? 'Get Started!' : '{{ ES : Get Started! }}'),
		StartJourneyMessage: computed(() => en() ? 'Start your journey with EforAll' : '{{ ES : Start your journey with EforAll }}'),
	};
}