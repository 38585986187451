import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, effect, inject, input, OnDestroy, OnInit, output, signal } from '@angular/core';
import { WistiaService } from '../../../services/wistia/wistia.service';


@Component({
	selector: 'common-wistia-player',
	standalone: true,
	template: `<div id="wistia_player" class="wistia_embed wistia_async_{{video().wistiaId}}"></div>
	
	<!-- <wistia-transcript player-dom-id="wistia_player"></wistia-transcript> -->
	`,
	styles: ``,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonWistiaPlayerWidget implements AfterViewInit, OnDestroy {
	public wistiaService = inject(WistiaService);

	public readonly video = input.required<{ wistiaId: string, furthestWatchedSecond?: number, completedUTC?: number }>();
	public readonly videoWatchedVectorChanged = output<number[]>();
	private currentWistiaId = signal<string | undefined>(undefined); // Signal to hold the current Wistia ID



	// Track secondsWatchedVector updates and trigger event
	constructor() {
		effect(async () => {
			const video = this.video();
			const vector = this.wistiaService.secondsWatchedVector();

			if (video && video.wistiaId !== this.currentWistiaId()) {
				await this.loadVideo(video);
			}

			if (vector && video && video.wistiaId === this.currentWistiaId() && this.wistiaService.isPlaying()) { // Emit vector if active
				this.videoWatchedVectorChanged.emit(vector);
			}
		});
	}



	async ngAfterViewInit() {
		await this.wistiaService.onNgAfterViewInit();
	}

	async ngOnDestroy() {
		console.log('wistiaService.onNgOnDestroy');
		await this.wistiaService.onNgOnDestroy();
	}


	async loadVideo(video: { wistiaId: string, furthestWatchedSecond?: number, completedUTC?: number }) {
		console.log('wistiaplayer loadvideo', video.wistiaId);

		try {

			await this.wistiaService.replaceVideo(video.wistiaId, false);
			this.currentWistiaId.set(video.wistiaId);


			if (video.furthestWatchedSecond && !video.completedUTC) {
				await this.wistiaService.seekVideo(video.furthestWatchedSecond);
			}

			await this.wistiaService.setWidth(396);

		} catch (error) {
			console.error("Error loading video:", error); // Handle errors!
		}
	}

}