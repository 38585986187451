import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import {
	CommonButtonColumnWidget,
	CommonMultiselectFieldWidget,
	CommonNumberFieldWidget,
	CommonSelectFieldWidget
} from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { QuestionnaireDemographicFormService } from './demographic-form.service';
import { QuestionnaireRoleId } from '@interfaces';

@Component({
	selector: 'questionnaire-basic-info',
	standalone: true,
	imports: [
		MobilePagePart,
		FormsModule,
		CommonSelectFieldWidget,
		CommonMultiselectFieldWidget,
		CommonNumberFieldWidget,
		CommonButtonColumnWidget
	],
	templateUrl: './demographic.page.html'
})

export class QuestionnaireDemographicsPage implements OnInit {
	private frame = inject(MobileFrameService);
	private router = inject(Router);

	public readonly form = inject(QuestionnaireDemographicFormService);

	public staffRoleId = QuestionnaireRoleId.Staff;

	public headerText = computed(() => this.form.questionnaireService.entrepreneurRole() ? `Page 3 of 3` : `Page 2 of 2`);

	constructor() {
		this.frame.setUrlMetadata({
			url: `/questionnaire/page-3`,
			backUrl: this.form.questionnaireService.entrepreneurRole() ? `/questionnaire/page-2` : `/questionnaire/page-1`,
			pageName: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			headerText: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			restrictNavigation: true,
		});
	}


	ngOnInit(): void {
		if (!this.form.questionnaireService.values().questionnaireRoleId) {
			this.router.navigate(['questionnaire/page-1']);
		}
	}


	public async saveAndNavigate() {
		if (!this.form.isValidInputs()) return;

		const values = this.form.questionnaireService.values();
		const data = {
			...values,
			questionnaireRoleId: values.questionnaireRoleId!,
			questionnaireLearnId: values.questionnaireLearnId!,
		};
		await this.form.questionnaireService.func.questionnaire.add({ ...data });

		if (data.yearOfBirth) {
			const user = this.form.questionnaireService.app.data().user;
			this.form.questionnaireService.app.setUserData({ ...user, birthYear: data.yearOfBirth ? +data.yearOfBirth : undefined });
		}
		this.router.navigate(['/']);
	}


}
