
import { Component, OnInit, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonFieldWidgetBase } from '../common-field-widget-base';
import { CommonRadioField } from '../field';
import { getLabels } from '../field.labels';


@Component({
	selector: 'common-radio-field',
	imports: [FormsModule],
	templateUrl: './common-radio-field.widget.html'
})
export class CommonRadioFieldWidget<T> extends CommonFieldWidgetBase<T, CommonRadioField<T>> implements OnInit {

	public readonly field = input.required<CommonRadioField<T>>();

	public readonly labels = computed(() => getLabels(this.field().config().isSpanish));


	ngOnInit() {
		this.field().error.set(this.validate());
	}

	public onChange(value: T,) {
		this.field().pendingValue.set(value);
		this.onBlur();
	}

	public async onBlur() {
		await this.blur(this.field());
	}

	protected renderValue(value: T) {
		const pendingValue = this.field().pendingValue() ?? this.field().actualValue();

		if (pendingValue === value) return true;
		else return false;
	}


	protected validate(): string {

		const value = this.getValue(this.field());
		const config = this.field().config();
		const labels = this.labels();

		if (!value && config.required) return labels.missingRequired();
		if (value && !config.options.map(opt => opt.value == value)) return labels.invalidSelection();

		return '';
	}
}