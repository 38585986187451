import { TitleCasePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonButtonRowWidget, CommonIconTextWidget, UrlService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { MessageFlyoutContentMessageType } from '../flyout-content';
import { getLabels } from '../flyout.labels';


@Component({
	selector: 'public-message-flyout-part',
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
		MarkdownModule,
		TitleCasePipe,
		CommonButtonRowWidget
	],
	providers: [provideMarkdown()],
	templateUrl: './message-flyout.part.html',
	styleUrl: './message-flyout.part.scss'
})
export class PublicMessageFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public readonly header = input<string>();
	public readonly message = input.required<string>();
	public readonly messageType = input<MessageFlyoutContentMessageType>(MessageFlyoutContentMessageType.information);
	readonly MessageFlyoutContentMessageType = MessageFlyoutContentMessageType;
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	readonly statusWiseIcon = computed(() => {
		switch (this.messageType()) {
			case MessageFlyoutContentMessageType.information:
				return 'fa-info-circle';
			case MessageFlyoutContentMessageType.alert:
				return 'fa-triangle-exclamation';
			case MessageFlyoutContentMessageType.error:
				return 'fa-circle-xmark';
			case MessageFlyoutContentMessageType.success:
				return 'fa-circle-check';
			default:
				return '';
		}
	});

	close() {
		this.activeOffcanvas.close();
	}
}