import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {
		...urlService.commonLabels,
		Courses: computed(() => en() ? `Courses` : `Cursos`),
		AllCourses: computed(() => en() ? `All Courses` : `{{ ES : All Courses }}`),
		OpenCourse: computed(() => en() ? `Open Course` : `{{ ES :  Open Course }}`),
		BusinessAcceleratorCourseTitle: computed(() => en() ? `EforAll Business Accelerator Course` : `{{ ES : EforAll Business Accelerator Course }}`),
		BusinessAcceleratorCourseBlob: computed(() => en() ? `Course Description Here: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
Open Course` : `{{ ES : Course Description Here: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
Open Course }}`),
		AceleradordeNegociosdeEparaTodosTitle: computed(() => en() ? `Acelerador de Negocios de EparaTodos` : `{{ ES : Acelerador de Negocios de EparaTodos }}`),
		AceleradordeNegociosdeEparaTodosBlob: computed(() => en() ? `Course Description Here: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
Open Course` : `{{ ES : Course Description Here: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
Open Course }}`),


	};
}