<mobile-page-part>

	<div class="info-content">
		@if(applicationService.application(); as application){
		<h3>{{application.application.picId ? applicationService.labels.PitchContestApplication():
			applicationService.labels.AcceleratorApplication()}}</h3>
		}
		@else {<small>{{applicationService.labels.InfoTextNoApplication()}}</small>}
	</div>

	@if(applicationService.application(); as application)
	{

	@if(application.canUpdate)
	{
	@if(application.isCoParticipant)
	{

	<div class="white-box">
		<h3 class="text-center">{{application.name}}</h3>
		<div>{{applicationService.labels.Applicant()}}: {{application.applicant.firstName}}
			{{application.applicant.lastName}}</div>
		<div>{{applicationService.labels.Business()}}: {{application.business._name}}</div>
		<div>{{applicationService.labels.Deadline()}}: {{application.deadline}}</div>
	</div>
	<h4 class="color-darkred text-center">
		{{applicationService.labels.ApplicationNotYetSubmitted()}}
	</h4>
	<common-button-column>
		<button class="btn btn-primary btn-large"
			(click)="applicationService.flyoutService.showApplicationReadonly()">{{applicationService.labels.ViewPendingApplication()}}</button>
	</common-button-column>


	<mobile-list [items]="tellUsAboutYou()"></mobile-list>


	}
	<!-- If Applicant -->
	@else
	{
	<div class="white-box">
		<h3 class="text-center">{{application.name}}</h3>
		<div>{{applicationService.labels.Deadline()}}: {{application.deadline}}</div>
		<div>{{applicationService.labels.Language()}}: {{application.application.languageId === 2 ?
			'Espanol':'English'}}</div>
		<common-button-column>
			<button class="btn btn-large"
				[ngClass]="{  'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
				(click)="openInstructions()">{{applicationService.labels.Instructions()}}</button>
		</common-button-column>
	</div>

	<h4 class="color-darkred text-center">
		{{application.application.applicationStatusId > 3 ? applicationService.labels.Submitted():
		applicationService.labels.NotSubmitted()}}:
		{{applicationService.stepsCompleted()}} {{applicationService.labels.OfStepsComplete()}}
	</h4>

	@if(applicationService.stepsCompleted() === 4 && application.canSubmit) {
	<common-button-column>
		<button class="btn btn-primary btn-large"
			(click)="applicationService.flyoutService.showApplicationSubmission()">{{applicationService.labels.button.Submit()}}</button>
	</common-button-column>
	}

	<mobile-list [items]="listItems()"></mobile-list>

	<common-button-column>
		@if(application.canSubmit){
		<button class="btn btn-large"
			[ngClass]="{  'btn-primary': primaryBtn() === 'submit',  'btn-standard': primaryBtn() !== 'submit',}"
			(click)="applicationService.flyoutService.showApplicationSubmission()">{{applicationService.labels.button.Submit()}}</button>

		<button class="btn btn-standard"
			(click)="applicationService.openMakeChangesFlyout()">{{applicationService.labels.Withdraw()}}</button>

		}
		@else if( application.canWithdraw || application.canReopen) {

		<button class="btn btn-standard btn-large"
			(click)="applicationService.openMakeChangesFlyout()">{{applicationService.labels.MakeChanges()}}</button>
		}
	</common-button-column>

	}
	}
	@else
	{
	<application-readonly-part></application-readonly-part>
	}
	}
	@else {
	<common-message alertType="warning">{{applicationService.labels.common.NotAuthorized()}}</common-message>
	}

</mobile-page-part>