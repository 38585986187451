import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		ResumeVideo: computed(() => en() ? `Resume Video` : `{{ES: Resume Video}}`),
		Message: computed(() => en() ? `You already started this video. Pick up where you left off?` : `{{ES: You already started this video. Pick up where you left off?}}`),
		ResumeButton: computed(() => en() ? `Resume from {minutes}` : `{{ES: Resume from 0:00}}`),
		PlayBeginButton: computed(() => en() ? `Play from Beginning` : `{{ES: Play from Beginning}}`),

	};
}
