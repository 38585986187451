import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonGeneralFieldConfig, CommonOptionsFieldConfig, CommonPhoneField, CommonSelectField, CommonTextField } from "@eforall/common";
import { QuestionnaireLearnId } from "@interfaces";
import { QuestionnaireService } from "../questionnaire.service";

@Injectable({ providedIn: 'root' })
export class QuestionnaireBasicInfoFormService {

	public readonly questionnaireService = inject(QuestionnaireService);


	public isValidInputs: Signal<boolean> = computed(() =>
		!!this.questionnaireService.app.data().user.firstName
		&& !!this.questionnaireService.app.data().user.firstName
		&& !!this.questionnaireService.values().questionnaireRoleId
		&& !!this.questionnaireService.values().questionnaireLearnId
		&& !!this.questionnaireService.app.data().user.placeId
	);

	readonly firstName = computed<CommonTextField>(() => ({
		config: signal({ label: this.questionnaireService.labels.form.FirstName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.firstName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setFirstName({ firstName: value });
			this.questionnaireService.app.setUserData({ ...user, firstName: value });
		},
		error: signal(''),
	}));

	readonly middleInit = computed<CommonTextField>(() => ({
		config: signal({ label: this.questionnaireService.labels.MiddleInit(), min: 0, max: 1, fixCase: true, multiLine: false, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.middleInit || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setMiddleInit({ middleInit: value });
			this.questionnaireService.app.setUserData({ ...user, middleInit: value });
		},
		error: signal(''),
	}));

	readonly lastName = computed<CommonTextField>(() => ({
		config: signal({ label: this.questionnaireService.labels.form.LastName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.lastName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setLastName({ lastName: value });
			this.questionnaireService.app.setUserData({ ...user, lastName: value });
		},
		error: signal(''),
	}));

	private readonly roleOptions = computed(() => [
		{ value: 1, text: this.questionnaireService.labels.role.NewBusiness() },
		{ value: 2, text: this.questionnaireService.labels.role.ExistsingBusiness() },
		{ value: 3, text: this.questionnaireService.labels.role.EforAllVolunteer() },
		{ value: 4, text: this.questionnaireService.labels.role.EforAllStaffMember() },
	]);
	readonly role = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.questionnaireService.labels.WhatsRole(), options: this.roleOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().questionnaireRoleId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			this.questionnaireService.setValues('questionnaireRoleId', +value);
		},
		error: signal(''),
	}));



	readonly address = computed<CommonAddressField>(() => ({
		config: signal<CommonAddressFieldConfig>({ label: this.questionnaireService.labels.form.Address(), isSpanish: false, required: true }),
		actualValue: computed(() => {
			const user = this.questionnaireService.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			if (value) {
				const user = this.questionnaireService.app.data().user;
				const { countyFipsId } = await this.questionnaireService.func.profile.form.setAddress({ address: value });
				this.questionnaireService.app.setUserData({
					...user,
					inUS: value.inUS,
					zipId: value.zipId,
					countyFipsId,
					address: value.streetAddress,
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});
			}
		},
		error: signal(''),
	}));

	private readonly learnOptions = computed(() => [
		{ value: QuestionnaireLearnId.SocialMedia, text: this.questionnaireService.labels.learn.SocialMedia() },
		{ value: QuestionnaireLearnId.OnlineAd, text: this.questionnaireService.labels.learn.OnlineAd() },
		{ value: QuestionnaireLearnId.SearchEngine, text: this.questionnaireService.labels.learn.SearchEngine() },
		{ value: QuestionnaireLearnId.TVRadioAd, text: this.questionnaireService.labels.learn.TV() },
		{ value: QuestionnaireLearnId.ArticleBlogPodcast, text: this.questionnaireService.labels.learn.Article() },
		{ value: QuestionnaireLearnId.FriendFamilyNeighbor, text: this.questionnaireService.labels.learn.Friend() },
		{ value: QuestionnaireLearnId.EventConference, text: this.questionnaireService.labels.learn.Event() },
		{ value: QuestionnaireLearnId.Other, text: this.questionnaireService.labels.learn.Other() },
	]);
	readonly learn = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.questionnaireService.labels.LearnAboutEforAll(), options: this.learnOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().questionnaireLearnId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			if (value) {
				this.questionnaireService.setValues('questionnaireLearnId', +value);
			}
		},
		error: signal(''),
	}));

	readonly phone = computed<CommonPhoneField>(() => ({
		config: signal<CommonGeneralFieldConfig>({ label: this.questionnaireService.labels.form.Phone(), required: false, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setPhone({ phone: value });
			this.questionnaireService.app.setUserData({ ...user, phone: value });
		},
		error: signal(''),
	}));




}