<div class="nps-widget" [class.saving]='saving'>
	<div class="btn-group" role="group" aria-label="Net Promoter Score">
		@for(i of [0,1,2,3,4,5,6,7,8,9,10]; track $index;){
		<button type="button" class="btn btn-flat btn-outline-secondary p-2" [class.btn-primary]="i === netPromoter()" (click)="scoreChanged(i)">{{i}}</button>
		}
	</div>
	<div class="d-flex flex-row justify-content-between">
		<i>{{labels.Disagree()}}</i>
		<i>{{labels.Agree()}}</i>
	</div>
</div>