import { inject, Injectable, signal } from "@angular/core";
import { UrlService } from "@eforall/common";
import { Topic } from "@interfaces";
import { FuncService } from "../../services";


@Injectable({ providedIn: 'root' })
export class MaterialService {

	private func = inject(FuncService);
	public urlService = inject(UrlService);
	private readonly loaded = signal<boolean>(false);
	private readonly _topics = signal<Topic[]>([]);
	public readonly topics = this._topics.asReadonly();

	public async loadTopics() {
		if (!this.loaded()) {
			this.loaded.set(true);
			const data = await this.func.materials.get();
			if (data) {
				this._topics.set(data);
			}
		}
	}
}