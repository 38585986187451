@if(comments(); as comments)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle"
			[text]="util.text.singularOrPluralWithCount(comments.length, labels.Comment(), labels.Comments())"
			spacing="large" iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		@for(comment of comments; track comment.accTeamGoalId){
		<div class="white-box">
			<h4>{{comment.updatedByPersonName}} - {{util.date.formatUTC(comment.updatedUTC, 'MM/DD/YYYY', 'H:MM AM EST',
				urlService.isSpanish()? 'es-US': 'en-US')}}</h4>
			<div>{{comment.comment}}</div>
		</div>
		}
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}