import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import { CommonMultiSelectField, CommonNumberField, CommonNumberFieldConfig, CommonOptionsFieldConfig, CommonSelectField } from "@eforall/common";
import { QuestionnaireService } from "../questionnaire.service";
import { QuestionnaireRoleId, Races } from "@interfaces";

@Injectable({ providedIn: 'root' })
export class QuestionnaireDemographicFormService {

	public readonly questionnaireService = inject(QuestionnaireService);


	public isValidInputs: Signal<boolean> = computed(() => {
		let allRequiredValid = !!this.questionnaireService.app.data().user.gender
			&& !!this.questionnaireService.app.data().user.hispanic
			&& !!this.questionnaireService.app.data().user.veteran
			&& !!this.questionnaireService.app.data().user.races?.length;


		if (this.questionnaireService.entrepreneurRole()) {
			allRequiredValid = allRequiredValid
				&& this.questionnaireService.values().householdIncome !== undefined
				&& !!this.questionnaireService.values().householdSize
				&& !!this.questionnaireService.values().yearOfBirth
				&& !!this.questionnaireService.values().college;
		}

		if (this.questionnaireService.values().questionnaireRoleId == QuestionnaireRoleId.Volunteer) {
			allRequiredValid = allRequiredValid && !!this.questionnaireService.values().yearOfBirth;
		}


		return allRequiredValid;

	});

	private readonly gendersOptions = computed(() => [
		{ value: 'F', text: this.questionnaireService.labels.genderOption.Female() },
		{ value: 'M', text: this.questionnaireService.labels.genderOption.Male() },
		{ value: 'N', text: this.questionnaireService.labels.genderOption.NonBinary() },
		{ value: 'O', text: this.questionnaireService.labels.genderOption.Other() },
	]);

	readonly gender = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.form.Gender(), options: this.gendersOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setGender({ gender: value });
			this.questionnaireService.app.setUserData({ ...user, gender: value });
		},
		error: signal(''),
	}));

	private readonly hispanicOptions = computed(() => [
		{ value: 'Y', text: this.questionnaireService.labels.hispanicOptions.IAmHispanic() },
		{ value: 'N', text: this.questionnaireService.labels.hispanicOptions.IAmNotHispanic() }
	]);
	readonly hispanicOrLatino = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.form.HispanicOrLatino(), options: this.hispanicOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setHispanic({ hispanic: pendingValue });
			this.questionnaireService.app.setUserData({ ...user, hispanic: pendingValue });
		},
		error: signal(''),
	}));

	private readonly racesOptions = computed(() => [
		{ value: Races.NativeAmerican, text: this.questionnaireService.labels.raceOptions.AmericanIndian() },
		{ value: Races.Asian, text: this.questionnaireService.labels.raceOptions.Asian() },
		{ value: Races.BlackOrAfricanAmerican, text: this.questionnaireService.labels.raceOptions.AfricanAmerican() },
		{ value: Races.PacificIslander, text: this.questionnaireService.labels.raceOptions.PacificIslander() },
		{ value: Races.White, text: this.questionnaireService.labels.raceOptions.White() },
	]);

	readonly races = computed<CommonMultiSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.form.Race(), options: this.racesOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setRaces({ races: value });
			this.questionnaireService.app.setUserData({ ...user, races: value });
		},
		error: signal(''),
	}));


	private readonly veteranOptions = computed(() => [
		{ value: 'Y', text: this.questionnaireService.labels.veteranOptions.IAmVeteran() },
		{ value: 'N', text: this.questionnaireService.labels.veteranOptions.IAmNotVeteran() }
	]);
	readonly veteran = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.form.Veteran(), options: this.veteranOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.app.data().user.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.questionnaireService.app.data().user;
			await this.questionnaireService.func.profile.form.setVeteran({ veteran: pendingValue });
			this.questionnaireService.app.setUserData({ ...user, veteran: pendingValue });
		},
		error: signal(''),
	}));


	private readonly collegeOptions = computed(() => [
		{ value: 'Y', text: this.questionnaireService.labels.college.GraduatedCollege() },
		{ value: 'N', text: this.questionnaireService.labels.college.DidNotGraduateCollege() }
	]);
	readonly college = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.College(), options: this.collegeOptions(), required: this.questionnaireService.entrepreneurRole(), isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().college || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			this.questionnaireService.setValues('college', pendingValue);
		},
		error: signal(''),
	}));


	readonly householdIncome = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: this.questionnaireService.labels.form.HouseholdIncomeLastYear(), max: 100000000, required: this.questionnaireService.entrepreneurRole(), isSpanish: this.questionnaireService.urlService.isSpanish(), type: 'dollar', }),
		actualValue: computed(() => this.questionnaireService.values().householdIncome ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			this.questionnaireService.setValues('householdIncome', value);
		},
		error: signal(''),
	}));



	private readonly yobOptions = computed(() => {
		const options = [];
		for (let i = new Date().getFullYear() - 17; i > new Date().getFullYear() - 100; i--)
			options.push({ value: i, text: i.toString() });
		return options;
	});
	readonly yearOfBirth = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.questionnaireService.labels.YearOfBirth(), options: this.yobOptions(), required: this.questionnaireService.values().questionnaireRoleId !== QuestionnaireRoleId.Staff, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().yearOfBirth || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			this.questionnaireService.setValues('yearOfBirth', value);
		},
		error: signal(''),
	}));


	private readonly householdSizeOptions = computed(() => [
		{ value: 1, text: "1" },
		{ value: 2, text: "2" },
		{ value: 3, text: "3" },
		{ value: 4, text: "4" },
		{ value: 5, text: "5" },
		{ value: 6, text: "6" },
		{ value: 7, text: "7" },
		{ value: 8, text: "8+" },
	]);
	readonly householdSize = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.questionnaireService.labels.HouseholdSize(), options: this.householdSizeOptions(), required: this.questionnaireService.entrepreneurRole(), isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().householdSize || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			this.questionnaireService.setValues('householdSize', value);
		},
		error: signal(''),
	}));

}