import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';
import { MobileFrameConfig, MobileMenuItem } from '@eforall/mobile';
import { AppService, FlyoutService } from '../../services';


export function getMobileFrameConfig(
	app: AppService,
	flyoutService: FlyoutService,
	urlService: UrlService,
) {
	return computed(() => {

		const user = app.data().user;
		const courseEnrollments = app.data().courseEnrollments;
		const labels = urlService.commonLabels;

		let navBarItems: MobileMenuItem[] = [];

		const getProspectNavItems = (): MobileMenuItem[] => {
			let learningRouterLink = '';
			// some check
			const filterCourseEnrollments = courseEnrollments.filter(course => !course.completedUTC && !course.accId);
			if (filterCourseEnrollments.length > 0) {
				learningRouterLink = `/my-courses/${filterCourseEnrollments[0]?.courseVersionId}`;
			} else {
				learningRouterLink = `/all-courses`;
			}

			return [
				{
					label: 'Courses',
					routerLink: learningRouterLink,
					iconStyle: 'fa-regular',
					icon: 'fa-books color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Profile(),
					routerLink: '/profile',
					iconStyle: 'fa-regular',
					icon: 'fa-user color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Home(),
					routerLink: '/',
					iconStyle: 'fa-regular',
					icon: 'fa-home',
					iconColor: 'color-eforall-blue',
				},
				{
					label: "Advisor",			//TODO: finalize new pages and update label accordingly
					routerLink: '/support',		//TODO: finalize new pages and update path accordingly
					iconStyle: 'fa-regular',
					icon: 'fa-comments-question-check',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.EforAllWebsite(),
					url: urlService.isSpanish() ? 'https://eforall.org/es' : 'https://eforall.org',
					iconStyle: 'fa-regular',
					icon: 'fa-globe-pointer color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
			]
		}


		const getCohortNavItems = (): MobileMenuItem[] => {
			let learningRouterLink = '';

			// Find course enrollment for the user's cohort AccId
			const filterCourseEnrollments = courseEnrollments.filter(course => course.accId == user.cohortAccId);
			if (filterCourseEnrollments.length > 0) {
				learningRouterLink = `/my-courses/${filterCourseEnrollments[0]?.courseVersionId}`;
			} else {
				learningRouterLink = `/materials`;
			}

			return [
				{
					label: labels.common.Learning(),
					routerLink: learningRouterLink,
					iconStyle: 'fa-regular',
					icon: 'fa-books color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Goals(),
					routerLink: '/goals',
					iconStyle: 'fa-regular',
					icon: 'fa-chart-mixed color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Home(),
					routerLink: '/',
					iconStyle: 'fa-regular',
					icon: 'fa-home',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Advisor',
					routerLink: '/cohort',
					iconStyle: 'fa-regular',
					icon: 'fa-people-group color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Calendar(),
					routerLink: '/calendar',
					iconStyle: 'fa-regular',
					icon: 'fa-calendars color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
			]
		}


		const getAlumNavItems = (): MobileMenuItem[] => {
			return [
				{
					label: labels.common.Learning(),
					routerLink: '/my-courses',
					iconStyle: 'fa-regular',
					icon: 'fa-books color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Goals(),
					routerLink: '/goals',
					iconStyle: 'fa-regular',
					icon: 'fa-chart-mixed color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Home(),
					routerLink: '/',
					iconStyle: 'fa-regular',
					icon: 'fa-home',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Cohort(),
					routerLink: '/cohort',
					iconStyle: 'fa-regular',
					icon: 'fa-people-group color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
				{
					label: labels.common.Calendar(),
					routerLink: '/calendar',
					iconStyle: 'fa-regular',
					icon: 'fa-calendars color-eforall-blue',
					iconColor: 'color-eforall-blue',
				},
			]
		}

		switch (user.phase) {
			case 'Alum':
				navBarItems = getAlumNavItems();
				break;

			case 'Cohort':
				navBarItems = getCohortNavItems();
				break;

			default:
				navBarItems = getProspectNavItems();
				break;
		}


		const items: MobileFrameConfig = {
			user: {
				publicUserId: user.personId,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.emails?.find(email => email.isDefault)?.email || '',
				avatar: '',
				mobilePhone: user.phone,
				department: undefined
			},
			navBarItems,

			openUserMenu: () => flyoutService.showUserMenu(),
		}

		return items;
	});
}