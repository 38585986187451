import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		SocialLinks: computed(() => en() ? `Social Links` : `{{ ES: Social Links }}`),
		Link: computed(() => en() ? `Link` : `{{ ES: Link }}`),
		Links: computed(() => en() ? `Links` : `{{ ES: Links }}`),
		URL: computed(() => en() ? `URL` : `{{ ES: URL }}`),
		LinkType: computed(() => en() ? `Link Type` : `{{ ES: Link Type }}`),
		AddALink: computed(() => en() ? `Add a Link` : `{{ ES: Add a Link }}`),
		DeleteLinkConfirmationMessage: computed(() => en() ? `Are you sure you want to delete the link?` : `{{ ES: Are you sure you want to delete the link? }}`),
		DeleteLinkConfirmationMessageTitle: computed(() => en() ? `Delete Confirmation` : `{{ ES: Delete Confirmation }}`),
	};
}