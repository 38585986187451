import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ClassesStart: computed(() => en() ? `Classes Start` : `Clases comienzan`),
		MeetingTimes: computed(() => en() ? `Meeting Times` : `Tiempos de reunión`),
		MyCohort: computed(() => en() ? `My Cohort` : `Mi Grupo`),
		MyTeam: computed(() => en() ? `My Team` : `Mi equipo`),
		TeamMember: computed(() => en() ? `Team Member` : `Miembro del Equipo`),
		TeamMembers: computed(() => en() ? `Team Members` : `Miembros del Equipo`),
		NotAteamYetTBD: computed(() => en() ? `Not a team yet. TBD` : `{{ ES: Not a team yet. TBD }}`),

	};
}