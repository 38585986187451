@if(field(); as field)
{
<div class="input-group" style="flex-wrap: nowrap;">
	<span class="input-group-text">
		@if(field.saving()){<i class="fa-fw fa-regular fa-spin fa-spinner-third"></i>}
		@else if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
		@else{<i class="fa-fw fa-regular fa-list-check"></i>}
	</span>
	<div class="white-box" style="width: 100%;">
		<label class="color-gray d-flex">
			{{field.config().label}}{{!field.config().required ? ' ' + labels().optionalWithParentheses():''}}
		</label>
		@for(option of field.config().options; track option.value;)
		{
		<div class="form-check pb-2">
			<input class="form-check-input fs-6" type="checkbox"
				name="field-checkbox-{{field.config().label}}-{{option.value}}" [id]="option.value"
				[ngModel]="renderValue(option.value)" (ngModelChange)="onChange(option.value,$event)" (blur)='onBlur()'
				(focus)="onFocus()">
			<label class="form-check-label fs-6" [for]="option.value" style="width: 100%;">
				{{option.text}}
			</label>
		</div>
		}
	</div>
</div>

@if(errorToShow() !== '')
{
<div class="color-darkred text-end">{{errorToShow()}}</div>
}
}