import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonGeneralFieldConfig, CommonOptionsFieldConfig, CommonSelectField, CommonUrlField } from "@eforall/common";
import { AppService, DomainDataService, FuncService } from "services";
import { getLabels } from "./social-links.page.labels";
import { SocialLinkService } from "./social-links.service";

@Injectable({ providedIn: 'root' })
export class AddSocialLinksFormService {

	public socialLinkService = inject(SocialLinkService);
	private labels = getLabels(this.socialLinkService.urlService);
	private values = signal<{ type: string, url: string }>({ type: '', url: '', });
	private domainDataService = inject(DomainDataService);
	public showAddForm = signal(false);
	public isInvalidForm = computed(() => !this.url().actualValue() || this.url().error());
	private app = inject(AppService);
	private func = inject(FuncService);

	private readonly linkTypeOptions = computed(() => {
		return this.domainDataService.data().webLinkTypes.map(type => ({ value: type.webLinkTypeId.toString(), text: type.label }));
	});

	readonly linkType = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.LinkType(), options: this.linkTypeOptions(), required: true, isSpanish: this.socialLinkService.urlService.isSpanish() }),
		actualValue: computed(() => this.values().type),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			if (value)
				this.values.set({ ...this.values(), type: value });
		},
		error: signal(''),
	}));

	readonly url = computed<CommonUrlField>(() => ({
		config: signal<CommonGeneralFieldConfig>({ label: this.labels.URL(), required: true, max: 100, isSpanish: this.socialLinkService.urlService.isSpanish() }),
		actualValue: computed(() => this.values().url),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			if (value)
				this.values.set({ ...this.values(), url: value });
		},
		error: signal(''),
	}));

	async saveNewWebLink() {
		await this.app.update(this.func.profile.webLink.add, { url: this.url().actualValue().slice(0, 100), weblinkTypeId: Number(this.linkType().actualValue()) });
		this.closeAddForm();
	}

	async removeWebLink(webLinkId: number) {
		await this.app.update(this.func.profile.webLink.remove, { webLinkId });
		this.closeAddForm();
	}


	private resetForm() {
		this.values.set({ type: '', url: '', });
		this.linkType().pendingValue.set(null);
		this.url().pendingValue.set(null);
	}

	openAddForm() {
		this.resetForm();
		this.showAddForm.set(true);
	}

	closeAddForm() {
		this.resetForm();
		this.showAddForm.set(false);
	}

}