import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		NotificationSettings: computed(() => en() ? `Notification Settings` : `{{ ES: Notification Settings }}`),
		Notification: computed(() => en() ? `Notification` : `{{ ES: Notification }}`),
		Notifications: computed(() => en() ? `Notifications` : `{{ ES: Notifications }}`),
		Emails: computed(() => en() ? `Email(s)` : `{{ ES: Email(s) }}`),
		UseThisEmail: computed(() => en() ? `Use this email` : `{{ ES: Use this email }}`),
		NotificationsSentHere: computed(() => en() ? `Notifications sent here` : `{{ ES: Notifications sent here }}`),
		Update: computed(() => en() ? `Update` : `{{ ES: Update }}`),
		PushNotifications: computed(() => en() ? `Push Notifications` : `{{ ES: Push Notifications }}`),
		ComingSoon: computed(() => en() ? `Coming Soon` : `{{ ES: Coming Soon }}`),
		SendMeTextNotifications: computed(() => en() ? `Send me text notifications` : `{{ ES: Send me text notifications }}`),
		DoNotSendMeTextNotifications: computed(() => en() ? `Do not send me text notifications` : `{{ ES: Do not send me text notifications }}`),
	};
}