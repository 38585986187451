import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
	CommonAddressFieldWidget,
	CommonButtonColumnWidget,
	CommonPhoneFieldWidget,
	CommonSelectFieldWidget,
	CommonTextFieldWidget,
	UrlService,
} from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from '../questionnaire.page.labels';
import { QuestionnaireBasicInfoFormService } from './basic-info-form.service';
@Component({
	selector: 'questionnaire-basic-info',
	standalone: true,
	imports: [
		MobilePagePart,
		FormsModule,
		CommonTextFieldWidget,
		CommonAddressFieldWidget,
		CommonPhoneFieldWidget,
		CommonSelectFieldWidget,
		RouterLink,
		CommonButtonColumnWidget,
	],
	templateUrl: './basic-info.page.html'
})

export class QuestionnaireBasicInfoPage {
	private frame = inject(MobileFrameService);
	public readonly form = inject(QuestionnaireBasicInfoFormService);

	public headerText = computed(() => this.form.questionnaireService.entrepreneurRole() ? `Page 1 of 3` : `Page 1 of 2`);
	public pageURL = computed(() => this.form.questionnaireService.entrepreneurRole() ? 'page-2' : 'page-3');


	constructor() {
		this.frame.setUrlMetadata({
			url: `/questionnaire/page-1`,
			backUrl: `/welcome`,
			pageName: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			headerText: computed(() => this.form.questionnaireService.labels.Questionnaire()),
			restrictNavigation: true,
		});
	}



}
