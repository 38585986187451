<mobile-page-part>

	<div class="info-content">
		@if(myTeamService.team(); as team){
		<h3>
			{{myTeamService.util.text
			.singularOrPluralWithCount(team.entrepreneurs.length + team.mentors.length,
			labels.TeamMember(),
			labels.TeamMembers())}}
		</h3>
		}
		@else {
		<small>{{labels.NoTeam()}}</small>
		}
	</div>


	@if(myTeamService.team(); as team){
	<div class="white-box">
		<h4>{{team.data.name}}</h4>
		<div>{{labels.OrgType()}}: {{team.data.orgType}}</div>
		<div>{{labels.Industry()}}: {{team.data.industry}}</div>
		<div>{{labels.Offerings()}}: {{team.data.offerings}}</div>
		<div>{{labels.OperatingStatus()}}: {{team.data.status}}</div>

		<common-button-row [center]='true'>
			@if(team.data.canEdit){
			<button class="btn btn-standard" routerLink="business-details">{{labels.button.Edit()}}</button>
			}
			<button class="btn btn-standard"
				[routerLink]="myTeamService.urlService.withCurrentLanguage('/applications/'+team.data.applicationId)">
				{{labels.ViewApplication()}}</button>
		</common-button-row>
	</div>



	<mobile-list [items]="team.entrepreneurs" [header]="labels.Entrepreneurs()"
		[noItemsMessage]="labels.NoEntrepreneurs()"></mobile-list>


	<mobile-list [items]="team.mentors" [header]="labels.Mentors()" [noItemsMessage]="labels.NoMentors()"></mobile-list>


	}


</mobile-page-part>