import { Component, computed, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonProgressBarWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { CourseActivity } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { IndividualCourseService } from '../individual-course.service';
import { getLabels } from './course-overview.page.labels';


@Component({
	selector: 'course-overview-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
		CommonMessageWidget,
		NgbAccordionModule,
		CommonProgressBarWidget,
	],
	templateUrl: './course-overview.page.html'
})

export class CourseOverviewPage implements OnInit {
	private frame = inject(MobileFrameService);
	public individualCourseService = inject(IndividualCourseService);
	public util = inject(UtilityService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	public progressBar = [false, false, false, false, false, false];
	public labels = getLabels(this.individualCourseService.urlService);


	public course = computed(() => {
		const course = this.individualCourseService.course();
		if (course) {

			return {
				enrollmentDate: this.util.date.formatUTC(course.enrollmentDate, 'MMM D, YYYY', 'No Time', this.individualCourseService.urlService.languageCode()),
				activities: course.activities,
				title: course.title,
				courseCompletionPercentage: course.courseCompletionPercentage,
				activityList: this.getMobileList(course.activities),
				levels: course.levels.map(level1 => {
					type LevelType = 'Module' | 'Section' | 'Topic';

					return {
						courseLevelStructureId: level1.courseLevelStructureId,
						levelType: this.labels[<LevelType>level1.levelType](),
						levelTitle: level1.levelTitle,
						activitiesList: this.getMobileList(level1.activities),
						children: level1.children.map(level2 => {
							return {
								courseLevelStructureId: level2.courseLevelStructureId,
								levelType: this.labels[<LevelType>level2.levelType](),
								levelTitle: level2.levelTitle,
								activitiesList: this.getMobileList(level2.activities),
								children: level2.children.map(level3 => {
									const hasActivities = !!level3.activities.length;

									return {
										courseLevelStructureId: level3.courseLevelStructureId,
										levelType: this.labels[<LevelType>level3.levelType](),
										levelTitle: level3.levelTitle,
										activitiesStartedCount: this.getActivitiesStartedCount(level3.activities),
										activitiedCompletedMessage: this.getActivitiesCompletedMessage(level3.activities),
										hasActivities,
										hasRequiredActivities: this.getHasRequiredActivities(level3.activities),
										percentComplete: this.getActivitiesPercentComplete(level3.activities),
										pendingActivityId: this.getPendingActivityId(level3.activities),
										activities: level3.activities.map(activity => {
											return {
												courseActivityStructureId: activity.courseActivityStructureId,
												courseActivityType: this.labels[<'Video' | 'Prompt' | 'Quiz' | 'Workbook' | 'Feedback'>activity.courseActivityType](),
												activityTitle: activity.activityTitle,
												activityOptional: activity.activityOptional,
											};
										})

									}
								}),

							}
						}),
					}
				}),


			}
		}

		return undefined;


	});

	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];

		this.frame.setUrlMetadata({
			url: `/my-courses/${courseVersionId}/overview`,
			backUrl: `/my-courses/${courseVersionId}`,
			pageName: computed(() => this.labels.CourseOverview()),
			headerText: computed(() => this.labels.CourseOverview()),
		});

		await this.individualCourseService.loadDataAndSetCourseVersion(courseVersionId);
	}


	public getMobileList(activities: CourseActivity[]): MobileListWithSubItem[] {
		if (!activities || !activities.length) return [];

		return activities.map(activity => {
			const url = `my-courses/${this.individualCourseService.courseVersionId()}/activity/${activity.courseActivityStructureId}`;
			return {
				icon: activity.response && activity.response.completedUTC ? 'fa-circle color-green' : 'fa-circle color-lightgray',
				iconStyle: 'fa-solid',
				text: `${activity.courseActivityType}: ${activity.activityTitle}`,
				subText: `${activity.response ?
					activity.response.completedUTC ? this.labels.Completed() : this.labels.Incomplete()
					: this.labels.NotStarted()} ${activity.activityOptional ? "(" + this.labels.Optional() + ")" : ""}`,
				callback: () => {
					this.router.navigate([this.individualCourseService.urlService.withCurrentLanguage(url)]);
				}
			};
		});
	}


	private getHasRequiredActivities(activities: CourseActivity[]): boolean {
		if (!activities.length) return false;

		return !!activities.filter(activity => !activity.activityOptional).length;
	}

	private getActivitiesCompletedMessage(activities: CourseActivity[]): string {
		if (!activities.length) return this.labels.NoActivities();

		//Check if there are required activites for this level
		if (!this.getHasRequiredActivities(activities)) return this.labels.AllOptionalActivities();

		const completedActivities = activities.filter(activity => activity.response && !activity.activityOptional && activity.response.completedUTC).length;
		return `${completedActivities} ${this.labels.Of()} ${activities.filter(activity => !activity.activityOptional).length} ${this.labels.RequiredActivitiesComplete()}`;
	}

	private getActivitiesPercentComplete(activities: CourseActivity[]): number {
		if (!activities.length) return 0;

		const completedActivities = activities.filter(activity => activity.response && !activity.activityOptional && activity.response.completedUTC).length;
		return Math.round((completedActivities / activities.filter(activity => !activity.activityOptional).length) * 100);
	}

	private getActivitiesStartedCount(activities: CourseActivity[]): number {
		const startedActivities = activities.filter(activity => activity.response && activity.response.startUTC).length;
		return startedActivities;
	}

	private getPendingActivityId(activities: CourseActivity[]): number | undefined {
		const pendingActivity = activities.find(activity => !activity.activityOptional && (!activity.response || (activity.response && !activity.response.completedUTC)));
		return pendingActivity?.courseActivityStructureId;
	}

}