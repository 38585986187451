import { UrlService } from '@eforall/common';
import { computed } from '@angular/core';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Agreements: computed(() => en() ? `Agreements.` : `Documentos`),
		NoAgreements: computed(() => en() ? `No agreements.` : `{{ ES: No agreements. }}`),
		SignatureRequired: computed(() => en() ? `Signature Required` : `Firma Requerida`),
		SignedAgreement: computed(() => en() ? `Signed Agreement` : `Documento Firmado`),
		SignedAgreements: computed(() => en() ? `Signed Agreements` : `Documentos Firmados`),
		SignedOn: computed(() => en() ? `Signed on` : `Firmó en`),
		UnsignedAgreement: computed(() => en() ? `Unsigned Agreement` : `Documento sin firmar`),
		UnsignedAgreements: computed(() => en() ? `Unsigned Agreements` : `Documentos No Firmados`),
		UpdatedOn: computed(() => en() ? `Updated on` : `{{ ES: Updated on }}`),
	};
}