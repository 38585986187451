import { CalendarEvent, CourseActivityVideo, GoalComment, Participant } from "@interfaces";

export type FlyoutContent = UserMenuFlyoutContent | MessageFlyoutContent | ApplicationSubmitFlyoutContent | ApplicationReadonlyFlyoutContent | ApplicationMakeChangesFlyoutContent | ApplicationParticipantFlyoutContent | CalendarEventFlyoutContent | GoalCommentsFlyoutContent | VideoActivityFlyoutContent | ConfirmationFlyoutContent;

export interface UserMenuFlyoutContent {
	type: 'UserMenu';
	data: undefined,
}
export interface MessageFlyoutContent {
	type: 'Message';
	data: string,
	header: string,
	messageType: MessageFlyoutContentMessageType
}
export interface ApplicationReadonlyFlyoutContent {
	type: 'ApplicationReadonly';
	data: undefined,
}
export interface ApplicationSubmitFlyoutContent {
	type: 'ApplicationSubmission';
	data: undefined,
}

export interface ApplicationMakeChangesFlyoutContent {
	type: 'ApplicationMakeChanges';
	data: undefined,
}

export interface ApplicationParticipantFlyoutContent {
	type: 'ApplicationParticipant';
	data: Participant,
}
export interface CalendarEventFlyoutContent {
	type: 'CalendarEvent';
	data: CalendarEvent,
}
export interface GoalCommentsFlyoutContent {
	type: 'GoalComments';
	data: GoalComment[],
}
export interface VideoActivityFlyoutContent {
	type: 'VideoActivity';
	data: CourseActivityVideo,
}
export interface ConfirmationFlyoutContent {
	type: 'Confirmation';
	data: string,
	header: string,
}
export enum MessageFlyoutContentMessageType {
	information = "information",
	alert = "alert",
	error = "error",
	success = "success"
}