import { FuncData, User } from '@interfaces';

export const EMPTY_DATA: FuncData = {

	asOfUtc: 0,
	user: {} as User,
	businesses: [],
	userApplications: [],
	courseEnrollments: []

};