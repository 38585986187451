import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonButtonRowWidget, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { AddGoalFormService } from './add-goal-form.service';
import { getLabels } from './goals.page.labels';


@Component({
	selector: 'goals-page',
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		MobileListWidget,
		CommonTextFieldWidget,
		FormsModule,
	],
	templateUrl: './goals.page.html'
})

export class GoalsPage implements OnInit {

	private frame = inject(MobileFrameService);
	public form = inject(AddGoalFormService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	constructor() {
		this.frame.setUrlMetadata({
			url: `/goals`,
			backUrl: `/`,
			pageName: computed(() => this.labels.common.Goals()),
			headerText: computed(() => this.labels.common.Goals()),
		});
	}

	async ngOnInit() {
		await this.form.goalsService.loadData();
	}


	addAGoal() {
		this.form.openAddForm();
	}

}