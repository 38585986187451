<mobile-page-part>

	<div class="info-content">
		@if(individualCourseService.course()){<h3>{{labels.AcceleratorCourse()}}</h3>}
		@else {<h3>{{labels.NoOngoingSession()}}</h3>}

	</div>



	@if(individualCourseService.course()){

	@for(card of courseCardAndList().cards; track $index){

	<div class="white-box">
		<h4>{{card.header}}</h4>
		<div class="d-flex flex-column gap-3">
			<markdown [data]="card.infoMarkdown"></markdown>

			@if(card.percentComplete !== undefined){
			<div>{{card.activityCompleteCountMsg}}</div>
			<common-progress-bar [percentage]="card.percentComplete"></common-progress-bar>
			}

			@if(card.dueDate){
			<div>{{labels.Due()}}: {{card.dueDate}}</div>
			}

		</div>
		<common-button-row [center]="true">
			<button class="btn btn-primary" routerLink="{{card.navigateUrl}}">{{card.buttonText}}</button>
			@if(card.overviewUrl){
			<button class="btn btn-standard" routerLink="{{card.overviewUrl}}">{{labels.CourseOverview()}}</button>
			}
		</common-button-row>

	</div>

	}


	@if(courseCardAndList().dueList.length){
	<mobile-list [items]="courseCardAndList().dueList" [header]="labels.DueSoon()"></mobile-list>
	}
	}
	@else {
	<div>{{labels.NoOngoingSession()}}</div>
	}

</mobile-page-part>