import { computed } from '@angular/core';
import { UrlService } from '../../../services/url/url.service';


export function getWidgetsLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {
		Disagree: computed(() => en() ? `Disagree` : `De Acuerdo`),
		Agree: computed(() => en() ? `Agree` : `Aceptar`),

	};
}
