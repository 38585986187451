import { inject, Injectable, signal } from "@angular/core";
import { UrlService } from "@eforall/common";
import { CourseActivity, CourseLevel, CourseVersion } from "@interfaces";
import { FuncService } from "../../services";
import { AllCoursesService } from "./all-courses.service";


@Injectable({ providedIn: 'root' })
export class IndividualCourseService {

	private func = inject(FuncService);
	public urlService = inject(UrlService);
	private readonly loaded = signal<boolean>(false);
	private allCoursesService = inject(AllCoursesService);
	private readonly _sessionId = signal<number | undefined>(undefined);
	public readonly sessionId = this._sessionId.asReadonly();

	private readonly _courseVersionId = signal<number | undefined>(undefined);
	public readonly courseVersionId = this._courseVersionId.asReadonly();

	private readonly _course = signal<CourseVersion | undefined>(undefined);
	public readonly course = this._course.asReadonly();

	public async loadCourse(courseVersionId: number) {
		if (!this.loaded()) {
			const data = await this.func.learning.get({ courseVersionId });
			this._course.set(data);
			this.loaded.set(true);
		}
	}

	public async loadDataAndSetCourseVersion(courseVersionId: number) {
		if (!this._sessionId()) {
			const randomId = this.generateUniqueSessionId();
			this._sessionId.set(randomId);
		}

		await this.loadCourse(courseVersionId);

		if (courseVersionId && courseVersionId != this.courseVersionId()) {
			this._courseVersionId.set(courseVersionId);
		}

	}

	private generateUniqueSessionId() {
		return Math.floor(Math.random() * (2 ** 32)); // Random number between 0 and 2^32 - 1 which is INT UNSIGNED value
	}

	public async submitQuiz(courseActivityId: number, courseActivityStructureId: number, selectedChoices: { quizQuestionsId: number, selectedChoiceId: number }[]) {
		const data = await this.func.learning.setQuizActivityResponse({ courseActivityId, courseActivityStructureId, selectedChoices, });
		if (data) await this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });

		return data;
	}

	public async submitRating(courseActivityId: number, courseActivityStructureId: number, rating: 1 | -1 | undefined) {
		const data = await this.func.learning.setRatingActivityResponse({ courseActivityId, courseActivityStructureId, rating });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, });
		await this.updateCourseCompletionPercentage();
		return data;
	}

	public async updateVideo(sessionId: number, courseActivityId: number, courseActivityStructureId: number, latestSecondsWatchedVector: number[]) {
		const data = await this.func.learning.setVideoActivityResponse({ sessionId, courseActivityId, courseActivityStructureId, latestSecondsWatchedVector });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });
		if (data.completedUTC) await this.updateCourseCompletionPercentage();

		return data;
	}


	public async submitWorkbook(courseActivityId: number, courseActivityStructureId: number) {
		const data = await this.func.learning.setWorkbookActivityResponse({ courseActivityId, courseActivityStructureId });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });
		if (data.completedUTC) await this.updateCourseCompletionPercentage();

		return data;
	}

	public async submitPrompt(courseActivityId: number, courseActivityStructureId: number, promptAnswer: string) {
		const data = await this.func.learning.setPromptActivityResponse({ courseActivityId, courseActivityStructureId, promptAnswer });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC, promptAnswer });
		if (data.completedUTC) await this.updateCourseCompletionPercentage();

		return data;
	}

	public async submitFeedback(courseActivityId: number, courseActivityStructureId: number, feedbackRating: number, comments?: string) {
		const data = await this.func.learning.setFeedbackActivityResponse({ courseActivityId, courseActivityStructureId, feedbackRating, comments });
		this.updateActivityForCourse({ courseActivityStructureId, courseActivityResponseId: data.courseActivityResponseId, completedUTC: data.completedUTC });
		if (data.completedUTC) await this.updateCourseCompletionPercentage();

		return data;
	}


	public updateActivityForCourse(data: { courseActivityStructureId: number, courseActivityResponseId: number, completedUTC?: number, promptAnswer?: string, rating?: 1 | -1 }) {
		const course = this.course();

		if (!course) return;


		const activityForCourseIndex = course.activities.findIndex(
			(activity: CourseActivity) =>
				activity.courseActivityStructureId === data.courseActivityStructureId
		);

		if (activityForCourseIndex !== -1) {

			const activities = [...course.activities];
			// Course activity found
			this.updateActivityResponse(
				activities[activityForCourseIndex]!,
				data.courseActivityResponseId,
				data.completedUTC,
			);

			course.activities = activities;
			this._course.set({ ...course });
			this.replaceCourses({ ...course });
		} else {
			// It's a level activity
			const newCourse = this.findAndUpdateActivity(
				course,
				data.courseActivityStructureId,
				data.courseActivityResponseId,
				data.completedUTC
			);
			this._course.set({ ...newCourse });
			this.replaceCourses({ ...newCourse });

		}

	}


	/**
	 * Updates the course completion percentage for the current course.
	 * 
	 * This method checks if the course is not yet completed by verifying the `courseCompletedUTC` property.
	 * If the course is not completed, it calculates the completion percentage and updates it if necessary.
	 * 
	 * The method performs the following steps:
	 * 1. Retrieves the current course.
	 * 2. Checks if the course is not completed.
	 * 3. Calculates the completion percentage and checks if the course is completed or if the completion percentage has changed.
	 * 4. If the course is completed or the completion percentage has changed, it updates the course completion percentage on the server.
	 * 5. Updates the local course data with the new completion percentage and completion date.
	 * 6. Updates the course enrollment data in the application state.
	 * 
	 * @returns {Promise<void>} A promise that resolves when the course completion percentage has been updated.
	 */
	public async updateCourseCompletionPercentage() {
		const course = this.course();

		if (!course) return;

		//
		// Only update the course completion if course enrollment CompletedUTC is not set yet
		//
		if (!course.courseCompletedUTC) {
			const completionPercentage = this.getCourseCompletionPercentage(course);
			if (course.courseCompletionPercentage != completionPercentage) {
				const response = await this.func.learning.setCourseCompletionPercentage({ courseVersionId: course.courseVersionId, completionPercentage });

				const updatedCourse: CourseVersion = { ...course, courseCompletedUTC: response.completedUTC, courseCompletionPercentage: response.completionPercentage };
				this._course.set(updatedCourse);
				this.replaceCourses(updatedCourse);
			}
		}

	}

	private replaceCourses(course: CourseVersion) {
		this.allCoursesService.replaceCourseEnrollment(course);
	}

	// Helper function to update activity response
	private updateActivityResponse(
		activity: CourseActivity,
		courseActivityResponseId: number,
		completedUTC?: number,
		promptAnswer?: string, rating?: 1 | -1
	) {
		if (activity.response) {
			activity.response = { ...activity.response, completedUTC, promptAnswer, rating };
		} else {
			activity.response = {
				courseActivityResponseId,
				startUTC: Date.now() / 1000,
				rating,
				promptAnswer,
				completedUTC,
			};
		}
	}

	private findAndUpdateActivity(
		course: CourseVersion,
		courseActivityStructureId: number,
		courseActivityResponseId: number,
		completedUTC?: number
	): CourseVersion {
		const newCourse: CourseVersion = { ...course };

		const updateActivityInLevels = (levels: CourseLevel[]): CourseVersion => {
			for (const level of levels) {
				const activityIndex = level.activities.findIndex(
					(activity: CourseActivity) =>
						activity.courseActivityStructureId === courseActivityStructureId
				);

				if (activityIndex !== -1) {
					const activities = [...level.activities];

					this.updateActivityResponse(
						activities[activityIndex]!,
						courseActivityResponseId,
						completedUTC
					);

					level.activities = activities;
					return newCourse; // Return as soon as the activity is found and updated
				}

				// Recursively search in child levels
				if (level.children?.length) {
					const result = updateActivityInLevels(level.children);
					if (result) return result;
				}
			}
			return newCourse;
		};

		return updateActivityInLevels(newCourse.levels);
	}


	/**
	 * Returns true if all required activities (non-optional) in the given course version have been completed.
	 * An activity is considered complete if its response object exists and has a non-null completedUTC.
	 */
	private getCourseCompletionPercentage(course: CourseVersion): number {
		let requiredActivities = 0;
		let completedActivities = 0;

		const processActivity = (activity: CourseActivity): void => {
			if (!activity.activityOptional) {
				requiredActivities++;
				if (activity.response?.completedUTC) completedActivities++;
			}
		};

		course.activities.forEach(processActivity);

		const processLevels = (levels: CourseLevel[]): void => {
			levels.forEach(level => {
				level.activities.forEach(processActivity);
				if (level.children?.length) {
					processLevels(level.children);
				}
			});
		};

		processLevels(course.levels);

		return requiredActivities > 0 ? Math.round((completedActivities / requiredActivities) * 100) : 100;
	}

	private calculateCompletionScore(courseVersion: CourseVersion): number {
		// Helper function to recursively count completed and total non-optional activities

		function countActivitiesForCourse(course: CourseVersion): { completed: number, total: number } {
			let completed = 0;
			let total = 0;

			for (const activity of course.activities) {
				if (!activity.activityOptional) {  // Skip optional activities
					if (activity.response?.completedUTC) {
						completed++;
					}
					total++;
				}
			}

			// Recursively count activities in child levels
			const childCounts = countActivitiesPerLevel(course.levels);
			completed += childCounts.completed;
			total += childCounts.total;

			return { completed, total };
		}

		function countActivitiesPerLevel(levels: CourseLevel[]): { completed: number, total: number } {
			let completed = 0;
			let total = 0;

			for (const level of levels) {
				// Count only non-optional activities at the current level
				for (const activity of level.activities) {
					if (!activity.activityOptional) {  // Skip optional activities
						if (activity.response?.completedUTC) {
							completed++;
						}
						total++;
					}
				}

				// Recursively count activities in child levels
				const childCounts = countActivitiesPerLevel(level.children);
				completed += childCounts.completed;
				total += childCounts.total;
			}

			return { completed, total };
		}

		// Start counting from the top-level of the course version
		const { completed, total } = countActivitiesForCourse(courseVersion);

		// Calculate the percentage and normalize it to a scale of 0-100
		const completionPercentage = total > 0 ? (completed / total) * 100 : 0;

		// Return the result rounded
		return Math.round(completionPercentage);
	}


}