<mobile-page-part>

	<div class="info-content">
		{{headerText()}}
	</div>
	<div class="d-flex flex-column gap-2">

		<div class="row ">
			<div class="col-7 pe-0"><common-text-field [field]="form.firstName()"></common-text-field></div>
			<div class="col ps-1"><common-text-field [field]="form.middleInit()"></common-text-field></div>
		</div>
		<common-text-field [field]="form.lastName()"></common-text-field>
		<common-select-field [field]="form.role()"></common-select-field>
		<common-address-field [field]="form.address()"></common-address-field>
		<common-phone-field [field]="form.phone()"></common-phone-field>
		<common-select-field [field]="form.learn()"></common-select-field>
	</div>
	<common-button-column>
		<button class="btn btn-primary btn-large" routerLink="../{{pageURL()}}" [disabled]="!form.isValidInputs()">{{form.questionnaireService.labels.button.Next()}}</button>
	</common-button-column>
</mobile-page-part>