import { Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { AccSessionSurveyAccess } from '@interfaces';
import { FuncService } from '../../services';
import { getLabels } from './surveys.page.labels';


@Component({
	selector: 'surveys-page',
	imports: [
		MobilePagePart,
		FormsModule,
		MobileListWidget,
	],
	templateUrl: './surveys.page.html'
})

export class SurveysPage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private util = inject(UtilityService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	public labels = getLabels(this.urlService);
	private readonly surveysList = signal<AccSessionSurveyAccess[]>([]);
	public surveysListDisplay: Signal<MobileListWithSubItem[]> = computed(() => this.prepareList());


	public infoContent = computed(() => {
		const count = this.surveysList().length;
		return count > 0 ? this.util.text.singularOrPluralWithCount(count, this.labels.OpenSurvey(), this.labels.OpenSurveys()) : this.labels.NoOpenSurveys();
	});


	constructor() {
		this.frame.setUrlMetadata({
			url: `/surveys`,
			backUrl: `/`,
			pageName: computed(() => this.labels.Surveys()),
			headerText: computed(() => this.labels.Surveys()),
		});
	}

	async ngOnInit() {
		this.surveysList.set(await this.func.surveys.get() as AccSessionSurveyAccess[]);
	}

	private prepareList(): MobileListWithSubItem[] {
		return this.surveysList().reduce((a: MobileListWithSubItem[], accSession) => {
			a.push({
				icon: accSession.accSessionSurveyResponseId ? `fa-circle color-green` : `fa-circle color-eforall-blue`,
				iconStyle: 'fa-solid',
				text: this.labels.AccSessionSurvey() ?? '',
				subText: this.labels.Complete() + (accSession.accSessionSurveyResponseId ? '' : ': ' + this.util.date.formatUTC(accSession.toolEndUTC, 'MM/DD/YYYY', 'H:MM AM EST', this.urlService.languageCode())),
				callback: () => {
					if (this.urlService.languageId() !== accSession.accLanguageId)
						this.urlService.setLanguage(accSession.accLanguageId);
					const path = `surveys/acc-session/${accSession.accSessionId}`;
					this.router.navigate([this.urlService.withCurrentLanguage(path)]);
				},
			});
			return a;
		}, []);
	}

}
