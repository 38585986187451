@if(activity())
{

<mobile-flyout-frame (closed)="close()">
	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" text="{{labels.ResumeVideo()}}" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex justify-content-center flex-column mt-2">
			<h1>
				<common-icon-text iconStyle="fa-solid" icon="fa-info-circle" [text]="''" spacing="large"
					iconSize="xx-large" class="d-flex justify-content-center flex-column">
				</common-icon-text>
			</h1>
		</div>
		<div class="container text-center px-0 flex-fill">
			<h4>{{ labels.Message() }}</h4>
		</div>
		<div class="btn-container w-100">
			<common-button-column [stretch]='true' class="mx-3">
				<button class="btn btn-primary" (click)="close()">
					{{ getResumeBtnLabel() }}
				</button>
				<button class="btn btn-standard" (click)="playFromBegin()">
					{{ labels.PlayBeginButton() }}
				</button>
			</common-button-column>
		</div>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}