@if(frame.urlData(); as urlData){
	@if(urlData.url !== '/' && urlData.backUrl !== frame.NO_BACK_URL) {
	<button class="btn btn-flat page-name-navigator color-white" (click)="frame.navigateUp(urlData)">
		<i class="fa-solid fa-arrow-left me-1"></i>
		<span style="border-left: solid 1px rgba(255, 255, 255, 0.5); padding-left: 0.5em;">{{urlData.headerText()}}</span>
	</button>
	}
	@else {
	<mobile-logo-part logo="white-full"></mobile-logo-part>
	}
}



<div class="right-panel">

	@if(canRequestFullscreen()) {
	<button class="btn btn-flat color-white" (click)="util.fullscreen.request('portrait');">
		<i style="font-size: 1.5em" class="fa-regular fa-arrow-up-right-and-arrow-down-left-from-center"></i>
	</button>
	}

	@if(canExitFullscreen()) {
	<button class="btn btn-flat color-white" (click)="util.fullscreen.exit();">
		<i style="font-size: 1.25em" class="fa-fw fa-regular fa-arrow-down-left-and-arrow-up-right-to-center"></i>
	</button>
	}

	@if(!frame.urlData().restrictNavigation){
	<button class='btn btn-flat' ngbTooltip="Collapse" placement='bottom' (click)="toggleMenu()">
		@if(frame.config().user.publicUserId){
		<i style="font-size: 2em" class="fa-fw fa-regular fa-bars color-white"></i>
		}
		@else if(frame.config().user.staffId){
		<mobile-avatar-part size="40px" [avatar]="frame.config().user.avatar"></mobile-avatar-part>
		}
	</button>
	}

</div>