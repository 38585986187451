import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonOptionsFieldConfig, CommonSelectField, Option } from "@eforall/common";
import { QuestionnaireService } from "../questionnaire.service";
import { QuestionnaireBusinessStage, QuestionnaireBusinessType } from "@interfaces";

@Injectable({ providedIn: 'root' })
export class QuestionnaireBusinessFormService {

	public readonly questionnaireService = inject(QuestionnaireService);

	public isValidInputs = computed(() =>
		!!this.questionnaireService.values().questionnaireBusinessType
		&& !!this.questionnaireService.values().businessStage
		&& !!this.questionnaireService.values().offerings
		&& !!this.questionnaireService.values().industryId
	);

	private readonly businessTypeOptions = computed(() => [
		{ value: QuestionnaireBusinessType.ForProfit, text: this.questionnaireService.labels.BusinessType.Profit() },
		{ value: QuestionnaireBusinessType.NonProfit, text: this.questionnaireService.labels.BusinessType.NonProfit() },
	]);
	readonly businessType = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.TypeOfBusiness(), options: this.businessTypeOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().questionnaireBusinessType || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			if (value) {
				this.questionnaireService.setValues('questionnaireBusinessType', value);
			}
		},
		error: signal(''),
	}));


	private readonly businessStageOptions = computed(() => [
		{ value: QuestionnaireBusinessStage.Stage0, text: this.questionnaireService.labels.BusinessStage.Stage0() },
		{ value: QuestionnaireBusinessStage.Stage1, text: this.questionnaireService.labels.BusinessStage.Stage1() },
		{ value: QuestionnaireBusinessStage.Stage2, text: this.questionnaireService.labels.BusinessStage.Stage2() },
	]);
	readonly businessStage = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.StageOfBusiness(), options: this.businessStageOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().businessStage || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			if (value) {
				this.questionnaireService.setValues('businessStage', value);
			}
		},
		error: signal(''),
	}));

	private readonly businessOfferingsOptions = computed(() => [
		{ value: 'Products', text: this.questionnaireService.labels.BusinessOfferings.Products() },
		{ value: 'Services', text: this.questionnaireService.labels.BusinessOfferings.Services() },
		{ value: 'Both', text: this.questionnaireService.labels.BusinessOfferings.Both() },
	]);

	readonly businessOfferings = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.questionnaireService.labels.Offerings(), options: this.businessOfferingsOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().offerings || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			if (value) {
				this.questionnaireService.setValues('offerings', value);
			}
		},
		error: signal(''),
	}));

	private readonly businessIndustryOptions = computed(() => {
		return this.questionnaireService.domain.data().industries.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.industryId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});


	readonly businessIndustry = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.questionnaireService.labels.Industry(), options: this.businessIndustryOptions(), required: true, isSpanish: this.questionnaireService.urlService.isSpanish() }),
		actualValue: computed(() => this.questionnaireService.values().industryId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			if (value) {
				this.questionnaireService.setValues('industryId', +value);
			}
		},
		error: signal(''),
	}));



	public resetValues() {
		this.businessIndustry().pendingValue.set(null);
		this.businessOfferings().pendingValue.set(null);
		this.businessStage().pendingValue.set(null);
		this.businessType().pendingValue.set(null);
	}

}