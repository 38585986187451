import { Injectable, computed, inject, signal } from "@angular/core";
import { UrlService } from "@eforall/common";
import { QuestionnaireRoleId } from "@interfaces";
import { AppService, DomainDataService, FuncService } from "../../../services";
import { getLabels } from "./questionnaire.page.labels";

const STATE_KEY = 'Questionnaire_Values';

interface QuestionnaireValues {
	questionnaireRoleId: number | undefined,
	questionnaireLearnId: number | undefined,
	questionnaireBusinessType: string | undefined,
	businessStage: string | undefined,
	offerings: string | undefined,
	industryId: number | undefined,
	householdIncome: number | undefined,
	householdSize: number | undefined,
	yearOfBirth: number | undefined,
	college: string | undefined,
};

@Injectable({ providedIn: 'root' })
export class QuestionnaireService {
	public readonly app = inject(AppService);
	public readonly func = inject(FuncService);
	public readonly domain = inject(DomainDataService);
	public readonly urlService = inject(UrlService);
	public readonly labels = getLabels(this.urlService);

	public readonly roleOptions = {
		[QuestionnaireRoleId.StartNewBusiness]: this.labels.role.NewBusiness(),
		[QuestionnaireRoleId.ExistingBusiness]: this.labels.role.ExistsingBusiness(),
		[QuestionnaireRoleId.Volunteer]: this.labels.role.EforAllVolunteer(),
		[QuestionnaireRoleId.Staff]: this.labels.role.EforAllStaffMember(),
	};

	public values = signal<QuestionnaireValues>(this.getState());


	public selectedRoleText = computed(() => {
		const role = this.values().questionnaireRoleId;
		if (role) return this.roleOptions[role as QuestionnaireRoleId] || '';
		return '';
	});

	public entrepreneurRole = computed(() => (this.values().questionnaireRoleId == 1 || this.values().questionnaireRoleId == 2));


	private getState(): QuestionnaireValues {
		const storedState = sessionStorage.getItem(STATE_KEY)
		if (storedState) return JSON.parse(storedState);

		return {
			questionnaireRoleId: undefined,
			questionnaireLearnId: undefined,
			questionnaireBusinessType: undefined,
			businessStage: undefined,
			offerings: undefined,
			industryId: undefined,
			householdIncome: undefined, householdSize: undefined, yearOfBirth: undefined,
			college: undefined,
		};
	}


	private setSessionState() {
		sessionStorage.removeItem(STATE_KEY);
		const json = JSON.stringify(this.values());
		console.log('new', json);

		sessionStorage.setItem(STATE_KEY, json);
	}


	public setValues(type: 'questionnaireRoleId' | 'questionnaireLearnId' | 'questionnaireBusinessType' | 'businessStage' | 'offerings' | 'industryId' | 'householdIncome' | 'householdSize' | 'yearOfBirth' | 'college', value: number | string | undefined) {

		const values = this.values();
		if (type == 'questionnaireRoleId') {
			const questionnaireRoleId = value;
			if (questionnaireRoleId == QuestionnaireRoleId.Staff || questionnaireRoleId == QuestionnaireRoleId.Volunteer) {
				values.businessStage = undefined;
				values.questionnaireBusinessType = undefined;
				values.industryId = undefined;
				values.offerings = undefined;
				values.householdIncome = undefined;
				values.householdSize = undefined;
				values.college = undefined;

				if (questionnaireRoleId == QuestionnaireRoleId.Staff) {
					values.yearOfBirth = undefined;
				}
			}
		}

		this.values.set({ ...values, [type]: value });


		this.setSessionState();
	}

}