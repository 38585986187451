@if(allCourses().length){
<div class="container">
	@for(course of courses(); track $index;){
	<div class="row individual-course-container my-3">
		<div class="col">
			<div class="row title-row grey-box d-flex align-items-center rounded-3 px-2">
				<div class="col-1 course-icon ps-1">
					<i class='fa-solid fa-2x' [ngClass]="[course.icon]"></i>
				</div>
				<div class="col course-header">
					<h4 class="mb-0">{{course.title }}</h4>
				</div>
			</div>

			<div class="row new-version-toaster-row py-2"
				*ngIf="course.canEnroll && !course.courseEnrollment && course.enrolledToPreviousVersion">
				<div class="col">
					<common-message alertType="info">{{labels.NewVersionAvailable()}}</common-message>
				</div>
			</div>
			<div class="row new-version-toaster-row py-2" *ngIf="course.courseEnrollment?.nextLevelDue?.levelDueUTC">
				<div class="col">
					<common-message alertType="info"
						*ngIf="course.nextSectionDue">{{course.nextSectionDue}}</common-message>
					<common-message alertType="warning"
						*ngIf="course.nextSectionPastDue">{{course.nextSectionPastDue}}</common-message>
				</div>
			</div>


			<div class="row description-row pt-2 pb-1">
				<div class="col course-description"> {{course.description}} </div>
			</div>

			<div class="row course-not-available-toaster-row py-2"
				*ngIf="!course.canEnroll && !course.courseEnrollment">
				<div class="col">
					<common-message alertType="warning">{{labels.CourseNotAvailableYet()}}</common-message>
				</div>
			</div>

			<div class="row progress-row px-1 py-2"
				*ngIf="course.courseEnrollment && course.courseEnrollment.completionPercentage < 100">
				<div class="col-9 px-1">
					<common-progress-bar
						[percentage]="course.courseEnrollment!.completionPercentage"></common-progress-bar>
				</div>
				<div class="col-3 d-flex align-items-center ps-1">
					<small>{{course.courseEnrollment!.completionPercentage}}% {{labels.Complete()}}</small>
				</div>
			</div>

			<div class="row footer-row py-2" *ngIf="course.canEnroll || course.courseEnrollment">
				<div class="col-7 sections ps-4 d-flex align-items-center">
					<h4 class="mb-0">{{course.courseEnrollment?.completedUTC ? labels.CourseComplete() :
						(course.countOfRequiredSections+' '+labels.Sections())}}</h4>
				</div>
				<div class="col-5 button-col my-1 px-3">
					<common-button-row [stretch]="true">
						<button class="btn btn-primary" *ngIf="course.canEnroll && !course.courseEnrollment"
							routerLink='./{{course.programId}}'
							routerLinkActive="router-link-active">{{labels.Start()}}</button>
						<button class="btn btn-primary"
							*ngIf="course.courseEnrollment && course.courseEnrollment.completionPercentage < 100"
							routerLink='../my-courses/{{course.programId}}'>{{labels.Resume()}}</button>
						<button class="btn btn-standard white-button"
							*ngIf="course.courseEnrollment && course.courseEnrollment.completedUTC"
							routerLink='../my-courses/{{course.programId}}'>{{labels.Review()}}</button>
					</common-button-row>
				</div>
			</div>
		</div>
	</div>
	}
</div>
}@else{
{{labels.NoCourses()}}
}