<mobile-page-part>

	<div class="info-content">
		@if(form.goal(); as goal){
		<h3>{{form.goalsService.util.text.singularOrPluralWithCount(goal.comments.length, labels.Comment(),
			labels.Comments())}}</h3>
		}
		@else {
		<small>{{labels.common.NotAuthorized()}}</small>
		}
	</div>

	@if(form.goal(); as goal){

	<div class="white-box">
		<h4>{{goal.summary}}</h4>
		<div>
			{{labels.GoalCreated()}}: {{form.goalsService.getDate(goal.createdUTC)}}
		</div>
		<div>
			{{labels.CreatedBy()}}: {{goal.createdByPersonName}}
		</div>
		<div>
			{{labels.LastUpdated()}}: {{form.goalsService.getDate(goal.updatedUTC)}}
		</div>
		<div>
			{{labels.UpdatedBy()}}: {{goal.updatedByPersonName}}
		</div>

		@if(goal.comments.length > 0){
		<common-button-row [center]="true">
			<button class="btn btn-primary btn-large" (click)="form.viewComments()">{{labels.button.View()}}
				{{form.goalsService.util.text.singularOrPluralWithCount(goal.comments.length, labels.Comment(),
				labels.Comments())}}</button>
		</common-button-row>
		}
	</div>


	@if(form.goalsService.access().canEditGoal){
	<div class="d-flex flex-column gap-3">

		<common-text-field [field]="form.summary()"></common-text-field>
		<common-text-field [field]="form.details()"></common-text-field>
		<common-text-field [field]="form.progress()"></common-text-field>

	</div>
	}


	}
	@else {
	<common-message alertType="warning">{{labels.common.NotAuthorized()}}</common-message>
	}


</mobile-page-part>