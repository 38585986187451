import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { MobileError404Page } from '@eforall/mobile';
import { PublicMobileFrame } from './frame/public-mobile/public-mobile.frame';
import {
	AccTeamGoalPage,
	AgreementPage,
	AgreementsPage,
	AllCoursesPage,
	ApplicationAboutYouPage,
	ApplicationApplyAcceleratorPage,
	ApplicationApplyPitchPage,
	ApplicationJoinCodePage,
	ApplicationJoinPage,
	ApplicationPage,
	ApplicationQuestionPage,
	ApplicationYourBusinessPage,
	ApplicationYourTeamPage,
	ApplicationsPage,
	CalendarPage,
	CohortMyTeamPage,
	CohortPage,
	CourseActivityPage,
	CourseDashboardPage,
	CourseLevelPage,
	CourseOverviewPage,
	ExampleButtonsPage,
	ExampleFieldsPage,
	ExampleTextPage,
	ExamplesPage,
	FeedbackPage,
	GoalsPage,
	HomePage,
	MaterialsPage,
	MyCoursesPage,
	MyTeamBusinessPage,
	NotificationsSettingsPage,
	ProfileBasicInfoPage,
	ProfileBiosPage,
	ProfileDemographicsPage,
	ProfileEmailsPage,
	ProfileLanguagePage,
	ProfilePage,
	ProfileSocialLinksPage,
	ProgramsPage,
	QuestionnaireBasicInfoPage,
	QuestionnaireBusinessPage,
	QuestionnaireDemographicsPage,
	SurveyAccSessionPage,
	SurveysPage,
	WelcomePage,
} from './pages';


/**
 * A function that serves as a route matcher.  It will ensure that
 * the slug is either 'en' or 'es' and reject anything else.
 */
function languageRouteMatcher(url: UrlSegment[]): UrlMatchResult | null {

	if (!url.length) return null;

	const segment = url[0];
	if (!segment) return null;

	if (segment.path === 'en' || segment.path === 'es') return { consumed: [segment] };
	return null;
}


export const routes: Routes = [
	{
		matcher: languageRouteMatcher,
		component: PublicMobileFrame,
		children: [
			{ path: '', component: HomePage },
			{ path: 'programs', component: ProgramsPage },
			{ path: 'feedback', component: FeedbackPage },
			{ path: 'calendar', component: CalendarPage },
			{ path: 'materials', component: MaterialsPage },
			{ path: 'welcome', component: WelcomePage },
			{ path: 'all-courses', component: AllCoursesPage },
			{
				path: 'my-courses',
				children: [
					{ path: '', component: MyCoursesPage },
					{
						path: ':courseVersionId',
						children: [
							{ path: '', component: CourseDashboardPage },
							{ path: 'overview', component: CourseOverviewPage },
							{ path: 'level/:courseLevelStructureId', component: CourseLevelPage, },
							{ path: 'activity/:courseActivityStructureId', component: CourseActivityPage, },
						],
					},
				],
			},
			{
				path: 'goals',
				children: [
					{ path: '', component: GoalsPage },
					{ path: ':accTeamGoalId', component: AccTeamGoalPage, },
				]
			},
			{
				path: 'surveys',
				children: [
					{ path: '', component: SurveysPage },
					{ path: 'acc-session/:accSessionId', component: SurveyAccSessionPage, },
				]
			},
			{
				path: 'cohort',
				children: [
					{ path: '', component: CohortPage },
					{
						path: 'my-team',
						children: [
							{ path: '', component: CohortMyTeamPage },
							{ path: 'business-details', component: MyTeamBusinessPage, },
						]
					}
				]
			},
			{
				path: 'agreements',
				children: [
					{ path: '', component: AgreementsPage },
					{ path: 'entrepreneur-handbook', component: AgreementPage, },
					{ path: 'media-consent', component: AgreementPage, },
				]
			},

			{
				path: 'applications',
				children: [
					{ path: '', component: ApplicationsPage },
					{ path: 'apply-accelerator', component: ApplicationApplyAcceleratorPage },
					{ path: 'apply-pitch', component: ApplicationApplyPitchPage },
					{
						path: 'join',
						children: [
							{ path: '', component: ApplicationJoinPage },
							{ path: ':applCode', component: ApplicationJoinCodePage }
						],
					},
					{
						path: ':applicationId',
						children: [
							{ path: '', component: ApplicationPage, },
							{ path: 'your-business', component: ApplicationYourBusinessPage, },
							{ path: 'you', component: ApplicationAboutYouPage, },
							{ path: 'your-team', component: ApplicationYourTeamPage, },
							{ path: 'questions/:questionId', component: ApplicationQuestionPage, },
						],
					},
				]
			},
			{
				path: 'profile',
				children: [
					{ path: '', component: ProfilePage },
					{ path: 'info', component: ProfileBasicInfoPage },
					{ path: 'demographics', component: ProfileDemographicsPage },
					{ path: 'emails', component: ProfileEmailsPage },
					{ path: 'bios', component: ProfileBiosPage },
					{ path: 'social-links', component: ProfileSocialLinksPage },
					{ path: 'notification', component: NotificationsSettingsPage },
					{ path: 'language', component: ProfileLanguagePage },
				]
			},

			{
				path: 'examples',
				children: [
					{ path: '', component: ExamplesPage },
					{ path: 'fields', component: ExampleFieldsPage },
					{ path: 'text', component: ExampleTextPage },
					{ path: 'buttons', component: ExampleButtonsPage },
				]
			},
			{
				path: 'questionnaire',
				children: [
					{ path: 'page-1', component: QuestionnaireBasicInfoPage },
					{ path: 'page-2', component: QuestionnaireBusinessPage },
					{ path: 'page-3', component: QuestionnaireDemographicsPage },
				]
			},
		]
	},
	{ path: '**', component: MobileError404Page }

];