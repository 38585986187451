import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		BasicInfo: computed(() => en() ? `Basic Info` : `Información básica`),
		Demographics: computed(() => en() ? `Demographics` : `Demografía`),
		Emails: computed(() => en() ? `Emails` : `Correos electrónicos`),
		BasicInfoSubtext: computed(() => en() ? `Name, Phone, Address` : `Nombre, teléfono, dirección`),
		DemographicsSubtext: computed(() => en() ? `Gender, Race, Birthday, etc.` : `Género, Raza, Fecha de Nacimiento, etc.`),
		SocialLinks: computed(() => en() ? `Social Links` : `Enlaces Redes Sociales`),
		NotificationSettings: computed(() => en() ? `Notification Settings` : `Configuración de notificaciones`),
		AppLanguage: computed(() => en() ? `App Language` : `Idioma de la aplicación`),

	};
}
