<mobile-page-part>
	<div class="info-content">
		<h3>{{labels.Courses()}}</h3>
	</div>

	<div class="d-flex flex-column white-box">
		<h4>{{myCourse().header}}</h4>
		<div>{{myCourse().blob}}</div>
		<common-button-row [center]="true">
			<button class="btn btn-standard" [routerLink]="myCourse().url">{{labels.OpenCourse()}}</button>
		</common-button-row>
	</div>

</mobile-page-part>