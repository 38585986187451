<mobile-page-part>

	<div class="info-content">
		<h3> {{ labels.NotificationSettings()}}</h3>
	</div>

	<div class="email-section-container">
		<h4 class="align-self-start">{{labels.Emails()}}</h4>
		@if(emails().length)
		{
		<div class="white-box d-flex flex-column">
			@for(email of emails(); track email.emailId;){
			<div class="d-flex align-items-center individual-email">
				<div class="email">{{email.email}}</div>
				<div class="col-2 d-flex justify-content-end align-items-center">
					<div class="fst-italic" *ngIf="email.isDefault">
						{{labels.NotificationsSentHere()}}</div>
					<button class="btn btn-standard align-self-end  my-auto" *ngIf="!email.isDefault" (click)="form.setPrimaryEmail(email.emailId)">{{labels.UseThisEmail()}}</button>
				</div>
			</div>

			}
		</div>
		}
	</div>

	<div class="phone-section-container mt-3">
		<h4 class="align-self-start">{{labels.form.Phone()}}</h4>
		@if(phone().length)
		{
		<div class="white-box d-flex flex-column gap-2">
			<div class="d-flex align-items-center gap-2">
				<div>{{phone()}}</div>
				<button class="btn btn-standard align-self-end ms-auto" routerLink="../info">{{labels.Update()}}</button>
			</div>
		</div>
		}

		<div class="d-flex flex-column gap-2 mt-2">
			<common-select-field [field]="form.phoneTypeId()"></common-select-field>
		</div>
	</div>

	<div class="push-notification-section-container mt-3">
		<h4 class="align-self-start">{{labels.PushNotifications()}}</h4>
		<div class="white-box d-flex flex-column gap-2">
			<div class="d-flex align-items-center gap-2">
				<div>{{labels.ComingSoon()}}</div>
			</div>
		</div>
	</div>

</mobile-page-part>