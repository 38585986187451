import { NgClass, NgIf } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonProgressBarWidget, UrlService, UtilityService } from '@eforall/common';
import { CourseBasicDetails } from '@interfaces';
import { getLabels } from './course-details-list-view.part.labels';


interface CourseDetailsList extends CourseBasicDetails {
	nextSectionDue?: string, nextSectionPastDue?: string
}


@Component({
	selector: 'course-details-list-view-part',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		NgIf,
		CommonMessageWidget,
		CommonProgressBarWidget,
		RouterLink,
		NgClass
	],
	templateUrl: './course-details-list-view.part.html',
	styleUrls: ['./course-details-list-view.part.scss']
})

export class CourseDetailsListViewPart {
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	public util = inject(UtilityService);

	public allCourses = input.required<CourseBasicDetails[]>();


	public courses = computed<CourseDetailsList[]>(() => {
		const allCourses = this.allCourses();

		return allCourses.reduce((a: CourseDetailsList[], course) => {
			const levelDueUTC = course.courseEnrollment?.nextLevelDue?.levelDueUTC;
			const nextSectionDue = levelDueUTC ? levelDueUTC > Date.now() / 1000 : undefined;
			const nextSectionPastDue = levelDueUTC ? levelDueUTC < Date.now() / 1000 : undefined;

			a.push({
				...course,
				nextSectionDue: nextSectionDue ?
					this.labels.NextSectionDue() + ' ' + this.util.date.formatUTC(levelDueUTC!, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode())
					: undefined,
				nextSectionPastDue: nextSectionPastDue ?
					this.labels.NextSectionPastDue() + ' ' + this.util.date.formatUTC(levelDueUTC!, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageCode())
					: undefined,
			});

			return a;
		}, []);

	});

}