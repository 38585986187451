import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Materials: computed(() => en() ? `Materials` : `Materiales`),
		NoOngoingSession: computed(() => en() ? `No Ongoing Session` : `No hay sesión activa`),
		TopicAvailable: computed(() => en() ? 'Topic Available' : 'Tema disponible'),
		TopicNoMaterialsMsg: computed(() => en() ? 'This topic does not have any course material.' : 'Este tema no tiene ningún material de curso.'),
		TopicsAvailable: computed(() => en() ? 'Topics Available' : 'Temas disponibles'),
	};
}