import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Agreement: computed(() => en() ? `Agreement` : `Documento`),
		AgreementCheckboxMsg: computed(() => en() ? `I have read, understand, and agree to this document.` : `He leído, entiendo y acepto este documento.`),
		AgreementDisclaimerMsg: computed(() => en() ? `DISCLAIMER: By typing your name below and clicking the Sign button, you are signing this document electronically. You agree that your electronic signature is the legal equivalent of your hand-written signature.` : `DESCARGO DE RESPONSABILIDAD: Al escribir su nombre a continuación y hacer clic en el botón Firmar, está firmando este documento electrónicamente. Usted acepta que su firma electrónica es el equivalente legal de su firma manuscrita.`),
		AgreementDoesNotExist: computed(() => en() ? `Agreement does not exist for the user.` : `{{ ES : Agreement does not exist for the user.}}`),
		AgreementThankYouMsg: computed(() => en() ? `Thank you. You have Successfully Signed this Document.` : `Gracias. Ha firmado con éxito este documento.`),
		PleaseReadAndSign: computed(() => en() ? `Please read and sign below` : `Por favor lea y firme a continuación.`),
		Sign: computed(() => en() ? `Sign` : `Firmar`),
		SignatureMustMatch: computed(() => en() ? `Signature must match` : `{{ ES : Signature must match }}`),
		ThankYou: computed(() => en() ? `Thank You` : `Gracias`),

	};
}