import { NgIf } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonSelectFieldWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService } from 'services';
import { NotificationSettingsFormService } from "./notification-settings-form.service";
import { getLabels } from './notification-settings.page.labels';
@Component({
	selector: 'profile-notification-settings-page',
	imports: [
		MobilePagePart,
		NgIf,
		CommonSelectFieldWidget,
		RouterLink
	],
	styleUrls: ['./notification-settings.page.scss'],
	templateUrl: './notification-settings.page.html'
})

export class NotificationsSettingsPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);
	private util = inject(UtilityService);
	private app = inject(AppService);
	public form = inject(NotificationSettingsFormService);

	public phone = computed(() => this.util.phone.format(this.app.data().user.phone));

	public emails = computed(() => {
		return this.app.data().user.emails?.sort((a, b) => {
			if (b.isDefault !== a.isDefault)
				return Number(b.isDefault) - Number(a.isDefault);
			return Number(b.emailId) - Number(a.emailId);
		}) ?? [];
	});


	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/notification',
			backUrl: '/profile',
			pageName: computed(() => this.labels.NotificationSettings()),
			headerText: computed(() => this.labels.Notifications()),
		});
	}
}